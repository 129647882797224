import { isDevMode } from "common";
import * as auth from "utils/auth";

export const useMockData =
  process.env.REACT_APP_ENVIRONMENT !== "production" && isDevMode;

export type Headers = {
  [key: string]: string;
};

type JSONResponse = {
  data: any;
  message?: string;
  error?: string;
};

export type Response<T> = {
  resp?: T;
  error?: any;
};

const DEFAULT_HEADERS: Headers = {
  Accept: "application/json, text/plain",
  "Content-Type": "application/json;charset=utf-8",
  "Cache-Control": "no-cache",
  Authorization: "Basic " + process.env.REACT_APP_SERVER_READ_AUTH,
};

const StatusUnauthorized = 401;

// `params` should be a query string or an array of pairs. eg. [['k1', 'v1'], ['k2', 1], ['k2', 2]]
async function fetchReq(
  path: string,
  httpMethod: "GET" | "POST",
  headers: Headers,
  params?: string | string[][] | null,
  data?: Object | null
): Promise<Response<any>> {
  if (useMockData) {
    return { error: "Can only use mock data" };
  }

  const url = new URL(process.env.REACT_APP_SERVER_URL + path);

  if (params && params.length > 0) {
    if (typeof params === "string") {
      url.search = params;
    } else {
      url.search = new URLSearchParams(params).toString();
    }
  }

  var opts: RequestInit = {
    method: httpMethod,
    headers: headers,
    credentials: "include",
  };

  if (data != null) {
    opts.body = JSON.stringify(data);
  }

  return { error: "An error occurred when contacting the server" };

  try {
    const response = await fetch(url.toString(), opts);
    if (!response.ok) {
      if (response.status === StatusUnauthorized) {
        // toastMessage({ type: "error", value: "You need to log in to do that" });
        return { error: "You need to log in to do that" };
      }

      const respText = await response.text();
      var err = new Error(
        `Response error- code ${response.status}: ${respText}`
      );
      return { error: err };
    }

    const jsonResponse: JSONResponse = await response.json();
    if (response.status < 200 || response.status > 299) {
      err = new Error(`Status code ${response.status}: ${jsonResponse.error}`);
      return { error: err };
    }

    return { resp: jsonResponse.data, error: jsonResponse.error };
  } catch (err) {
    var e = new Error(`An error occurred: ${err}`);
    return { error: e };
  }
}

export async function get(
  path: string,
  params?: string | string[][] | null,
  customHeaders?: Headers
) {
  const headers = DEFAULT_HEADERS;

  if (typeof customHeaders === "object") {
    Object.entries(customHeaders).forEach(([k, v]) => {
      headers[k] = v;
    });
  }

  return fetchReq(path, "GET", headers, params, null);
}

export async function post(path: string, body: any, customHeaders?: Headers) {
  const headers = DEFAULT_HEADERS;

  const token = auth.getUserToken() || "";
  if (token !== "") {
    headers["Authorization"] = "Bearer " + token;
  }

  if (typeof customHeaders === "object") {
    Object.entries(customHeaders).forEach(([k, v]) => {
      headers[k] = v;
    });
  }

  return fetchReq(path, "POST", headers, null, body);
}
