import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { TwitterShareButton } from "react-share";

import Auth from "components/auth/Auth";
import Rules from "pages/pickem/Rules";
import LoadingIndicator from "components/loadingIndicator";
import WinnersList from "pages/winners/Winners";

import { logInfo, getPredictionWinners, getPickEmsResult } from "api";

import styles from "pages/pickem/Result.module.scss";
import globalStyles from "styles/components.module.scss";
import { CURRENT_AWARDS_YEAR } from "common";
import { CategoryWithNomineesAndWinner, GACategory } from "types";
import { GetUserPredictionResultResp } from "types/responses";

function round(value: number, decimals: number) {
  return Number(Math.round(Number(value + "e" + decimals)) + "e-" + decimals);
}

type PickEmResultProps = {};

const PickEmResult: React.FC<PickEmResultProps> = () => {
  let [isCategoriesLoading, setIsCategoriesLoading] = useState<boolean>(true);
  let [categories, setCategories] = useState<
    Array<CategoryWithNomineesAndWinner>
  >([]);

  let [isLoading, setIsLoading] = useState<boolean>(false);
  let [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  let [userResult, setUserResult] =
    useState<GetUserPredictionResultResp | null>(null);

  useEffect(() => {
    async function fetchWinners() {
      const ret = await getPredictionWinners(CURRENT_AWARDS_YEAR);
      if (ret.error != null || ret.resp == null) {
        return;
      }
      setCategories(ret.resp.categories || []);

      setIsCategoriesLoading(false);
    }

    fetchWinners();
  }, []);

  const predictedWinners: { [catId: number]: number } = {};
  userResult?.submissions &&
    userResult.submissions.forEach(({ category_id, nominee_ids }) => {
      if (nominee_ids && nominee_ids.length > 0) {
        predictedWinners[category_id] = nominee_ids[0];
      }
    });

  const renderPercentile = (percentile: number) => {
    let text = "!";
    if (percentile >= 90) {
      text = "! Woah, you're quite the clairvoyant.";
    } else if (percentile >= 70) {
      text = ". Good job!";
    } else if (percentile <= 5) {
      text = ". That's uhh... quite an achievement!";
    } else if (percentile <= 25) {
      text = ".";
    } else {
      text = ".";
    }

    return (
      <p className={styles.Percentile}>
        Your Pick'em score is in the <b>{round(percentile, 2)}th percentile</b>
        {text}
      </p>
    );
  };

  const renderResult = () => {
    if (!isLoggedIn) {
      return <p>Log in to see your results</p>;
    }

    if (userResult == null || userResult.result == null) {
      return (
        <p className={styles.Description}>
          Sorry, we could not retrieve your final Pick'em results. Please check
          again later.
        </p>
      );
    }

    const { score, percentile, num_correct, share_message, top_picks } =
      userResult.result;

    return (
      <div className={styles.Result}>
        {Number.isFinite(percentile) && renderPercentile(percentile)}

        {Number.isFinite(score) && (
          <p className={styles.Score}>
            Your Pick'em score is <b>{round(score, 2)}</b>
          </p>
        )}

        {Number.isFinite(num_correct) && (
          <p className={styles.CorrectPredictions}>
            You predicted <b>{num_correct}</b> winner
            {num_correct == 1 ? "" : "s"} correctly.
          </p>
        )}

        {Array.isArray(top_picks) && top_picks.length > 0 && (
          <div className={styles.TopPicks}>
            <p>Your best picks were:</p>
            <ol className={styles.TopPicksList}>
              {top_picks.map(({ category, nominee }, idx) => (
                <li key={idx} className={styles.RulesListItem}>
                  <span className={styles.Pick}>{nominee}</span> - {category}
                </li>
              ))}
            </ol>
          </div>
        )}

        {share_message && (
          <div className={styles.Share}>
            <TwitterShareButton
              url={"https://thestreamerawards.com/pickem"}
              title={share_message}
              hashtags={["TheStreamerAwards"]}
              related={["StreamerAwards"]}
              via="StreamerAwards"
              onClick={() => {
                logInfo("share pickem tweet");
              }}
            >
              <div className={styles.ShareTwitter}>
                <div className={styles.ShareTwitterBtn}>
                  <span className={styles.TwitterIcon}></span>
                  <span>Share Results</span>
                </div>
              </div>
            </TwitterShareButton>

            <p className={styles.ShareText}>
              Use{" "}
              <a
                href={`https://twitter.com/search?q=(%23TheStreamerAwards)%20since%3A${CURRENT_AWARDS_YEAR}-01-01&src=typed_query`}
                target="_blank"
                rel="noreferrer"
                className={globalStyles.Link}
                onClick={() => {
                  logInfo("#TheStreamerAwards", "", GACategory.Visit);
                }}
              >
                <b>&#35;TheStreamerAwards</b>
              </a>{" "}
              and share your predictions!
            </p>
          </div>
        )}
      </div>
    );
  };

  const renderCategoryList = (predictedWinners: {
    [catId: number]: number;
  }) => {
    return categories && categories.length > 0 ? (
      <div className={styles.Categories}>
        {isLoggedIn && (
          <p>
            Your predictions (blue ticks) and the actual winners (green ticks)
            are shown below.
          </p>
        )}

        <WinnersList
          categories={categories}
          selectedCategoryNominees={predictedWinners}
        />
      </div>
    ) : (
      <div className={styles.FormError}>
        Sorry, could not retrieve categories. Please try again later.
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={styles.Description}>
        <h2>See your Pick'em results below</h2>
        <p></p>
        <Rules />
      </div>

      <Auth
        onAuthInit={() => {}}
        onLogin={async () => {
          setIsLoading(true);
          setIsLoggedIn(true);

          const ret = await getPickEmsResult(CURRENT_AWARDS_YEAR);
          if (ret.error != null || ret.resp == null) {
            toast.error("An error occurred when fetching Pick'em results.");
          } else {
            setUserResult(ret.resp);
          }

          setIsLoading(false);
        }}
        onLogout={() => {
          setIsLoggedIn(false);
          setUserResult(null);
        }}
      />

      {isLoading ? <LoadingIndicator /> : renderResult()}

      {!isCategoriesLoading && renderCategoryList(predictedWinners)}
    </React.Fragment>
  );
};

export default PickEmResult;
