import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

import SEO from "components/seo";
import {
  nominationEndDate,
  voteStartDate,
  voteEndDate,
  awardShowTimestamp,
  nominationStartDate,
  ticketsPresaleStartTimestamp,
  ticketsGeneralStartTimestamp,
} from "common/dates";
import { formatDate, formatDateTime } from "utils/index";

import dividerImg from "assets/divider.png";

import styles from "pages/faq/FAQ.module.scss";
import globalStyles from "styles/components.module.scss";
import { GACategory, GALabel, PageProps } from "types";
import { logInfo } from "api";

type QuestionProps = {
  children: ReactNode;
};

const Question: React.FunctionComponent<QuestionProps> = ({ children }) => {
  let id: string | undefined = undefined;
  if (typeof children === "string") {
    id = children
      .toLowerCase()
      .replace(/\ /g, "-")
      .replace(/\//g, "-")
      .replace(/\?/g, "")
      .replace(/\'/g, "")
      .replace(/\"/g, "")
      .replace(/\./g, "");
  }

  return (
    <React.Fragment>
      <h4 id={id} className={styles.Question}>
        Q: {children}
      </h4>
    </React.Fragment>
  );
};

type AnswerProps = {
  children: ReactNode;
};

const Answer: React.FunctionComponent<AnswerProps> = ({ children }) => {
  return (
    <React.Fragment>
      <p className={styles.Answer}>A: {children}</p>
    </React.Fragment>
  );
};

type Faq = {
  question: ReactNode;
  answer: ReactNode;
};

const categoryFaqs: Array<Faq> = [
  {
    question: (
      <Question>
        Will the final award categories include ONLY the games we're voting for?
      </Question>
    ),
    answer: (
      <Answer>
        No, more general categories from last year (like Best Just Chatting
        Streamer, Best FPS Streamer, Best Variety Streamer etc.) will be
        returning too. You're choosing which games you like watching and should
        have dedicated award categories (like Best Valorant Streamer, for
        example).
      </Answer>
    ),
  },
  {
    question: <Question>Why are we asking you to choose categories?</Question>,
    answer: (
      <Answer>
        We are trying to represent stream content that has been trending in the
        past year. By asking you to choose, we can include new award categories
        for games that people love watching, and remove ones that people aren't
        as interested in.
      </Answer>
    ),
  },
];

const nominationFaqs: Array<Faq> = [
  {
    question: <Question>When does the nominations phase begin?</Question>,
    answer: <Answer>Nominations open on {nominationStartDate}.</Answer>,
  },
  {
    question: (
      <Question>
        Can I nominate streamers that stream on Twitch/Youtube/Facebook
        Gaming/etc. or non-English speaking streamers?
      </Question>
    ),
    answer: (
      <Answer>
        Yes, you can nominate <i>any</i> livestreamer as long as they fit the
        award category.
      </Answer>
    ),
  },
  {
    question: (
      <Question>
        Are there any constraints on which streamers I can nominate?
      </Question>
    ),
    answer: (
      <Answer>
        Yes, there are two constraints-
        <ul>
          <li>
            For your nominated streamer to be considered for any award category,
            they must have streamed for at least{" "}
            <span className={styles.Highlight}>200 hours total</span> in 2022.
          </li>
          <li>
            For your nominated streamer to be considered for a specific award
            category (like Valorant, Strategy Game etc.), they must have
            streamed for at least{" "}
            <span className={styles.Highlight}>100 hours in that category</span>{" "}
            in 2022.
          </li>
        </ul>
      </Answer>
    ),
  },
  {
    question: (
      <Question>
        What criteria should I use for choosing my nominations?
      </Question>
    ),
    answer: (
      <Answer>
        This is the Streamer Awards, so it's based on which streamer you find
        most entertaining to watch in the category (unless otherwise stated in
        the category description). We're not looking for streamers that are the
        best at a game, for example. We're looking for streamers that put on the
        best show!
      </Answer>
    ),
  },
  {
    question: (
      <Question>
        What time period should I consider for my nominations?
      </Question>
    ),
    answer: (
      <Answer>
        These awards are meant for content that was created last year, i.e., in
        2022.
      </Answer>
    ),
  },
  {
    question: (
      <Question>
        What if I spell my nominations incorrectly? What if a nomination is
        submitted under multiple names?
      </Question>
    ),
    answer: (
      <Answer>
        We combine all variations that a potential nominee is submitted under,
        as best we can. But yes, it would greatly help if you spell nominations
        correctly.
      </Answer>
    ),
  },
  {
    question: (
      <Question>
        I've already submitted the form. Can I change my responses?
      </Question>
    ),
    answer: (
      <Answer>
        Yes, just log in with the same account, and submit the form again. It
        will be pre-filled with your previous submission.
      </Answer>
    ),
  },
  {
    question: <Question>How many times can I submit nominations?</Question>,
    answer: (
      <Answer>
        We limit submissions to one form per person. But, you can still
        re-submit the form if you want to update your responses.
      </Answer>
    ),
  },
  {
    question: <Question>When does the nomination phase end?</Question>,
    answer: <Answer>Nominations close on {nominationEndDate}.</Answer>,
  },
];

const voteFaqs = [
  {
    question: <Question>When does the voting phase begin?</Question>,
    answer: <Answer>Voting starts on {voteStartDate}.</Answer>,
  },
  {
    question: <Question>What criteria do I use for picking my votes?</Question>,
    answer: (
      <Answer>
        This is the Streamer Awards, so it's based on which streamer you find
        most entertaining to watch in the category (unless otherwise stated in
        the category description). We're not looking for streamers that are the
        best at a game, for example. We're looking for streamers that put on the
        best show!
      </Answer>
    ),
  },
  {
    question: (
      <Question>
        I've already submitted the form. Can I change my responses?
      </Question>
    ),
    answer: (
      <Answer>
        Yes, just log in with the same account, and submit the form again. It
        will be pre-filled with your previous submission.
      </Answer>
    ),
  },
  {
    question: <Question>How many times can I submit votes?</Question>,
    answer: (
      <Answer>
        Just once. But, you can still re-submit the form if you want to update
        your responses.
      </Answer>
    ),
  },
  {
    question: (
      <Question>
        Is there a limit on the number of categories that a streamer can be a
        nominee for?
      </Question>
    ),
    answer: (
      <Answer>
        Yes, we have limited nominees to a maximum of 2 categories. The chosen
        categories are based on their appropriateness for the category and the
        results from the nominations phase.
      </Answer>
    ),
  },
  {
    question: <Question>When does the voting phase end?</Question>,
    answer: <Answer>Voting closes on {voteEndDate}.</Answer>,
  },
];

const streamerFaqs = [
  {
    question: (
      <Question>
        I'm a streamer and I want to win an award. What should I do?
      </Question>
    ),
    answer: (
      <Answer>
        Campaign for yourself! Encourage your fans to nominate and vote for you
        through your livestreams, TikToks, tweets, YouTube videos, etc. The
        first step is to ask them to nominate you so that you reach the voting
        phase. Once the final voting starts, ask them to vote for you from the
        list of nominees.
      </Answer>
    ),
  },
  {
    question: (
      <Question>
        I'm a streamer and I want to attend the awards show. Can I come?
      </Question>
    ),
    answer: (
      <Answer>
        Yes! If you are a partnered streamer and would like to buy tickets to
        attend in-person as part of the audience, there is a presale that starts
        at {ticketsPresaleStartTimestamp.toString()}. Other than that, all seats
        are invite only. To buy tickets, go to{" "}
        <a
          href={"https://tickets.qtcinderella.gg"}
          target="_blank"
          rel="noreferrer"
          className={globalStyles.Link}
        >
          tickets.qtcinderella.gg
        </a>
        .
      </Answer>
    ),
  },
  {
    question: (
      <Question>I'm a streamer. Can I co-stream the awards show?</Question>
    ),
    answer: <Answer>No, only those given special permission may do so.</Answer>,
  },
  {
    question: (
      <Question>
        I'm a streamer and I've been invited to attend the show. Is there a
        dress code?
      </Question>
    ),
    answer: (
      <Answer>
        Yes, it's a formal attire event. Your fans are excited to see you all
        dressed up :)
      </Answer>
    ),
  },
];

const generalFaqs = [
  {
    question: (
      <Question>
        What are the "choose categories", "nomination" and "voting" phases?
      </Question>
    ),
    answer: (
      <Answer>
        See{" "}
        <Link to="/about#how-it-works" className={globalStyles.Link}>
          How It Works
        </Link>
        .
      </Answer>
    ),
  },
  {
    question: <Question>When is the awards show?</Question>,
    answer: (
      <Answer>
        The live in-person show is on{" "}
        <span className={globalStyles.Time}>
          {formatDateTime(awardShowTimestamp, true, true)}
        </span>
        , starting with the red carpet event followed by the main awards show.
      </Answer>
    ),
  },
  {
    question: <Question>Where can I watch the show?</Question>,
    answer: (
      <Answer>
        It will be live streamed at{" "}
        <a
          href="https://www.twitch.tv/qtcinderella"
          target="_blank"
          rel="noreferrer"
          className={globalStyles.Link}
        >
          twitch.tv/qtcinderella
        </a>
        .
      </Answer>
    ),
  },
  {
    question: (
      <Question>Where can I get updates about The Streamer Awards?</Question>
    ),
    answer: (
      <Answer>
        Updates will be posted on the official Twitter account{" "}
        <a
          href="https://twitter.com/StreamerAwards"
          target="_blank"
          rel="noreferrer"
          className={globalStyles.Link}
        >
          <span>&#64;</span>StreamerAwards
        </a>
        .
      </Answer>
    ),
  },
  {
    question: <Question>When are the winners chosen?</Question>,
    answer: (
      <Answer>
        We decide the nominees for each award after the nominations phase. Then,
        the final winners are determined after the voting phase, which is open
        from {voteStartDate} to {voteEndDate}.
      </Answer>
    ),
  },
  {
    question: (
      <Question>
        Will the results for the nomination and vote submissions be released?
      </Question>
    ),
    answer: (
      <Answer>
        For the nominations, only the final nominees will be announced, as part
        of the voting phase. For the votes, only the final winner of each award
        will be announced, at the in-person show.
      </Answer>
    ),
  },
  {
    question: <Question>Do you have merch?</Question>,
    answer: (
      <Answer>
        Yes, you can get merch at{" "}
        <a
          href="https://merch.thestreamerawards.com/"
          target="_blank"
          rel="noopener"
          onClick={() => {
            logInfo("merch", "", GACategory.Visit, GALabel.Link);
          }}
          className={globalStyles.Link}
        >
          merch.thestreamerawards.com
        </a>
      </Answer>
    ),
    // answer: <Answer>Merch will be announced closer to the show.</Answer>,
  },
  {
    question: (
      <Question>I'm a viewer. Can I attend the in-person awards show?</Question>
    ),
    answer: (
      <Answer>
        Yes! After the partner streamer presale (
        {formatDate(ticketsPresaleStartTimestamp)}), in-person general audience
        tickets go on sale on {formatDate(ticketsGeneralStartTimestamp)}. To buy
        tickets, go to{" "}
        <a
          href={"https://tickets.qtcinderella.gg"}
          target="_blank"
          rel="noreferrer"
          className={globalStyles.Link}
        >
          tickets.qtcinderella.gg
        </a>
        .
      </Answer>
    ),
  },
  {
    question: (
      <Question>
        Where can I see the privacy policy and cookie policy for this website?
      </Question>
    ),
    answer: (
      <Answer>
        By using this website, you consent to our data policies-{" "}
        <a
          href={process.env.PUBLIC_URL + "privacy_policy.html"}
          target="_blank"
          rel="noreferrer"
          className={globalStyles.Link}
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          href={process.env.PUBLIC_URL + "cookie_policy.html"}
          target="_blank"
          rel="noreferrer"
          className={globalStyles.Link}
        >
          Cookie Policy
        </a>
        .
      </Answer>
    ),
  },
];

const faqSections = {
  "Questions about Choosing Categories": categoryFaqs,
  "Questions about Nominations": nominationFaqs,
  "Questions about Voting": voteFaqs,
  "Streamer Questions": streamerFaqs,
  "General Questions": generalFaqs,
};

type FAQSectionProps = {
  name: string;
  faqs: Faq[];
};

const FAQSection: React.FunctionComponent<FAQSectionProps> = ({
  name,
  faqs,
}) => {
  return (
    <div className={styles.Section}>
      <h2>{name}</h2>
      <ul>
        {faqs.map(({ question, answer }, idx) => {
          return (
            <li key={idx}>
              {question}
              {answer}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

type SectionSeparatorProps = {};

const SectionSeparator: React.FunctionComponent<SectionSeparatorProps> = () => {
  return (
    <img src={dividerImg} className={styles.DividerImg} alt="section-divider" />
  );
};

type FAQProps = PageProps;

const FAQ: React.FunctionComponent<FAQProps> = () => {
  return (
    <div className={styles.FAQPage}>
      <SEO
        title="FAQ"
        description="Get answers to your frequently asked questions about The Streamer Awards."
      />

      <h1>Frequently Asked Questions</h1>
      <div>
        {Object.entries(faqSections).map(([sectionName, faqs], idx) => {
          return (
            <div key={idx}>
              {idx !== 0 && <SectionSeparator />}
              <FAQSection name={sectionName} faqs={faqs} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(FAQ);
