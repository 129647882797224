import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Prompt } from "react-router-dom";

import SEO from "components/seo";
import SubmissionSuccess from "pages/pickem/Success";
import PickEmResult from "pages/pickem/Result";
import Rules from "pages/pickem/Rules";
import LoadingIndicator from "components/loadingIndicator";
import Form, { FormValues } from "components/form";

import {
  logInfo,
  logError,
  getPickEmCategories,
  getPickEmsSubmission,
  submitPickEms,
} from "api";
import { Phase, currentPhase, pickEmEndDate } from "common/dates";
import useExitPrompt from "hooks/useExitPrompt";

import styles from "pages/pickem/PickEm.module.scss";
import globalStyles from "styles/components.module.scss";
import { CURRENT_AWARDS_YEAR } from "common";
import {
  CategoryWithNominees,
  ClientConfig,
  InputType,
  PageProps,
  PageStage,
  UserPredictionSubmission,
} from "types";
import { isNumeric } from "utils";

type PickEmFormProps = {
  categories: Array<CategoryWithNominees>;
  onSubmitSuccess: () => void;
};

const PickEmForm: React.FC<PickEmFormProps> = ({
  categories,
  onSubmitSuccess,
}) => {
  const [shouldShowExitPrompt, setShouldShowExitPrompt] = useExitPrompt(false);

  //NOTE: this similar to componentWillUnmount()
  useEffect(() => {
    return () => {
      setShouldShowExitPrompt(false);
    };
  }, [setShouldShowExitPrompt]);

  const fetchInitialValues = async (): Promise<FormValues> => {
    const catsMap = new Map<number, CategoryWithNominees>();
    const initialValues: FormValues = {};

    categories.forEach((c) => {
      initialValues[c.id] = "";

      catsMap.set(c.id, c);
    });

    const ret = await getPickEmsSubmission(CURRENT_AWARDS_YEAR);
    if (ret.error != null || ret.resp == null) {
      return initialValues;
    }

    const { submissions } = ret.resp;
    if (!submissions) {
      return initialValues;
    }

    submissions.forEach(({ category_id, nominee_ids, custom_responses }) => {
      const category = catsMap.get(Number(category_id));
      if (category == null) {
        return;
      }

      let val: string = "";

      switch (category.details?.input?.type) {
        case InputType.RadioGroup:
          if (nominee_ids && nominee_ids.length > 0) {
            val = String(nominee_ids[0]);
          }
          break;

        case InputType.Text:
        case InputType.Number:
        default:
          val =
            custom_responses && custom_responses.length > 0
              ? custom_responses[0]
              : "";
          break;
      }

      initialValues[category_id] = val;
    });

    return initialValues;
  };

  const handleSubmit = async (values: FormValues) => {
    const catsMap = new Map<number, CategoryWithNominees>();
    categories.forEach((c) => {
      catsMap.set(c.id, c);
    });

    const submissions: UserPredictionSubmission[] = [];
    for (const [cId, val] of Object.entries(values)) {
      const category = catsMap.get(Number(cId));
      if (category == null) {
        return;
      }

      const submission: UserPredictionSubmission = {
        category_id: Number(cId),
        nominee_ids: undefined,
        custom_responses: undefined,
      };

      switch (category.details?.input?.type) {
        case InputType.RadioGroup:
          submission.nominee_ids = val != null ? [Number(val)] : undefined;
          break;

        case InputType.Number:
          const v = String(val);
          if (isNumeric(v)) {
            submission.custom_responses = [v.trim()];
          }
          break;

        case InputType.Text:
          if (typeof val === "string") {
            submission.custom_responses = [val.trim()];
          }
          break;
      }

      submissions.push(submission);
    }

    const ret = await submitPickEms(CURRENT_AWARDS_YEAR, submissions);
    if (ret.error != null) {
      logError("submit pickems error", `${JSON.stringify(ret.error)}`);
      toast.error(
        "An error occurred when submitting your Pick'em predictions."
      );
      return;
    }

    logInfo("pickems submission");

    setShouldShowExitPrompt(false);
    onSubmitSuccess();
  };

  return (
    <React.Fragment>
      <Prompt
        when={shouldShowExitPrompt}
        message={`You've entered some responses, but have not submitted them. Are you sure you want to leave?`}
      />
      <Form
        formType="pickem"
        categories={categories}
        checkmarkColour={"#1326f2"}
        fetchInitialValues={fetchInitialValues}
        onSubmitForm={handleSubmit}
        onFormTouched={() => setShouldShowExitPrompt(true)}
      />
    </React.Fragment>
  );
};

type PickEmPageProps = {
  onSubmitSuccess?: () => void;
} & PageProps;

const PickEmPage: React.FunctionComponent<PickEmPageProps> = ({
  config,
  onSubmitSuccess,
}) => {
  const [currStage, setCurrStage] = useState<PageStage>(PageStage.loading);
  const [categories, setCategories] = useState<Array<CategoryWithNominees>>([]);

  const handleConfigUpdate = async (conf?: ClientConfig) => {
    if (currentPhase !== Phase.PICKEMS) {
      return;
    }

    if (conf?.is_predictions_open === false) {
      setCategories([]);
      return;
    }

    const ret = await getPickEmCategories(CURRENT_AWARDS_YEAR);
    if (ret.error != null || ret.resp == null) {
      toast.error("An error occurred when fetching Pick'em categories.");
      return;
    }

    const { categories } = ret.resp;
    setCategories(categories || []);
  };

  useEffect(() => {
    async function fetchCategories() {
      await handleConfigUpdate(config);

      if (currStage === PageStage.loading) {
        setCurrStage(PageStage.input);
      }
    }

    fetchCategories();
  }, [config]);

  const renderForm = () => {
    return categories && categories.length > 0 ? (
      <PickEmForm
        categories={categories}
        onSubmitSuccess={() => {
          onSubmitSuccess && onSubmitSuccess();
          setCurrStage(PageStage.complete);
          window.scrollTo(0, 0);
        }}
      />
    ) : (
      <div className={styles.FormError}>
        Sorry, an error occurred. No Pick'em categories found. Please check
        again later.
      </div>
    );
  };

  const renderPickemPredictions = () => {
    if (currStage === PageStage.complete) {
      return (
        <SubmissionSuccess
          categories={categories}
          handleClick={() => {
            setCurrStage(PageStage.input);
          }}
        />
      );
    }

    return (
      <React.Fragment>
        <div className={styles.Description}>
          <h1>Pick'em</h1>
          <h3>
            Compete against the community with your Pick'em predictions and
            stand a chance to win prizes
          </h3>
          <p>
            Submit your predictions for the award winners and other aspects of
            the show below.
          </p>
          <p>The Pick'em contest will close on {pickEmEndDate}.</p>
          <p>
            {/* TODO: add above submit button */}
            Note: By submitting this form, you consent to being contacted via
            email if you win.
          </p>
          <Rules />
        </div>

        {currStage === PageStage.loading ? <LoadingIndicator /> : renderForm()}
      </React.Fragment>
    );
  };

  const renderPageContent = () => {
    switch (currentPhase) {
      case Phase.PICKEMS:
        return renderPickemPredictions();

      case Phase.AWARDS_SHOW:
      case Phase.WINNERS_ANNOUNCED:
        return <PickEmResult />;

      default:
        return <p>This page is not open yet.</p>;
    }
  };

  return (
    <div>
      <SEO
        title="Pick'em"
        description={
          "Submit your predictions in The Streamer Awards Pick'em contest " +
          "and compete against the community to win prizes and prove that you know best."
        }
        keywords={[
          "Pick'em",
          "Contest",
          "Tournament",
          "Competition",
          "Vote",
          "Submit",
        ]}
      />

      {renderPageContent()}
    </div>
  );
};

export default PickEmPage;
