import { Helmet } from "react-helmet-async";

import { awardShowDate } from "common/dates";

const DEFAULT_TITLE = "The Streamer Awards";
const DEFAULT_DESCRIPTION = `Official website of The Streamer Awards to submit nominations, votes, predictions, and to learn more about the show. Live on ${awardShowDate}. Founded by QTCinderella.`;
const DEFAULT_URL = "http://thestreamerawards.com/";

const DEFAULT_IMAGE = "https://thestreamerawards.com/assets/card.jpg?1";
const DEFAULT_IMAGE_WIDTH = "960";
const DEFAULT_IMAGE_HEIGHT = "540";

const DEFAULT_TWITTER_SITE = "@StreamerAwards";

function SEO({ title, description, keywords = [], meta = [] }) {
  const metaTitle = title ? DEFAULT_TITLE + "- " + title : DEFAULT_TITLE;

  const metaDescription = description || DEFAULT_DESCRIPTION;
  const metaUrl = window.location.href || DEFAULT_URL;

  return (
    <Helmet
      title={metaTitle}
      htmlAttributes={{ lang: "en" }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },

        {
          property: "og:url",
          content: metaUrl,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:title",
          content: metaTitle,
        },
        {
          property: "og:site_name",
          content: metaTitle,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:image",
          content: DEFAULT_IMAGE,
        },
        {
          property: "og:image:width",
          content: DEFAULT_IMAGE_WIDTH,
        },
        {
          property: "og:image:height",
          content: DEFAULT_IMAGE_HEIGHT,
        },

        {
          property: "twitter:card",
          content: "summary_large_image",
        },
        {
          property: "twitter:site",
          content: DEFAULT_TWITTER_SITE,
        },
        {
          property: "twitter:title",
          content: metaTitle,
        },
        {
          property: "twitter:description",
          content: metaDescription,
        },
        {
          property: "twitter:image",
          content: DEFAULT_IMAGE,
        },
        {
          property: "twitter:image_alt",
          content: "The Streamer Awards",
        },

        {
          property: "keywords",
          content: [
            "The Streamer Awards",
            "Streamer",
            "Awards",
            "Twitch",
            "Livestream",
          ]
            .concat(keywords)
            .join(","),
        },
      ].concat(meta)}
    ></Helmet>
  );
}

export default SEO;
