import { year } from "types";

export const CURRENT_AWARDS_YEAR: year = 2023;
export const AWARDS_YEARS: year[] = [2023, 2022].sort((a, b) => {
  return b - a;
});

function getIsDevMode(): boolean {
  // return true;
  return false;

  return process.env.REACT_APP_ENVIRONMENT === "development";
}

export const isDevMode = getIsDevMode();

export const GA_TRACKING_ID = "UA-131744099-3";

export const DEFAULT_PARENT_DOMAINS: string[] = ["thestreamerawards.com"];
