import React from "react";
import { Link } from "react-router-dom";

import SEO from "components/seo";
import YoutubeEmbed from "components/video/YoutubeEmbed";
import {
  categoryNominationStartDate,
  categoryNominationEndDate,
  nominationStartDate,
  nominationEndDate,
  voteStartDate,
  voteEndDate,
  awardShowDate,
  awardShowTimestamp,
  Phase,
  currentPhase,
} from "common/dates";
import { logInfo, getClientConfig } from "api";

import styles from "pages/about/About.module.scss";
import globalStyles from "styles/components.module.scss";

import dividerImg from "assets/divider.png";
import phase1Img from "pages/about/assets/phase-1.png";
import phase2Img from "pages/about/assets/phase-2.png";
import phase3Img from "pages/about/assets/phase-3.png";
import phasesImg from "pages/about/assets/phase-3.png";
import voteWeightageImg from "pages/about/assets/weightage.png";
import twitchImg from "pages/about/assets/twitch.png";
import youtubeImg from "pages/about/assets/youtube.png";
import twitterImg from "pages/about/assets/twitter.png";
import instagramImg from "pages/about/assets/instagram.png";

import qtHostImg from "pages/about/assets/host-qtcinderella.jpg";
import mayaHostImg from "pages/about/assets/host-maya.jpg";
import { GACategory } from "types";
import { formatDateTime } from "utils";

function SectionSeparator() {
  return (
    <img src={dividerImg} className={styles.DividerImg} alt="section-divider" />
  );
}

function AboutShow() {
  return (
    <div className={styles.AboutShow}>
      <p>
        The Streamer Awards is an annual awards show that celebrates
        achievements in the live-streaming industry over the past year. As the
        first ever streamer-oriented awards show, we aim to recognize the best
        that live streaming platforms have to offer, as they continue to grow in
        popularity and change how people consume entertainment.
      </p>

      <p>
        We aim to honor individuals and groups who have not only worked hard to
        create great content for their viewers but, more importantly, have
        fostered entire communities that span the globe. To do this, we try to
        have a pulse on the community to showcase a diverse set of award
        categories that include a variety of games, types of content and
        communities.
      </p>

      <p>
        So why should you care about this? Because it's{" "}
        <b>
          <i>you</i>
        </b>
        , the same viewers who watch and support these content creators, who
        will decide the nominees and winners of the awards.
      </p>
      <p>
        The Streamer Awards 2023 will be broadcasted live on {awardShowDate}{" "}
        with all your favorite streamers in attendance. Watch the red carpet
        interviews, live performances, premieres, awards ceremony and more at{" "}
        <a
          href="https://www.twitch.tv/qtcinderella"
          target="_blank"
          rel="noreferrer"
          className={globalStyles.Link}
        >
          twitch.tv/qtcinderella
        </a>
        .
      </p>
      <p>
        Follow our official Twitter{" "}
        <a
          href="https://twitter.com/StreamerAwards"
          target="_blank"
          rel="noreferrer"
          className={globalStyles.Link}
        >
          <span>&#64;</span>StreamerAwards
        </a>{" "}
        to keep up with the latest developments.
      </p>
    </div>
  );
}

class Videos extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      useYoutubeTrailer: true,
    };
  }

  async componentDidMount() {
    let ret = await getClientConfig();
    if (ret.error == null) {
      const { use_youtube_trailer } = ret.resp;

      this.setState({
        useYoutubeTrailer: use_youtube_trailer,
      });
    }
  }

  render() {
    // const { useYoutubeTrailer } = this.state;

    return (
      <React.Fragment>
        {/* {useYoutubeTrailer ? ( */}

        <div className={styles.VideosGroup}>
          <h2>The Streamer Awards 2023</h2>
          <YoutubeEmbed
        border
        embedId="FnoiaRk7eqM"
        title="The Streamer Awards 2023"
        className={globalStyles.YoutubeVideo}
      />
          <YoutubeEmbed
            border
            embedId="Fqz2bTlGifg"
            title="The Streamer Awards 2023- Red Carpet"
            className={globalStyles.YoutubeVideo}
          />
        </div>

        <div className={styles.VideosGroup}>
          <h2>The Streamer Awards 2022</h2>
          <YoutubeEmbed
            border
            embedId="l5xqg4N8-KI"
            title="The Streamer Awards 2022"
            className={globalStyles.YoutubeVideo}
          />
          <YoutubeEmbed
            border
            embedId="ybVVNJOyhB4"
            title="The Streamer Awards 2022- Red Carpet"
            className={globalStyles.YoutubeVideo}
          />
        </div>

        {/* ) : (
          <Video
            src={process.env.PUBLIC_URL + "assets/trailer.mp4"}
            poster={process.env.PUBLIC_URL + "assets/trailer.jpg"}
            type="video/mp4"
            name="trailer_about"
          />
        )} */}
      </React.Fragment>
    );
  }
}

function HowItWorks() {
  return (
    <div>
      <ul className={styles.HowItWorksContainer}>
        <li className={styles.HowItWorksItem} id="phase-1">
          <img
            src={phase1Img}
            alt="phase 1 indicator"
            className={styles.PhaseImg}
          />
          <h2>Phase 1: Choosing Categories</h2>
          <p>
            Last year's show featured some fan favorite games as categories.
            This year, we want to take it one step further by asking you to
            choose which games should have their own award category, so we get a
            pulse on what you care about. Nominate your favorite games on the{" "}
            <Link
              to="/categories"
              target="_blank"
              rel="noreferrer"
              className={globalStyles.Link}
            >
              Categories page
            </Link>
            . You can submit your categories from {categoryNominationStartDate}{" "}
            to {categoryNominationEndDate}.
          </p>
          <p>
            The most popular choices will be added as award categories for this
            year's show.
          </p>

          {currentPhase === Phase.CATEGORY_NOMINATIONS && (
            <Link to="/categories">
              <button type="button" className={styles.Btn}>
                Choose now→
              </button>
            </Link>
          )}
        </li>

        <li className={styles.HowItWorksItem} id="phase-2">
          <img
            src={phase2Img}
            alt="phase 2 indicator"
            className={styles.PhaseImg}
          />
          <h2>Phase 2: Nominations</h2>
          <p>
            Nominate your favorites for each award category on the{" "}
            <Link
              to="/nominations"
              target="_blank"
              rel="noreferrer"
              className={globalStyles.Link}
            >
              Nominations page
            </Link>
            . Nominations will be open from {nominationStartDate} to{" "}
            {nominationEndDate}.
          </p>
          <p>
            The viewer-submitted nominations are aggregated to determine the
            final nominees for the voting phase. Also, one nominee in each
            category is chosen by our panel - owners of esports and content
            organizations, commentators, writers, journalists, and other
            veterans of the live streaming and entertainment industry.
          </p>

          {currentPhase === Phase.NOMINATIONS && (
            <Link to="/nominations">
              <button type="button" className={styles.Btn}>
                Nominate now→
              </button>
            </Link>
          )}
        </li>

        <li className={styles.HowItWorksItem} id="phase-3">
          <img
            src={phase3Img}
            alt="phase 3 indicator"
            className={styles.PhaseImg}
          />
          <h2>Phase 3: Voting</h2>
          <p>
            Vote for your favorite choice from the list of nominees for each
            award category on the{" "}
            <Link
              to="/vote"
              target="_blank"
              rel="noreferrer"
              className={globalStyles.Link}
            >
              Voting page
            </Link>
            . Voting starts on {voteStartDate} and will be open until{" "}
            {voteEndDate}.
          </p>

          {currentPhase === Phase.VOTING && (
            <div>
              <Link to="/vote" className={styles.Btn}>
                <button type="button" className={styles.Btn}>
                  Vote now→
                </button>
              </Link>
            </div>
          )}

          <img
            src={voteWeightageImg}
            alt="votes weightage"
            className={styles.WeightageImg}
          />
          <p>
            The award winners are determined using a weighted combination of the
            popular vote (70%) and panelists' vote (30%), to give a voice to
            fans but still maintain integrity.
          </p>
        </li>

        <li className={styles.HowItWorksItem}>
          <img
            src={phasesImg}
            alt="results phase indicator"
            className={styles.PhaseImg}
          />
          <h2>Results</h2>
          <p>
            The winners will be announced at the in-person awards show with all
            your favorite streamers in attendance. The Streamer Awards 2023 will
            be live streamed on{" "}
            <span className={globalStyles.Time}>
              {formatDateTime(awardShowTimestamp, true, true)}
            </span>{" "}
            at{" "}
            <a
              href="https://www.twitch.tv/qtcinderella"
              target="_blank"
              rel="noreferrer"
              className={globalStyles.Link}
            >
              twitch.tv/qtcinderella
            </a>
            .
          </p>
        </li>
      </ul>
    </div>
  );
}

function AboutCreator({ socials }) {
  const qtStartDate = new Date("Aug 18, 2018");

  const now = new Date();
  const yearsSince = Math.trunc(
    (now - qtStartDate) / 1000 / 60 / 60 / 24 / 365
  );

  return (
    <div className={styles.AboutCreator}>
      <div className={styles.AboutContainer}>
        <img src={qtHostImg} alt="host-qtcinderella" className={styles.Img} />
        <p>
          <a
            href={socials["twitch"]}
            target="_blank"
            rel="noreferrer"
            className={globalStyles.Link}
          >
            QTCinderella
          </a>{" "}
          is a live streamer, YouTuber, baker, podcast host, singer, and
          interior designer. After spending a few years living out her dreams as
          a wedding cake designer, QT moved on to interior design, and is now a
          full-time content creator who has been streaming on Twitch for more
          than {yearsSince} years. She is best known for her Just Chatting
          content, bakealongs, cooking and gaming streams, and for organizing
          big events like{" "}
          <a
            href="https://youtube.com/playlist?list=PLceTTwKJQtEI7LBYGYClnlGeBzbq7CZf1"
            target="_blank"
            rel="noreferrer"
            className={globalStyles.Link}
          >
            Shitcamp
          </a>
          ,{" "}
          <a
            href="https://youtu.be/Prf83_zdyI0"
            target="_blank"
            rel="noreferrer"
            className={globalStyles.Link}
          >
            Christmas Concerts
          </a>
          ,{" "}
          <a
            href="https://youtube.com/playlist?list=PLceTTwKJQtEJ-dBwv_ZfBE0OPLpuSoY9W"
            target="_blank"
            rel="noreferrer"
            className={globalStyles.Link}
          >
            Master Baker
          </a>{" "}
          and this awards show!
        </p>

        <div className={styles.SocialsContainer}>
          <a
            href={socials["twitch"]}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <img
              src={twitchImg}
              className={styles.SocialImg}
              alt="twitch-icon"
              onClick={() => {
                logInfo("qt_socials-twitch", "", GACategory.Visit);
              }}
            />
          </a>
          <a
            href={socials["youtube"]}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <img
              src={youtubeImg}
              className={styles.SocialImg}
              alt="youtube-icon"
              onClick={() => {
                logInfo("qt_socials-youtube", "", GACategory.Visit);
              }}
            />
          </a>
          <a
            href={socials["twitter"]}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <img
              src={twitterImg}
              className={styles.SocialImg}
              alt="twitter-icon"
              onClick={() => {
                logInfo("qt_socials-twitter", "", GACategory.Visit);
              }}
            />
          </a>
          <a
            href={socials["instagram"]}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            <img
              src={instagramImg}
              className={styles.SocialImg}
              alt="instagram-icon"
              onClick={() => {
                logInfo("qt_socials-instagram", "", GACategory.Visit);
              }}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

function About() {
  const qtSocials = {
    twitch: "https://twitch.tv/qtcinderella",
    youtube: "https://www.youtube.com/c/QTCinderella",
    twitter: "https://twitter.com/qtcinderella",
    instagram: "https://instagram.com/qtcinderella",
  };

  const sections = [
    {
      title: "About the show",
      component: <AboutShow />,
    },
    {
      title: "How it works",
      id: "how-it-works",
      component: <HowItWorks />,
    },
    {
      title: "About the Creator",
      component: <AboutCreator socials={qtSocials} />,
    },
    {
      title: "Past Shows",
      component: <Videos />,
    },
  ];

  return (
    <div className={styles.AboutPage}>
      <SEO
        title="About"
        description="Learn more about the live Streamer Awards show, how it works, the timeline and the hosts."
      />

      {sections.map((section, idx) => {
        return (
          <div key={idx}>
            {idx !== 0 && <SectionSeparator />}
            <h1 id={section.id}>{section.title}</h1>
            {section.component}
          </div>
        );
      })}
    </div>
  );
}

export default React.memo(About);
