import { seconds } from "types";

export const removeDuplicates = (a: Array<number>): Array<number> => {
  const seen: { [key: number]: boolean } = {};
  return a.filter(function (item) {
    return seen.hasOwnProperty(item) ? false : (seen[item] = true);
  });
};

export const isNumeric = (n: string): boolean => {
  return !isNaN(parseFloat(n)) && isFinite(Number(n));
};

export const getDateDiffInSecs = (d1: Date, d2: Date): seconds => {
  return (d1.valueOf() - d2.valueOf()) / 1000;
};

const days: { [i: number]: string } = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

const months: { [i: number]: string } = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

// "January 31, 2023"
// includeDay: "Tuesday, 31 January 2023"
export const formatDate = (d: Date, includeDay?: boolean): string => {
  const date: number = d.getDate();
  const month: string = months[d.getMonth()];
  const year: number = d.getFullYear();
  const day: string = days[d.getDay()];

  if (includeDay) {
    return `${day}, ${date} ${month} ${year}`;
  }

  return `${month} ${date}, ${year}`;
};

// Adapted from https://stackoverflow.com/a/36822046/8802161
// "PST"
// "EST"
// "GMT+1"
// "GMT+3"
// "GMT+5:30"
// "GMT+8"
// "PST (GMT-8)"
// "MST (GMT-7)"
// "EST (GMT-5)"
// "UTC (GMT)"
const getTimezoneName = (d: Date, includeGmtOffset?: boolean): string => {
  const dateOnly = d.toLocaleDateString(undefined);
  const dateWithTimeZoneName = d.toLocaleDateString(undefined, {
    timeZoneName: "short",
  });
  const dateWithTimeZoneGmt = d.toLocaleDateString(undefined, {
    timeZoneName: "shortOffset",
  });

  let timeZoneName = "";

  // Trying to remove date from the string in a locale-agnostic way
  let shortIndex = dateWithTimeZoneName.indexOf(dateOnly);
  if (shortIndex < 0) {
    // in some magic case when short representation of date is not present in the long one,
    // just return the long one as a fallback, since it should contain the timezone's name
    return dateWithTimeZoneName;
  }

  timeZoneName =
    dateWithTimeZoneName.substring(0, shortIndex) +
    dateWithTimeZoneName.substring(shortIndex + dateOnly.length);

  // by this time `timeZoneName` should be the timezone's name with some punctuation -
  // trim it from both sides
  timeZoneName = timeZoneName.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, "");

  if (!includeGmtOffset) {
    return timeZoneName;
  }

  let timeZoneGmt = "";

  shortIndex = dateWithTimeZoneGmt.indexOf(dateOnly);
  if (shortIndex >= 0) {
    timeZoneGmt =
      dateWithTimeZoneGmt.substring(0, shortIndex) +
      dateWithTimeZoneGmt.substring(shortIndex + dateOnly.length);

    timeZoneGmt = timeZoneGmt.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, "");
  }

  if (timeZoneName === timeZoneGmt) {
    return timeZoneName;
  }

  return `${timeZoneName} (${timeZoneGmt})`;
};

const pad = (n: number, size: number) => {
  let num = n.toString();
  while (num.length < size) {
    num = "0" + num;
  }
  return num;
};

// 18:24
// 18:24 EST
// 6:22 PM EST
// 18:24 EST (GMT-5)
export const formatTime = (
  d: Date,
  includeTimeZone?: boolean,
  hour12?: boolean,
  includeGmtOffset?: boolean
): string => {
  let timeStr = d.toLocaleString(undefined, {
    hour12: hour12,
    timeStyle: "short",
  });

  // const hour: number = d.getHours();
  // const minute: number = d.getMinutes();

  // let timeStr = `${pad(hour, 1)}:${pad(minute, 2)}`;
  // if (hour12) {
  //   if (hour <= 12) {
  //     timeStr = `${pad(hour, 1)}:${pad(minute, 2)} AM`;
  //   } else {
  //     const h = hour % 12;
  //     timeStr = `${pad(h, 1)}:${pad(minute, 2)} PM`;
  //   }
  // }

  if (includeTimeZone) {
    const timeZone = getTimezoneName(d, includeGmtOffset);
    timeStr += includeGmtOffset ? ` ${timeZone}` : ` (${timeZone})`;
  }

  return timeStr.trim();
};

// Thursday, Mar 2, 2023 18:26
// Thursday, Mar 2, 2023 18:26 EST
// Thursday, Mar 2, 2023 6:26PM EST
// Thursday, Mar 2, 2023 18:26 EST (GMT-5)
export const formatDateTime = (
  d: Date,
  includeTimeZone?: boolean,
  hour12?: boolean,
  includeGmtOffset?: boolean
): string => {
  const day: string = d.toLocaleDateString(undefined, { weekday: "long" });
  const date: number = d.getDate();
  const month: string = d.toLocaleDateString(undefined, { month: "short" });
  const year: number = d.getFullYear();

  const dateStr = `${day}, ${date} ${month} ${year}`;
  // const dateStr = d.toLocaleDateString(undefined, {
  //   weekday: "long",
  //   year: "numeric",
  //   month: "short",
  //   day: "numeric",
  // });

  const timeStr = formatTime(d, includeTimeZone, hour12, includeGmtOffset);

  return `${dateStr}, ${timeStr}`;
};

const REGEX_TWITCH_VIDEO = /^.*(?:twitch.tv\/videos)\/([^\?]*)(?:.*)?$/;
const REGEX_TWITCH_CLIP =
  /^.*(?:clips.twitch.tv|twitch.tv\/.*\/clip)\/([^?]*)(?:\?.*)?$/;
const REGEX_YOUTUBE_VIDEO =
  /(?:youtu\.be\/|youtube\.com(?:\/embed\/|\/live\/|\/v\/|\/watch\?v=|\/user\/\S+|\/ytscreeningroom\?v=|\/sandalsResorts#\w\/\w\/.*\/))([^\/&\?]{10,12})/;

export function getTwitchVideoId(url: string): string | null {
  const matchTwitchVideo = url.match(REGEX_TWITCH_VIDEO);
  if (matchTwitchVideo == null) {
    return null;
  }

  return matchTwitchVideo[1];
}

export function getTwitchClipId(url: string): string | null {
  const matchTwitchClip = url.match(REGEX_TWITCH_CLIP);
  if (matchTwitchClip == null) {
    return null;
  }

  return matchTwitchClip[1];
}

export function getYoutubeVideoId(url: string): string | null {
  const matchYTVid = url.match(REGEX_YOUTUBE_VIDEO);
  if (matchYTVid == null) {
    return null;
  }

  return matchYTVid[1];
}
