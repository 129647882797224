import React, { useState, useEffect } from "react";

import UserContext, { userContext } from "contexts/UserContext";
import { getUserProfile } from "api";

import type { User } from "types";

type Props = {
  children: React.ReactNode;
};

const UserProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User | null | undefined>(undefined);

  useEffect(() => {
    async function fetchUser() {
      const ret = await getUserProfile();
      if (ret.error != null || ret.resp == null) {
        // toast.error(
        //   "Could not load Google Auth to log in. To submit your responses, try enabling all cookies or do not use incognito mode.",
        //   { duration: 10000 }
        // );
        // console.log("not logged in:", ret);

        setUser(null);
        return;
      }

      setUser(ret.resp);
    }

    fetchUser();
  }, []);

  const value: userContext = {
    user: user,
    setUser: setUser,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
