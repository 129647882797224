import styles from "components/form/input/Checkmark.module.scss";

type CheckmarkProps = {
  colour?: string;
  borderColour?: string;
};

// Adapted from https://github.com/stanleyxu2005/react-checkmark
const Checkmark: React.FC<CheckmarkProps> = ({ colour, borderColour }) => {
  const style: { [cssVar: string]: string } = {};

  if (colour) {
    style["--checkmark-fill-color"] = colour;

    if (!borderColour) {
      style["--checkmark-border-color"] = colour;
    }
  }

  if (borderColour) {
    style["--checkmark-border-color"] = borderColour;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      className={styles.Checkmark}
      style={style}
    >
      <circle
        className={styles.CheckmarkCircle}
        cx="26"
        cy="26"
        r="25"
        fill="none"
      ></circle>
      <path
        className={styles.CheckmarkCheck}
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
      ></path>
    </svg>
  );
};

export default Checkmark;
