import React, { useState, useEffect } from "react";
import * as htmlToImage from "html-to-image";

import SimpleBtn from "components/button/SimpleBtn";

import { logInfo, getPickEmsSubmission } from "api";
import { awardShowTimestamp } from "common/dates";

import styles from "pages/pickem/Success.module.scss";
import globalStyles from "styles/components.module.scss";
import defaultPlaceholder from "assets/default-placeholder.png";
import { CURRENT_AWARDS_YEAR } from "common";
import { CategoryWithNominees, GACategory, InputType, Nominee } from "types";
import { formatDateTime } from "utils";

const ID_MY_PREDICTIONS = "my-predictions";
const CELLS_PER_ROW = parseInt(styles.cellsPerRow);
const GENERATE_COLLAGE_IMG_DELAY_MS = 500;

type CategorySubmissionMap = { [category_id: number]: number };

const fetchSubmissionsForUser = async (): Promise<CategorySubmissionMap> => {
  const categorySubmissions: CategorySubmissionMap = {};

  const ret = await getPickEmsSubmission(CURRENT_AWARDS_YEAR);
  if (ret.error != null || ret.resp == null) {
    return categorySubmissions;
  }

  const { submissions } = ret.resp;
  if (!submissions) {
    return categorySubmissions;
  }

  submissions.forEach(({ category_id, nominee_ids }) => {
    if (nominee_ids && nominee_ids.length > 0) {
      categorySubmissions[category_id] = nominee_ids[0];
    }
  });

  return categorySubmissions;
};

type SubmissionSuccessProps = {
  categories: Array<CategoryWithNominees>;
  handleClick: () => void;
};

const SubmissionSuccess: React.FunctionComponent<SubmissionSuccessProps> = ({
  categories,
  handleClick,
}) => {
  const [submissionsCollage, setSubmissionsCollage] =
    useState<React.ReactNode>(null);

  const generateCollage = async () => {
    const categorySubmissions = await fetchSubmissionsForUser();

    const submissionChoices: Array<{
      catIdx: number;
      imageUrl: string;
      title: string;
    }> = [];

    categories.forEach((c, idx) => {
      if (
        !c?.details?.input?.type ||
        c.details.input.type !== InputType.RadioGroup
      ) {
        return;
      }

      if (!c.nominees || c.nominees.length === 0) {
        return;
      }

      const nom = c.nominees.find(
        (n) => n.nominee_id === categorySubmissions[c.id]
      );

      const image = nom?.details?.thumbnail_url || defaultPlaceholder;
      const title = `${c.name}- ${nom?.name || ""}`;

      submissionChoices.push({
        catIdx: idx + 1,
        imageUrl: image,
        title: title,
      });
    });

    const collageRows = [];
    for (let i = 0; i * CELLS_PER_ROW < submissionChoices.length; i++) {
      const start = i * CELLS_PER_ROW;
      const end = start + CELLS_PER_ROW;

      collageRows.push(submissionChoices.slice(start, end));
    }

    const collage: React.ReactNode = (
      <div>
        <div className={styles.SubmissionCollage} id={ID_MY_PREDICTIONS}>
          {collageRows.map((row, rowIdx) => {
            return (
              <div key={rowIdx} className={styles.SubmissionCollageRow}>
                {row.map(({ catIdx, imageUrl, title }, idx) => {
                  return (
                    <div key={idx} className={styles.ShareCollageCell}>
                      <img
                        src={process.env.PUBLIC_URL + imageUrl}
                        title={title}
                        className={styles.ShareCollageImg}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = defaultPlaceholder;
                        }}
                        alt={title}
                      />
                      <small className={styles.ShareCollageIdx}>{catIdx}</small>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );

    setSubmissionsCollage(collage);
  };

  useEffect(() => {
    generateCollage();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      const node = document.getElementById(ID_MY_PREDICTIONS) as HTMLElement;

      htmlToImage
        .toPng(node)
        .then(function (dataUrl) {
          var img = new Image();
          img.src = dataUrl;
          img.className = styles.SubmissionCollageImg;

          node.replaceWith(img);
          // node.appendChild(img);

          // var div = document.createElement("div");
          // div.id = "container";
          // div.className = styles.SubmissionCollageImg;
          // div.appendChild(img);
          // node.replaceWith(div);
        })
        .catch(function (error) {
          console.error("error creating shareable image:", error);
        });
    }, GENERATE_COLLAGE_IMG_DELAY_MS);
  }, [submissionsCollage]);

  return (
    <React.Fragment>
      <div className={styles.Success}>
        <h3>Your Pick'em predictions have been submitted!</h3>
        <p>
          Thank you for participating{" "}
          <img
            src="assets/widepeepoHappy.png"
            className={globalStyles.WidepeepoHappyImg}
            alt="widepeepoHappy"
          />
        </p>

        <p>
          Share your predictions with{" "}
          <a
            // href="https://twitter.com/search?q=%23TheStreamerAwards"
            href={`https://twitter.com/search?q=(%23TheStreamerAwards)%20since%3A${CURRENT_AWARDS_YEAR}-01-01&src=typed_query`}
            target="_blank"
            rel="noreferrer"
            className={globalStyles.Link}
            onClick={() => {
              logInfo("#TheStreamerAwards", "", GACategory.Visit);
            }}
          >
            <b>&#35;TheStreamerAwards</b>
          </a>
        </p>

        {submissionsCollage}

        <p>
          If you want to update some of your responses, you can just re-submit
          this form with your updates.
        </p>

        <p>
          Your results will be available after the winners are announced at The
          Streamer Awards on{" "}
          <span className={globalStyles.Time}>
            {formatDateTime(awardShowTimestamp, true, true)}
          </span>{" "}
          at{" "}
          <a
            href="https://www.twitch.tv/qtcinderella"
            target="_blank"
            rel="noreferrer"
            className={globalStyles.Link}
          >
            twitch.tv/qtcinderella
          </a>
          !
        </p>
      </div>

      <SimpleBtn handleClick={handleClick} align="center">
        ← Back to Pick'em
      </SimpleBtn>
    </React.Fragment>
  );
};

export default React.memo(SubmissionSuccess);
