import * as auth from "utils/auth";
import { useMockData, get, post } from "api/helper";
import { clientConfig, winnersByCategory } from "api/const";
import * as mock from "api/mock";
import * as ga from "utils/ga";

import type { Response, Headers } from "api/helper";
import type {
  GetCategoryNominationCategoriesReq,
  GetNominationCategoriesReq,
  GetPredictionCategoriesReq,
  GetPredictionWinnersByCategoryReq,
  GetUserCategorySubmissionReq,
  GetUserNominationSubmissionReq,
  GetUserPredictionResultReq,
  GetUserPredictionSubmissionReq,
  GetUserVoteSubmissionReq,
  GetVoteCategoriesReq,
  GetWinnersByCategoryReq,
  LogLevel,
  SubmitCategoriesForUserReq,
  SubmitNominationsForUserReq,
  SubmitPredictionsForUserReq,
  SubmitVotesForUserReq,
} from "types/requests";
import type {
  GetUserResp,
  GetClientConfigResp,
  GetUserCategorySubmissionResp,
  GetCategoryNominationCategoriesResp,
  GetWinnersByCategoryResp,
  GetUserNominationSubmissionResp,
  GetUserVoteSubmissionResp,
  GetUserPredictionSubmissionResp,
  GetUserPredictionResultResp,
  GetNominationCategoriesResp,
  GetVoteCategoriesResp,
  GetPredictionCategoriesResp,
  GetPredictionWinnersByCategoryResp,
} from "types/responses";
import {
  CategoryWithNominees,
  GACategory,
  GALabel,
  Nominee,
  UserCategorySubmission,
  UserNominationSubmission,
  UserPredictionSubmission,
  UserVoteSubmission,
  year,
} from "types";

const DEFAULT_FORM_VALUE = "";

async function logMessage(
  level: LogLevel,
  msg: string,
  info?: string
): Promise<Response<null>> {
  if (!msg) {
    return { resp: null };
  }

  const req = {
    message: msg.substring(0, 200),
    data: info && info.substring(0, 1000),
    level: level || "unspecified",
  };

  const ret = await post("/api/log/log_message", req);
  return ret;
}

export async function logError(
  errorMsg: string,
  errorInfo?: string
): Promise<Response<null>> {
  ga.trackException(errorMsg);

  return logMessage("error", errorMsg, errorInfo);
}

export async function logInfo(
  msg: string,
  moreInfo?: string,
  category?: GACategory,
  label?: GALabel
): Promise<Response<null>> {
  ga.trackEvent(msg, category, label);

  return logMessage("info", msg, moreInfo);
}

export async function logoutUser(): Promise<Response<null>> {
  if (useMockData) {
    const ret = {
      resp: null,
    };

    return ret;
  }

  const ret = await get("/api/auth/logout");
  return ret;
}

export async function getUserProfile(): Promise<Response<GetUserResp>> {
  if (useMockData) {
    const ret = {
      resp: mock.user,
    };

    return ret;
  }

  const ret = await get("/api/user/get_profile");
  return ret;
}

export async function getClientConfig(): Promise<
  Response<GetClientConfigResp>
> {
  if (useMockData) {
    return {
      resp: mock.clientConfig,
    };
  }

  // return {
  //   resp: clientConfig,
  // };

  const ret = await get("/api/config/get_client_config");
  return ret;
}

export async function getWinners(
  year: year
): Promise<Response<GetWinnersByCategoryResp>> {
  if (useMockData) {
    return {
      resp: mock.winnersByCategory,
    };
  }

  if (winnersByCategory.has(year)) {
    return {
      resp: winnersByCategory.get(year),
    };
  }

  const req: GetWinnersByCategoryReq = {
    year: year,
  };

  const ret = await get("/api/category/get_winners_by_category", [
    ["year", String(req.year)],
  ]);
  return ret;
}

export async function getPredictionWinners(
  year: year
): Promise<Response<GetPredictionWinnersByCategoryResp>> {
  if (useMockData) {
    const ret = {
      resp: mock.winnersByCategory,
    };

    return ret;
    // return new Promise((resolve) => setTimeout(() => resolve(ret), 2000));
  }

  const req: GetPredictionWinnersByCategoryReq = {
    year: year,
  };

  const ret = await get("/api/prediction/get_prediction_category_winners", [
    ["year", String(req.year)],
  ]);
  return ret;
}

export async function getCategoryNominationCategories(
  year: year
): Promise<Response<GetCategoryNominationCategoriesResp>> {
  if (useMockData) {
    let ret = {
      resp: {
        categories: mock.catNomCategories,
      },
    };

    /*
    let id = 1;
    ret.resp.categories = ret.resp.categories.map((c) => {
      const newC: any = Object.assign({}, c);
      if (newC.nominees != null) {
        return newC;
      }

      const noms = [];
      for (let i = 0; i < 8; i++) {
        noms.push({
          nominee_id: id,
          name: `${id}`,
          media: { thumbnail: `assets/downloads/nominees/${id}.jpg` },
        });

        id++;
      }

      newC.nominees = noms;

      return newC;
    });

    ret.resp.categories = ret.resp.categories.filter((c, idx) => idx < 5);
*/

    return ret;
  }

  const req: GetCategoryNominationCategoriesReq = {
    year: year,
  };

  const ret = await get(
    "/api/category_nomination/get_category_nomination_categories",
    [["year", String(req.year)]]
  );

  return ret;
}

export async function submitCategoryNominations(
  year: year,
  submissions: UserCategorySubmission[]
): Promise<Response<null>> {
  const req: SubmitCategoriesForUserReq = {
    year: year,
    submissions: submissions,
  };

  const ret = await post(
    "/api/category_nomination/submit_category_nominations_for_user",
    req
  );
  return ret;
}

export async function getCategoryNominationsSubmission(
  year: year
): Promise<Response<GetUserCategorySubmissionResp>> {
  if (useMockData) {
    const ret = {
      resp: mock.catNomsSubmission,
    };

    return ret;
  }

  const req: GetUserCategorySubmissionReq = {
    year: year,
  };

  const ret = await get(
    "/api/category_nomination/get_category_nominations_submission_for_user",
    [["year", String(req.year)]]
  );

  return ret;
}

export async function getNominationCategories(
  year: year
): Promise<Response<GetNominationCategoriesResp>> {
  if (useMockData) {
    let ret = {
      resp: {
        categories: mock.placeholderCategories,
      },
    };

    ret.resp.categories = ret.resp.categories.map((c) => {
      const newC = Object.assign({}, c);
      newC.nominees = undefined;
      return newC;
    });

    return ret;
  }

  const req: GetNominationCategoriesReq = {
    year: year,
  };

  const ret = await get("/api/nomination/get_nomination_categories", [
    ["year", String(req.year)],
  ]);

  return ret;
}

export async function submitNominations(
  year: year,
  submissions: UserNominationSubmission[]
): Promise<Response<null>> {
  try {
    const req: SubmitNominationsForUserReq = {
      year: year,
      submissions: submissions,
    };

    const ret = await post("/api/nomination/submit_nominations_for_user", req);
    return ret;
  } catch (err) {
    return { error: err };
  }
}

export async function getNominationsSubmission(
  year: year
): Promise<Response<GetUserNominationSubmissionResp>> {
  if (useMockData) {
    return { resp: { submissions: [] } };
  }

  const customHeaders: Headers = {};

  // const token = auth.getUserToken();
  // if (token) {
  // customHeaders["Authorization"] = "Bearer " + token;
  // }

  const req: GetUserNominationSubmissionReq = {
    year: year,
  };

  const ret = await get(
    "/api/nomination/get_nominations_submission_for_user",
    [["year", String(req.year)]],
    customHeaders
  );

  return ret;
}

export async function getVoteCategories(
  year: year
): Promise<Response<GetVoteCategoriesResp>> {
  if (useMockData) {
    let ret = {
      resp: {
        categories: mock.placeholderCategories,
      },
    };

    let id = 1;
    ret.resp.categories = ret.resp.categories.map((c) => {
      const newC: CategoryWithNominees = Object.assign({}, c);
      if (newC.nominees != null) {
        return newC;
      }

      const noms: Nominee[] = [];
      for (let i = 0; i < 4; i++) {
        noms.push({
          nominee_id: id,
          name: `${id}`,
          details: {
            thumbnail_url: `assets/downloads/nominees/${id}.jpg`,
            url: "https://sullygnome.com/channel/qtcinderella",
          },
        });

        id++;
      }

      newC.nominees = noms;

      return newC;
    });

    ret.resp.categories.forEach((c) => {
      c.nominees?.forEach((n, i) => {
        const clipUrl =
          i == 0
            ? ""
            : i % 2 == 0
            ? "https://clips.twitch.tv/ThankfulRoughPistachioDoritosChip--hr22oonOPFDD8PX"
            : "https://www.youtube.com/watch?v=cHuAG-MPG-A";

        n.details = {
          ...n.details,
          clip_url: clipUrl,
        };
      });
    });

    return ret;
  }

  const req: GetVoteCategoriesReq = {
    year: year,
  };

  const ret = await get("/api/vote/get_voting_categories", [
    ["year", String(req.year)],
  ]);

  return ret;
}

export async function submitVotes(
  year: year,
  votes: UserVoteSubmission[]
): Promise<Response<null>> {
  const req: SubmitVotesForUserReq = {
    year: year,
    submissions: votes,
  };

  const ret = await post("/api/vote/submit_votes_for_user", req);
  return ret;
}

export async function getVotesSubmission(
  year: year
): Promise<Response<GetUserVoteSubmissionResp>> {
  if (useMockData) {
    return {
      resp: mock.votesSubmission,
    };
  }

  const customHeaders: Headers = {};

  // const token = auth.getUserToken();
  // if (token) {
  // customHeaders["Authorization"] = "Bearer " + token;
  // }

  const req: GetUserVoteSubmissionReq = {
    year: year,
  };

  const ret = await get(
    "/api/vote/get_votes_submission_for_user",
    [["year", String(req.year)]],
    customHeaders
  );

  return ret;
}

export async function getPickEmCategories(
  year: year
): Promise<Response<GetPredictionCategoriesResp>> {
  if (useMockData) {
    let ret = {
      resp: {
        categories: mock.placeholderCategories,
      },
    };

    ret.resp.categories = ret.resp.categories.map((c) => {
      const newC = Object.assign({}, c);
      newC.nominees = undefined;
      return newC;
    });

    return ret;
  }

  const req: GetPredictionCategoriesReq = {
    year: year,
  };

  const ret = await get("/api/prediction/get_prediction_categories", [
    ["year", String(req.year)],
  ]);

  return ret;
}

export async function getPickEmsSubmission(
  year: year
): Promise<Response<GetUserPredictionSubmissionResp>> {
  if (useMockData) {
    return {
      resp: {
        submissions: [],
      },
    };
  }

  const customHeaders: Headers = {};

  // const token = auth.getUserToken();
  // if (token) {
  //   customHeaders["Authorization"] = "Bearer " + token;
  // }

  const req: GetUserPredictionSubmissionReq = {
    year: year,
  };

  const ret = await get(
    "/api/prediction/get_predictions_submission_for_user",
    [["year", String(req.year)]],
    customHeaders
  );

  return ret;
}

export async function submitPickEms(
  year: year,
  predictions: UserPredictionSubmission[]
): Promise<Response<null>> {
  const req: SubmitPredictionsForUserReq = {
    year: year,
    submissions: predictions,
  };

  const ret = await post("/api/prediction/submit_predictions_for_user", req);
  return ret;
}

export async function getPickEmsResult(
  year: year
): Promise<Response<GetUserPredictionResultResp>> {
  if (useMockData) {
    return { resp: {} };
  }

  const customHeaders: Headers = {};

  // const token = auth.getUserToken();
  // if (token) {
  //   customHeaders["Authorization"] = "Bearer " + token;
  // }

  const req: GetUserPredictionResultReq = {
    year: year,
  };

  const ret = await get(
    "/api/prediction/get_predictions_result_for_user",
    [["year", String(req.year)]],
    customHeaders
  );
  return ret;
}

function oldGetLocalUser() {
  const user = auth.getUser();

  if (user && user.email) {
    return user.email;
  }

  return "";
}
