import React from "react";
import { Link } from "react-router-dom";

import { Phase, currentPhase } from "common/dates";
import { logInfo } from "api";
import { isDevMode } from "common";

import logo from "assets/logo.png";
import styles from "components/nav/Navbar.module.scss";
import { GACategory, GALabel } from "types";

type NavbarProps = {};

// TODO: return array of nav items, and render in Nav separately
const Navbar: React.FunctionComponent<NavbarProps> = () => {
  // const renderPhaseNav = (): Array<{path:string,title:string}> => {
  const renderPhaseNav = () => {
    const navItems = [];

    if (isDevMode) {
      return (
        <>
          <li>
            <Link
              to="/categories"
              className={`${styles.Link} ${styles.NavLink}`}
            >
              CATEGORIES
            </Link>
          </li>
          <li>
            <Link
              to="/nominations"
              className={`${styles.Link} ${styles.NavLink}`}
            >
              NOMINATIONS
            </Link>
          </li>
          <li>
            <Link to="/vote" className={`${styles.Link} ${styles.NavLink}`}>
              VOTE
            </Link>
          </li>
          <li>
            <Link to="/pickem" className={`${styles.Link} ${styles.NavLink}`}>
              PICK'EM
            </Link>
          </li>
          {/* <li>
            <Link to="/winners" className={`${styles.Link} ${styles.NavLink}`}>
              PAST WINNERS
            </Link>
          </li> */}
        </>
      );
    } else {
      switch (currentPhase) {
        case Phase.CATEGORY_NOMINATIONS:
          return (
            <li>
              <Link
                to="/categories"
                className={`${styles.Link} ${styles.NavLink}`}
              >
                CATEGORIES
              </Link>
            </li>
          );

        case Phase.NOMINATIONS:
          return (
            <li>
              <Link
                to="/nominations"
                className={`${styles.Link} ${styles.NavLink}`}
              >
                NOMINATIONS
              </Link>
            </li>
          );

        case Phase.VOTING:
          return (
            <li>
              <Link to="/vote" className={`${styles.Link} ${styles.NavLink}`}>
                VOTE
              </Link>
            </li>
          );

        case Phase.PICKEMS:
          return (
            <li>
              <Link to="/pickem" className={`${styles.Link} ${styles.NavLink}`}>
                PICK'EM
              </Link>
            </li>
          );

        case Phase.AWARDS_SHOW:
          return (
            <li>
              <Link to="/pickem" className={`${styles.Link} ${styles.NavLink}`}>
                PICK'EM
              </Link>
            </li>
          );

        case Phase.WINNERS_ANNOUNCED:
        // return (
        //   <li>
        //     <Link to="/pickem" className={`${styles.Link} ${styles.NavLink}`}>
        //       PICK'EM
        //     </Link>
        //   </li>
        // );

        default:
          return null;
      }
    }

    navItems.push({ path: "/about", title: "ABOUT" });
    navItems.push({ path: "/faq", title: "FAQ" });

    return navItems;
  };

  let [isClosed, setIsClosed] = React.useState(true);

  return (
    <nav
      className={`${styles.Container} ${styles.Nav}  ${
        isClosed ? "" : styles.Responsive
      }`}
    >
      <div className={styles.FixedNav}>
        <button
          className={styles.Bars}
          onClick={() => {
            setIsClosed(!isClosed);
          }}
        >
          <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.Hamburger}
          >
            <path
              d="M1 0h14a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2zm0 6h14a1 1 0 0 1 0 2H1a1 1 0 1 1 0-2zm0 6h14a1 1 0 0 1 0 2H1a1 1 0 0 1 0-2z"
              fillRule="evenodd"
            ></path>
          </svg>
        </button>

        <Link to="/home" className={styles.HomeLogo}>
          <div>
            <img
              src={logo}
              alt="The Streamer Awards"
              className={styles.HomeLogoImg}
            />
          </div>
        </Link>
      </div>

      <div className={`${styles.NavMenu}`}>
        <ul>
          <li>
            <a
              href="https://merch.thestreamerawards.com"
              className={`${styles.Link} ${styles.NavLink}`}
              // className={`${styles.Link} ${styles.Brighten} ${styles.NavLink}`}
              target="_blank"
              rel="noopener"
              onClick={() => {
                logInfo("merch", "", GACategory.Visit, GALabel.Navbar);
              }}
            >
              MERCH
            </a>
          </li>

          {renderPhaseNav()}

          {/* <li>
            <a
              href="https://www.ticketmaster.com/event/09005E32A2E0456F"
              className={`${styles.Link} ${styles.NavLink}`}
              target="_blank"
              rel="noopener"
              onClick={() => {
                logInfo("get_tickets", "", GACategory.Visit, GALabel.Navbar);
              }}
            >
              GET TICKETS
            </a>
          </li> */}

          <li>
            <Link to="/winners" className={`${styles.Link} ${styles.NavLink}`}>
              WINNERS
            </Link>
          </li>
          <li>
            <Link to="/about" className={`${styles.Link} ${styles.NavLink}`}>
              ABOUT
            </Link>
          </li>
          <li>
            <Link to="/faq" className={`${styles.Link} ${styles.NavLink}`}>
              FAQ
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
