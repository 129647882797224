import React from "react";
import { useEffect, useState } from "react";
import { useField } from "formik";
import toast from "react-hot-toast";

import Checkmark from "components/form/input/Checkmark";
import { logInfo } from "api";

import styles from "components/form/input/CheckboxInputGroup.module.scss";
import defaultProfile from "assets/default-profile.jpg";
import { GACategory, GALabel } from "types";

export type CheckboxInputOption = {
  label: string; // displayed value
  value: string; // internal value
  url?: string;
  thumbnail_url?: string;
};

type CheckboxProps = CheckboxInputOption & {
  checked?: boolean;
  disabled?: boolean;
  handleClick: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkboxColour?: string;
  minimalStyle?: boolean;
} & React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  value,
  url,
  thumbnail_url,
  checked = false,
  disabled,
  onChange,
  handleClick,
  checkboxColour,
  minimalStyle,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleCheckboxClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (e.ctrlKey && url) {
      logInfo(`link-${url}`, "", GACategory.Visit, GALabel.Checkbox);
      window.open(url, "_blank");
      return;
    }
  };

  const style: { [cssVar: string]: string } = {};
  if (checkboxColour) {
    style["--checkbox-color"] = checkboxColour;
  }

  return (
    <div
      title={label}
      className={`${styles.CheckboxWrapper} ${
        minimalStyle ? styles.Minimal : ""
      }`}
    >
      <a href={url} target="_blank" rel="noreferrer">
        <label>
          {minimalStyle && (
            <span className={styles.CheckboxSpanText}>{label}</span>
          )}
          <input
            type="checkbox"
            className={styles.CheckboxInput}
            style={style}
            checked={isChecked}
            disabled={disabled}
            onClick={handleCheckboxClick}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (disabled) {
                return;
              }

              handleClick();

              onChange && onChange(e);
            }}
            {...props}
          />

          {!minimalStyle && (
            <div
              className={`${styles.CheckboxContainer} ${
                disabled ? styles.CheckboxDisabled : ""
              }`}
            >
              <img
                className={styles.CheckboxImg}
                src={process.env.PUBLIC_URL + thumbnail_url}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = defaultProfile;
                }}
                alt="profile-pic"
              />

              <div className={styles.CheckboxSpan}>
                <div className={styles.CheckboxSpanText}>{label}</div>
                {checked && (
                  <span className={styles.CheckedInput}>
                    <Checkmark colour={checkboxColour} />
                  </span>
                )}
              </div>
            </div>
          )}
        </label>
      </a>
    </div>
  );
};

export type CheckboxInputGroupValue = {
  checkedValues: Array<string>;
  custom: Array<string>;
};

type CheckboxInputGroupProps = {
  name: string;
  options: Array<CheckboxInputOption>;
  maxCheckedOptionsAllowed?: number;
  setClearInput: (f: () => void) => void;
  handleClick: () => void;
  numCustomInputs?: number; // only supports 0 and 1 currently
  customInputPrompt?: string;
  maxLength?: number;
  checkboxColour?: string;
};

const CheckboxInputGroup = React.forwardRef<
  HTMLInputElement,
  CheckboxInputGroupProps
>(
  (
    {
      name,
      options,
      maxCheckedOptionsAllowed,
      setClearInput,
      handleClick,
      numCustomInputs = 0,
      customInputPrompt,
      maxLength = 64,
      checkboxColour,
    },
    ref
  ) => {
    const CUSTOM_INPUT_IDX = 0;

    const DEFAULT_CHECKBOX_GROUP_VALUE: CheckboxInputGroupValue = {
      checkedValues: [],
      custom: Array.from({ length: numCustomInputs }, (_, i) => ""),
    };

    const [field, meta, helpers] = useField<CheckboxInputGroupValue>({
      name: name,
      multiple: true,
    });

    const getCustomInput = (
      val: CheckboxInputGroupValue,
      idx: number
    ): string => {
      if (val?.custom && val.custom.length > idx) {
        return val.custom[idx];
      }

      return "";
    };

    const [displayedText, setDisplayedText] = useState<string>(
      getCustomInput(meta.value, CUSTOM_INPUT_IDX)
    );

    useEffect(() => {
      if (!meta.value) {
        helpers.setValue(DEFAULT_CHECKBOX_GROUP_VALUE);
      }
    }, []);

    useEffect(() => {
      if (meta.value?.custom?.length > numCustomInputs) {
        const newGroupValue = Object.assign(
          DEFAULT_CHECKBOX_GROUP_VALUE,
          meta.value
        );

        newGroupValue.custom = newGroupValue.custom?.filter(
          (_, i) => i < numCustomInputs
        );

        helpers.setValue(newGroupValue);
        return;
      }

      const val = getCustomInput(meta.value, CUSTOM_INPUT_IDX);
      if (val && val !== displayedText) {
        setDisplayedText(val);
      }
    }, [meta.value?.custom]);

    setClearInput(() => {
      helpers.setValue(DEFAULT_CHECKBOX_GROUP_VALUE);
      setDisplayedText("");
    });

    const addToGroupValue = (option: CheckboxInputOption) => {
      const newGroupValue = Object.assign(
        DEFAULT_CHECKBOX_GROUP_VALUE,
        meta.value
      );

      newGroupValue.checkedValues.push(option.value);

      helpers.setValue(newGroupValue);
    };

    const removeFromGroupValue = (option: CheckboxInputOption) => {
      const newGroupValue = Object.assign(
        DEFAULT_CHECKBOX_GROUP_VALUE,
        meta.value
      );

      newGroupValue.checkedValues = newGroupValue.checkedValues.filter(
        (v: string) => v !== option.value
      );

      helpers.setValue(newGroupValue);
    };

    const handleCustomInputChange = (newCustomInput: string, idx: number) => {
      const newGroupValue = Object.assign(
        DEFAULT_CHECKBOX_GROUP_VALUE,
        meta.value
      );

      if (newGroupValue.custom?.length > idx) {
        newGroupValue.custom[idx] = newCustomInput;
      } else {
        newGroupValue.custom = Array.from({ length: idx + 1 }, (_, i) => "");
        newGroupValue.custom[idx] = newCustomInput;
      }

      helpers.setValue(newGroupValue);
    };

    const hasReachedAllowedLimit = (): boolean => {
      if (typeof maxCheckedOptionsAllowed !== "number") {
        return false;
      }

      let numSelected = meta.value?.checkedValues?.length || 0;
      // numSelected += meta.value?.custom?.filter((v) => v || v !== "").length;

      if (numSelected >= maxCheckedOptionsAllowed) {
        return true;
      }

      return false;
    };

    if (!options || options.length === 0) {
      return <p>Sorry, an error occurred. Could not find nominees data.</p>;
    }

    return (
      <div
        ref={ref}
        role="group"
        aria-labelledby="checkbox-group"
        className={styles.CheckboxGroup}
      >
        {options.map((option: CheckboxInputOption) => {
          const { label, value, url, thumbnail_url } = option;

          return (
            <Checkbox
              key={value}
              label={label}
              value={value}
              url={url}
              thumbnail_url={thumbnail_url}
              checkboxColour={checkboxColour}
              checked={
                meta.value?.checkedValues &&
                meta.value.checkedValues.includes(value)
              }
              handleClick={handleClick}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  if (hasReachedAllowedLimit()) {
                    toast.error(
                      "You've selected the maximum number of allowed choices. Unselect some choices if you want to select others."
                    );
                    return;
                  }

                  addToGroupValue(option);
                } else {
                  removeFromGroupValue(option);
                }
              }}
            />
          );
        })}

        {numCustomInputs > 0 && (
          <div className={styles.CustomCheckboxContainer}>
            {/* <Checkbox
              key="custom-option"
              label={customInputPrompt ? customInputPrompt : "Other:"}
              value=""
              checkboxColour={checkboxColour}
              checked={
                meta.value?.custom?.length > CUSTOM_INPUT_IDX &&
                meta.value.custom[CUSTOM_INPUT_IDX] !== ""
              }
              handleClick={handleClick}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.checked) {
                  // if (hasReachedAllowedLimit()) {
                  //   toast.error(
                  //     "You've selected the maximum number of allowed choices. Unselect some choices if you want to select others."
                  //   );
                  //   return;
                  // }

                  handleCustomInputChange(
                    displayedText || " ",
                    CUSTOM_INPUT_IDX
                  );
                } else {
                  handleCustomInputChange("", CUSTOM_INPUT_IDX);
                }
              }}
              minimalStyle
            /> */}
            <div className={styles.CustomInputPrompt}>
              {customInputPrompt ? customInputPrompt : "Other:"}
            </div>

            <div className={styles.TextInputContainer}>
              <label className={styles.TextInputLabel}>
                <input
                  name={name}
                  type="text"
                  value={displayedText}
                  placeholder="Enter response…"
                  onClick={handleClick}
                  onChange={(e) => {
                    setDisplayedText(e.target.value);

                    // if (hasReachedAllowedLimit()) {
                    //   // TODO: debounce error
                    //   toast.error(
                    //     "You've selected the maximum number of allowed choices. Unselect some choices if you want to add your response."
                    //   );
                    //   return;
                    // }

                    handleCustomInputChange(e.target.value, CUSTOM_INPUT_IDX);
                  }}
                  className={styles.TextInput}
                  maxLength={maxLength}
                />
              </label>
              {displayedText && displayedText !== "" && (
                <span className={styles.ValidInput}>
                  <Checkmark colour={checkboxColour} />
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default CheckboxInputGroup;
