import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Prompt } from "react-router-dom";

import SEO from "components/seo";
import SimpleBtn from "components/button/SimpleBtn";
import LoadingIndicator from "components/loadingIndicator";
import Form, { FormValues } from "components/form";
import TicketsModal from "components/modals/TicketsModal";
import {
  getNominationCategories,
  getNominationsSubmission,
  submitNominations,
} from "api";
import { voteStartDate, nominationEndDate } from "common/dates";
import useExitPrompt from "hooks/useExitPrompt";
import { logInfo, logError } from "api";

import styles from "pages/nomination/Nomination.module.scss";
import globalStyles from "styles/components.module.scss";
import { CURRENT_AWARDS_YEAR } from "common";
import {
  Category,
  ClientConfig,
  PageProps,
  PageStage,
  UserNominationSubmission,
} from "types";

type SubmissionSuccessProps = {
  handleClick: () => void;
};

const SubmissionSuccess: React.FunctionComponent<SubmissionSuccessProps> = ({
  handleClick,
}) => {
  return (
    <React.Fragment>
      <TicketsModal openDelay={300} closeDelay={1500} />

      <div className={styles.Success}>
        <h3>Your nominations have been submitted!</h3>
        <p>
          Thank you for participating{" "}
          <img
            src="assets/widepeepoHappy.png"
            className={styles.WidepeepoHappyImg}
            alt="widepeepoHappy"
          />
        </p>
        <p>
          Come back on {voteStartDate} to vote for your winners from the
          finalized list of nominees.
        </p>
        <p>
          If you want to update some of your responses, you can just re-submit
          this form with your updates.
        </p>
      </div>

      <SimpleBtn handleClick={handleClick} align="center">
        ← Back to nominations
      </SimpleBtn>
    </React.Fragment>
  );
};

type NominationFormProps = {
  onSubmitSuccess: () => void;
  categories: Array<Category>;
};

const NominationForm: React.FunctionComponent<NominationFormProps> = ({
  categories,
  onSubmitSuccess,
}) => {
  const [shouldShowExitPrompt, setShouldShowExitPrompt] = useExitPrompt(false);

  //NOTE: this similar to componentWillUnmount()
  useEffect(() => {
    return () => {
      setShouldShowExitPrompt(false);
    };
  }, [setShouldShowExitPrompt]);

  const fetchInitialValues = async (): Promise<FormValues> => {
    const initialValues: FormValues = {};
    categories.forEach(({ id }) => {
      initialValues[id] = "";
    });

    let ret = await getNominationsSubmission(CURRENT_AWARDS_YEAR);
    if (ret.error != null || ret.resp == null) {
      return initialValues;
    }

    const { submissions } = ret.resp;
    if (!submissions) {
      return initialValues;
    }

    submissions.forEach(({ category_id, nomination }) => {
      initialValues[category_id] = nomination || "";
    });

    return initialValues;
  };

  const handleSubmit = async (values: FormValues) => {
    const submissions: UserNominationSubmission[] = [];
    for (const [cID, nomValue] of Object.entries(values)) {
      const nom = {
        category_id: Number(cID),
        nomination: String(nomValue).trim(),
      };

      submissions.push(nom);
    }

    let ret = await submitNominations(CURRENT_AWARDS_YEAR, submissions);
    if (ret.error != null) {
      logError("submit nominations error", `${JSON.stringify(ret.error)}`);
      toast.error("An error occurred when submitting your nominations.");
      return;
    }

    logInfo("nominations submission");

    setShouldShowExitPrompt(false);
    onSubmitSuccess();
  };

  return (
    <React.Fragment>
      <Prompt
        when={shouldShowExitPrompt}
        message={`You've entered some responses, but have not submitted them. Are you sure you want to leave?`}
      />
      <Form
        formType="nomination"
        categories={categories}
        fetchInitialValues={fetchInitialValues}
        onSubmitForm={handleSubmit}
        onFormTouched={() => setShouldShowExitPrompt(true)}
      />
    </React.Fragment>
  );
};

type NominationProps = {
  onSubmitSuccess?: () => void;
} & PageProps;

const Nomination: React.FunctionComponent<NominationProps> = ({
  config,
  onSubmitSuccess,
}) => {
  const [currStage, setCurrStage] = useState<PageStage>(PageStage.loading);
  const [categories, setCategories] = useState<Array<Category>>([]);

  const handleConfigUpdate = async (conf?: ClientConfig) => {
    if (conf?.is_nominations_open === false) {
      setCategories([]);
      return;
    }

    let ret = await getNominationCategories(CURRENT_AWARDS_YEAR);
    if (ret.error != null || ret.resp == null) {
      toast.error("An error occurred when fetching nomination categories.");
      return;
    }

    const { categories } = ret.resp;
    setCategories(categories || []);
  };

  useEffect(() => {
    handleConfigUpdate(config);
  }, [config]);

  useEffect(() => {
    async function fetchCategories() {
      await handleConfigUpdate(config);
      setCurrStage(PageStage.input);
    }

    fetchCategories();
  }, []);

  const renderForm = () => {
    return categories && categories.length > 0 ? (
      <NominationForm
        categories={categories}
        onSubmitSuccess={() => {
          onSubmitSuccess && onSubmitSuccess();
          setCurrStage(PageStage.complete);
          window.scrollTo(0, 0);
        }}
      />
    ) : (
      <div className={styles.FormError}>
        Sorry, an error occurred. No nomination categories found. Please check
        again later.
      </div>
    );
  };

  const renderPageContent = () => {
    if (currStage === PageStage.complete) {
      return (
        <SubmissionSuccess
          handleClick={() => {
            setCurrStage(PageStage.input);
          }}
        />
      );
    }

    return (
      <React.Fragment>
        <div className={styles.Description}>
          <h1>Nominate your Favorites</h1>
          <h3>Enter a nomination for each of the award categories below</h3>

          <div className={styles.Criteria}>
            <ul>
              <li>
                To be considered for any award category, nominated streamers
                must have streamed for at least <b>200 hours total</b> in 2022.
              </li>
              <li>
                To be considered for specific award categories (like Battle
                Royale, Art, Chess etc.), nominated streamers must have streamed
                for at least <b>100 hours in that category</b> in 2022.
              </li>
            </ul>
          </div>

          <p>
            Your submission will be used to determine the final nominees for
            each award.
          </p>
          <p>
            You can skip awards that you do not want to enter nominations for.
          </p>
          <p>You can also come back later and update your submission.</p>
          <p>Nominations will close on {nominationEndDate}.</p>
          <p className={styles.Disclaimer}>
            Disclaimer: Only relevant nominations for the award category will be
            considered.
          </p>
        </div>

        {currStage === PageStage.loading ? <LoadingIndicator /> : renderForm()}
      </React.Fragment>
    );
  };

  return (
    <div>
      <SEO
        title="Nominate"
        description="NOMINATE NOW! Enter your choices for the award categories of The Streamer Awards."
        keywords={["Nominate", "Streamer", "Award", "Submit"]}
      />

      {renderPageContent()}
    </div>
  );
};

export default Nomination;
