import React from "react";
import { useField } from "formik";

import Checkmark from "components/form/input/Checkmark";

import styles from "components/form/input/TextInput.module.scss";

const EMPTY_VALUE = "";

type TextInputProps = {
  label: string;
  onEnterKeyDown: () => void;
  handleClick: () => void;
  setClearInput: (f: () => void) => void;
  checkmarkColour?: string;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      onEnterKeyDown,
      handleClick,
      setClearInput,
      checkmarkColour,
      ...props
    },
    ref
  ) => {
    const [field, meta, helpers] = useField(props as any);

    setClearInput(() => {
      const { setValue } = helpers;
      setValue(EMPTY_VALUE);
    });

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (e.keyCode === 13 || e.code === "13" || e.key === "Enter") {
        onEnterKeyDown();
      }
    };

    return (
      <div className={styles.TextContainer}>
        <label htmlFor={props.id || props.name} />
        <input
          className={styles.Text}
          onKeyDown={handleKeyDown}
          onClick={handleClick}
          ref={ref}
          type="text"
          {...field}
          {...props}
        />
        {meta.value && meta.value !== "" && (
          <span className={styles.ValidInput}>
            <Checkmark colour={checkmarkColour} />
          </span>
        )}

        {props.children}
      </div>
    );
  }
);

export default TextInput;
