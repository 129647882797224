import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import SEO from "components/seo";
import LoadingIndicator from "components/loadingIndicator";
import WinnersList from "pages/winners/Winners";
import { getWinners } from "api";
import { useQuery } from "hooks/useQuery";
import { AWARDS_YEARS, CURRENT_AWARDS_YEAR } from "common";

import { CategoryWithNomineesAndWinner, PageProps, year } from "types";

import styles from "pages/winners/Winners.module.scss";
import globalStyles from "styles/components.module.scss";
import Select from "components/form/input/Select";

const QUERY_YEAR = "year";

type WinnersPageProps = {
  onSubmitSuccess?: () => void;
} & PageProps;

const WinnersPage: React.FunctionComponent<WinnersPageProps> = ({}) => {
  const { query, setQuery } = useQuery();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [year, setYear] = useState<year>(
    Number(query.get(QUERY_YEAR)) || CURRENT_AWARDS_YEAR
  );
  const [categories, setCategories] = useState<
    Array<CategoryWithNomineesAndWinner>
  >([]);

  const fetchWinners = async () => {
    const ret = await getWinners(year);

    setIsLoading(false);

    if (ret.error != null || ret.resp == null) {
      toast.error("An error occurred when fetching the award winners.");
      return;
    }

    const { categories } = ret.resp;
    setCategories(categories || []);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchWinners();
  }, [year]);

  const renderList = () => {
    return categories && categories.length > 0 ? (
      <WinnersList categories={categories} />
    ) : (
      <div className={styles.FormError}>
        Sorry, an error occurred. Award winners could not be retrieved. Please
        try again later.
      </div>
    );
  };

  const renderPageContent = () => {
    return (
      <React.Fragment>
        <h1>Winners</h1>
        <div className={styles.Description}>
          <p>
            Here are the winners for the Streamer Awards{" "}
            <Select
              selectedValue={String(year)}
              onChange={(val: string) => {
                const newYear = Number(val);

                setYear(newYear);

                const params = new URLSearchParams();
                params.append(QUERY_YEAR, String(newYear));
                setQuery(params);
              }}
              values={AWARDS_YEARS.map((y) => ({
                label: String(y),
                value: String(y),
              }))}
            />
          </p>
        </div>

        {isLoading ? <LoadingIndicator /> : renderList()}
      </React.Fragment>
    );
  };

  return (
    <div>
      <SEO
        title={`Winners ${year}`}
        description={
          "See the winners of all the different categories of The Streamer Awards."
        }
        keywords={["Awards", "Winner"]}
      />

      {renderPageContent()}
    </div>
  );
};

export default WinnersPage;
