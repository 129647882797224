import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Prompt } from "react-router-dom";

import SEO from "components/seo";
import SimpleBtn from "components/button/SimpleBtn";
import LoadingIndicator from "components/loadingIndicator";
import Form, { FormValues } from "components/form";
import {
  getCategoryNominationCategories,
  getCategoryNominationsSubmission,
  submitCategoryNominations,
} from "api";
import { categoryNominationEndDate, nominationStartDate } from "common/dates";
import useExitPrompt from "hooks/useExitPrompt";
import { logInfo, logError } from "api";

import {
  ClientConfig,
  PageProps,
  Category,
  UserCategorySubmission,
  InputType,
  PageStage,
  CategoryWithNominees,
} from "types";

import styles from "pages/category/CategoryNomination.module.scss";
import globalStyles from "styles/components.module.scss";
import { CURRENT_AWARDS_YEAR } from "common";
import { CheckboxInputGroupValue } from "components/form/input/CheckboxInputGroup";
import { removeDuplicates } from "utils";

type SubmissionSuccessProps = {
  handleClick: () => void;
};

const SubmissionSuccess: React.FunctionComponent<SubmissionSuccessProps> = ({
  handleClick,
}) => {
  return (
    <React.Fragment>
      <div className={styles.Success}>
        <h3>Your categories have been submitted!</h3>
        <p>
          Thank you for participating{" "}
          <img
            src="assets/widepeepoHappy.png"
            className={styles.WidepeepoHappyImg}
            alt="widepeepoHappy"
          />
        </p>

        <p>
          Come back on {nominationStartDate} to nominate your favorites for an
          award in the finalized list of categories.
        </p>
        <p>
          If you want to update some of your responses, you can just re-submit
          this form with your updates.
        </p>
      </div>

      <SimpleBtn handleClick={handleClick} align="center">
        ← Back to choose categories
      </SimpleBtn>
    </React.Fragment>
  );
};

type CategoryNominationFormProps = {
  onSubmitSuccess: () => void;
  categories: Array<CategoryWithNominees>;
};

const CategoryNominationForm: React.FunctionComponent<
  CategoryNominationFormProps
> = ({ categories, onSubmitSuccess }) => {
  const [shouldShowExitPrompt, setShouldShowExitPrompt] = useExitPrompt(false);

  //NOTE: this similar to componentWillUnmount()
  useEffect(() => {
    return () => {
      setShouldShowExitPrompt(false);
    };
  }, [setShouldShowExitPrompt]);

  const fetchInitialValues = async (): Promise<FormValues> => {
    const inputsMap: { [cId: number]: InputType } = {};
    const initialValues: FormValues = {};

    categories.forEach(({ id, details }) => {
      initialValues[id] = "";

      if (details?.input?.type) {
        inputsMap[id] = details.input.type;
      }
    });

    let ret = await getCategoryNominationsSubmission(CURRENT_AWARDS_YEAR);
    if (ret.error != null || ret.resp == null) {
      return initialValues;
    }

    const { submissions } = ret.resp;
    if (!submissions) {
      return initialValues;
    }

    submissions.forEach(({ category_id, nominee_ids, custom_responses }) => {
      const type = inputsMap[category_id];

      let val: string | CheckboxInputGroupValue = "";

      switch (type) {
        case InputType.CheckboxGroup:
        case InputType.CheckboxGroupCustom:
          const nomIds = removeDuplicates(nominee_ids || []);

          val = {
            checkedValues: nomIds.map((nId) => String(nId)),
            custom: custom_responses || [],
          };
          break;

        case InputType.Text:
        case InputType.Number:
        default:
          val =
            custom_responses && custom_responses.length > 0
              ? custom_responses[0]
              : "";
          break;
      }

      initialValues[category_id] = val;
    });

    return initialValues;
  };

  const handleSubmit = async (values: FormValues) => {
    const submissions: UserCategorySubmission[] = [];
    for (const [cId, val] of Object.entries(values)) {
      switch (typeof val) {
        case "string":
          submissions.push({
            category_id: Number(cId),
            custom_responses: [val.trim()],
          });
          break;

        case "number":
          submissions.push({
            category_id: Number(cId),
            custom_responses: [String(val)],
          });
          break;

        default:
          submissions.push({
            category_id: Number(cId),
            nominee_ids: val.checkedValues.map((v) => Number(v)),
            custom_responses: val.custom.map((v) => v.trim()),
          });
          break;
      }
    }

    const ret = await submitCategoryNominations(
      CURRENT_AWARDS_YEAR,
      submissions
    );
    if (ret.error != null) {
      logError("submit categories error", `${JSON.stringify(ret.error)}`);
      toast.error("An error occurred when submitting your categories.");
      return;
    }

    logInfo("categories submission");

    setShouldShowExitPrompt(false);
    onSubmitSuccess();
  };

  return (
    <React.Fragment>
      <Prompt
        when={shouldShowExitPrompt}
        message={`You've entered some responses, but have not submitted them. Are you sure you want to leave?`}
      />
      <Form
        categories={categories}
        fetchInitialValues={fetchInitialValues}
        onSubmitForm={handleSubmit}
        onFormTouched={() => setShouldShowExitPrompt(true)}
        checkmarkColour={"green"}
      />
    </React.Fragment>
  );
};

type CategoryNominationProps = {
  onSubmitSuccess?: () => void;
} & PageProps;

const CategoryNomination: React.FunctionComponent<CategoryNominationProps> = ({
  config,
  onSubmitSuccess,
}) => {
  const [currStage, setCurrStage] = useState<PageStage>(PageStage.loading);
  const [categories, setCategories] = useState<Array<CategoryWithNominees>>([]);

  const handleConfigUpdate = async (conf?: ClientConfig) => {
    if (conf?.is_category_noms_open === false) {
      setCategories([]);
      return;
    }

    let ret = await getCategoryNominationCategories(CURRENT_AWARDS_YEAR);
    if (ret.error != null || ret.resp == null) {
      toast.error("An error occurred when fetching categories.");
      return;
    }

    const { categories } = ret.resp;
    setCategories(categories || []);
  };

  useEffect(() => {
    handleConfigUpdate(config);
  }, [config]);

  useEffect(() => {
    async function fetchCategories() {
      await handleConfigUpdate(config);
      setCurrStage(PageStage.input);
    }

    fetchCategories();
  }, []);

  const renderForm = () => {
    return categories && categories.length > 0 ? (
      <CategoryNominationForm
        categories={categories}
        onSubmitSuccess={() => {
          onSubmitSuccess && onSubmitSuccess();
          setCurrStage(PageStage.complete);
          window.scrollTo(0, 0);
        }}
      />
    ) : (
      <div className={styles.FormError}>
        Sorry, an error occurred. No categories found. Please check again later.
      </div>
    );
  };

  const renderPageContent = () => {
    if (currStage === PageStage.complete) {
      return (
        <SubmissionSuccess
          handleClick={() => {
            setCurrStage(PageStage.input);
          }}
        />
      );
    }

    return (
      <React.Fragment>
        <div className={styles.Description}>
          <h1>Choose Award Categories</h1>
          <h3>Submit your suggestions for this year's award categories</h3>

          <p>
            Your submission will be used to determine some of the categories for
            The Streamer Awards 2023, alongside our returning award categories.
          </p>

          <p>You can come back later and update your submission.</p>
          <p>Choosing categories will close on {categoryNominationEndDate}.</p>
        </div>

        {currStage === PageStage.loading ? <LoadingIndicator /> : renderForm()}
      </React.Fragment>
    );
  };

  return (
    <div>
      <SEO
        title="Choose Categories"
        description={
          "Choose award categories now! Select the categories that you want to see at The Streamer Awards."
        }
        keywords={["Nominate", "Choose", "Award", "Category", "Vote", "Submit"]}
      />

      {renderPageContent()}
    </div>
  );
};

export default CategoryNomination;
