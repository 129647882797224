import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { HelmetProvider } from "react-helmet-async";

import UserProvider from "contexts/UserProvider";
import ScrollHandler from "components/nav/ScrollHandler";
import ScrollToTop from "components/nav/ScrollToTop";
import App from "App";
import { logInfo } from "api";

import "index.module.scss";
import reportWebVitals from "reportWebVitals";

const appComponent = () => {
  return (
    <React.StrictMode>
      <HelmetProvider>
        <Router
          getUserConfirmation={(message, callback) => {
            logInfo("user_redirect_confirmation");

            const allowTransition = window.confirm(message);
            callback(allowTransition);
          }}
        >
          <ScrollToTop />
          <ScrollHandler />

          <UserProvider>
            <App />
          </UserProvider>

          <Toaster
            toastOptions={{
              style: {
                background: "#363636",
                color: "#ffffff",
                fontSize: "14px",
                letterSpacing: "1.4px",
              },
            }}
          />
        </Router>
      </HelmetProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(appComponent(), document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
