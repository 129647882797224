import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Prompt } from "react-router-dom";

import SEO from "components/seo";
import SimpleBtn from "components/button/SimpleBtn";
import LoadingIndicator from "components/loadingIndicator";
import Form, { FormValues } from "components/form";
import TicketsModal from "components/modals/TicketsModal";

import { getVoteCategories, getVotesSubmission, submitVotes } from "api";
import { voteEndDate, awardShowDate } from "common/dates";
import useExitPrompt from "hooks/useExitPrompt";
import { logInfo, logError } from "api";

import styles from "pages/vote/Vote.module.scss";
import globalStyles from "styles/components.module.scss";
import { CURRENT_AWARDS_YEAR } from "common";
import {
  CategoryWithNominees,
  ClientConfig,
  PageProps,
  PageStage,
  UserVoteSubmission,
} from "types";

type SubmissionSuccessProps = {
  handleClick: () => void;
};

const SubmissionSuccess: React.FunctionComponent<SubmissionSuccessProps> = ({
  handleClick,
}) => {
  return (
    <React.Fragment>
      <TicketsModal openDelay={300} closeDelay={1500} />

      <div className={styles.Success}>
        <h3>Your votes have been submitted!</h3>
        <p>
          Thank you for participating{" "}
          <img
            src="assets/widepeepoHappy.png"
            className={styles.WidepeepoHappyImg}
            alt="widepeepoHappy"
          />
        </p>
        <p>
          Remember to tune in to the awards show on {awardShowDate} at{" "}
          <a
            href="https://www.twitch.tv/qtcinderella"
            target="_blank"
            rel="noreferrer"
            className={globalStyles.Link}
          >
            twitch.tv/qtcinderella
          </a>{" "}
          to watch the results live!
        </p>
        <p>
          If you want to update some of your responses, you can just re-submit
          this form with your updates.
        </p>
      </div>

      <SimpleBtn handleClick={handleClick} align="center">
        ← Back to voting
      </SimpleBtn>
    </React.Fragment>
  );
};

type VoteFormProps = {
  onSubmitSuccess: () => void;
  categories: Array<CategoryWithNominees>;
};

const VoteForm: React.FunctionComponent<VoteFormProps> = ({
  categories,
  onSubmitSuccess,
}) => {
  const [shouldShowExitPrompt, setShouldShowExitPrompt] = useExitPrompt(false);

  //NOTE: this similar to componentWillUnmount()
  useEffect(() => {
    return () => {
      setShouldShowExitPrompt(false);
    };
  }, [setShouldShowExitPrompt]);

  const fetchInitialValues = async (): Promise<FormValues> => {
    const initialValues: FormValues = {};

    categories.forEach(({ id }) => {
      initialValues[id] = "";
    });

    const ret = await getVotesSubmission(CURRENT_AWARDS_YEAR);
    if (ret.error != null || ret.resp == null) {
      return initialValues;
    }

    const { submissions } = ret.resp;
    if (submissions) {
      submissions.forEach(({ category_id, nominee_id }) => {
        initialValues[category_id] = String(nominee_id);
      });
    }

    return initialValues;
  };

  const handleSubmit = async (values: FormValues) => {
    const submissions: UserVoteSubmission[] = [];

    for (const [cID, nomID] of Object.entries(values)) {
      if (nomID === "") {
        continue;
      }

      const submission: UserVoteSubmission = {
        category_id: Number(cID),
        nominee_id: Number(nomID),
      };
      if (nomID == null) {
        submission.nominee_id = undefined;
      }

      submissions.push(submission);
    }

    const ret = await submitVotes(CURRENT_AWARDS_YEAR, submissions);
    if (ret.error != null) {
      logError("submit votes error", `${JSON.stringify(ret.error)}`);
      toast.error("An error occurred when submitting your votes.");
      return;
    }

    logInfo("votes submission");

    setShouldShowExitPrompt(false);
    onSubmitSuccess();
  };

  return (
    <React.Fragment>
      <Prompt
        when={shouldShowExitPrompt}
        message={`You've entered some responses, but have not submitted them. Are you sure you want to leave?`}
      />
      <Form
        formType="vote"
        categories={categories}
        fetchInitialValues={fetchInitialValues}
        onSubmitForm={handleSubmit}
        onFormTouched={() => setShouldShowExitPrompt(true)}
      />
    </React.Fragment>
  );
};

type VotePageProps = {
  onSubmitSuccess?: () => void;
} & PageProps;

const VotePage: React.FunctionComponent<VotePageProps> = ({
  config,
  onSubmitSuccess,
}) => {
  const [currStage, setCurrStage] = useState<PageStage>(PageStage.loading);
  const [categories, setCategories] = useState<Array<CategoryWithNominees>>([]);

  const handleConfigUpdate = async (conf?: ClientConfig) => {
    if (conf?.is_voting_open === false) {
      setCategories([]);
      return;
    }

    const ret = await getVoteCategories(CURRENT_AWARDS_YEAR);
    if (ret.error != null || ret.resp == null) {
      toast.error("An error occurred when fetching voting categories.");
      return;
    }

    const { categories } = ret.resp;
    setCategories(categories || []);
  };

  useEffect(() => {
    async function fetchCategories() {
      await handleConfigUpdate(config);

      if (currStage == PageStage.loading) {
        setCurrStage(PageStage.input);
      }
    }

    fetchCategories();
  }, [config]);

  const renderForm = () => {
    return categories && categories.length > 0 ? (
      <VoteForm
        categories={categories}
        onSubmitSuccess={() => {
          onSubmitSuccess && onSubmitSuccess();
          setCurrStage(PageStage.complete);
          window.scrollTo(0, 0);
        }}
      />
    ) : (
      <div className={styles.FormError}>
        Sorry, an error occurred. No voting categories found. Please check again
        later.
      </div>
    );
  };

  const renderPageContent = () => {
    if (currStage === PageStage.complete) {
      return (
        <SubmissionSuccess
          handleClick={() => {
            setCurrStage(PageStage.input);
          }}
        />
      );
    }

    return (
      <React.Fragment>
        <div className={styles.Description}>
          <h1>Vote for your Favorites</h1>
          <h3>
            Vote for your favorite nominee in each of the award categories below
          </h3>
          <p>
            Your submission will contribute towards deciding the final award
            winners.
          </p>
          <p>You can skip categories that you do not want to vote for.</p>
          <p>You can also come back later and update your submission.</p>
          <p>Voting will close on {voteEndDate}.</p>

          {/* <p className={styles.Disclaimer}>
            Note: The clips shown for the nominees are their most viewed clips
            from 2022 that are relevant to the award category.
          </p> */}
        </div>

        {currStage === PageStage.loading ? <LoadingIndicator /> : renderForm()}
      </React.Fragment>
    );
  };

  return (
    <div>
      <SEO
        title="Vote"
        description={
          "VOTE NOW! Select your choices for the different award categories of The Streamer Awards."
        }
        keywords={["Vote", "Submit"]}
      />

      {renderPageContent()}
    </div>
  );
};

export default VotePage;
