import { MutatingDots } from "react-loader-spinner";

import styles from "components/loadingIndicator/LoadingIndicator.module.scss";
import globalStyles from "styles/components.module.scss";

type LoadingIndicatorProps = {
  color?: string;
  secondaryColor?: string;
};

const LoadingIndicator: React.FunctionComponent<LoadingIndicatorProps> = ({
  color = globalStyles.primaryColor || "#866744",
  secondaryColor = "#fff",
}) => {
  return (
    <div className={styles.LoadingIndicator}>
      <MutatingDots
        ariaLabel="loading-indicator"
        color={color}
        secondaryColor={secondaryColor}
      />
    </div>
  );
};

export default LoadingIndicator;
