import React from "react";

import { DEFAULT_PARENT_DOMAINS } from "common";

import styles from "components/video/TwitchVideo.module.scss";

type TwitchVideoProps = {
  channel?: string;
  videoID?: string;
  time?: string | null;
  className?: string;
};

// https://dev.twitch.tv/docs/embed/everything
// https://github.com/talk2MeGooseman/react-twitch-embed-video
// https://philna.sh/blog/2020/03/23/responsive-twitch-embed/
const TwitchVideo: React.FC<TwitchVideoProps> = ({
  channel,
  videoID,
  time,
  className,
}) => {
  const domainName = window.location.hostname;

  const url = new URL("https://player.twitch.tv");

  const qParams = new URLSearchParams();
  qParams.append("allowfullscreen", String(true));
  qParams.append("preload", "auto");
  if (videoID != null) {
    qParams.append("video", `v${videoID}`);
  } else if (channel != null) {
    qParams.append("channel", channel);
  }
  qParams.append("autoplay", String(true));
  qParams.append("muted", String(false));
  qParams.append("parent", domainName);
  if (!domainName.startsWith("www.")) {
    qParams.append("parent", "www." + domainName);
  }
  DEFAULT_PARENT_DOMAINS.forEach((d) => {
    qParams.append("parent", d);
  });
  if (time != null) {
    qParams.append("time", String(time));
  }

  url.search = qParams.toString();
  const videoSrc = url.toString();

  return (
    <div className={`${styles.TwitchVideoEmbed} ${className ? className : ""}`}>
      <iframe
        src={videoSrc}
        title="Twitch stream"
        frameBorder="0"
        scrolling="no"
        allowFullScreen={true}
        allow="autoplay"
      />
    </div>
  );
};

export default React.memo(TwitchVideo);
