import { GACategory, GALabel } from "types";

type Action = string;

type Value = string;

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
// https://support.google.com/analytics/answer/1033068
export const trackEvent = (
  action: Action,
  category: GACategory = GACategory.General,
  label?: GALabel,
  nonInteractionEvent: boolean = false,
  value?: Value
): void => {
  const { gtag } = window;
  if (!gtag) {
    return;
  }

  gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value || 1,
    non_interaction: nonInteractionEvent,
  });
};

export const trackException = (error: string, fatal: boolean = false) => {
  const { gtag } = window;
  if (!gtag) {
    return;
  }

  gtag("event", "exception", {
    description: error,
    fatal: fatal,
  });
};
