import { ClientConfig, InputType, User } from "types";
import type { Category, CategoryWithNominees } from "types";
import type { GetUserCategorySubmissionResp } from "types/responses";

// s = ""
// c.forEach((x,i)=>{s += String(i+1)+". "+x.name +":\n";s+=x.details.nomination.description+"\n\n";})

export const user: User = {
  name: "Mock User",
};

export const clientConfig: ClientConfig = {
  is_category_noms_open: true,
  is_nominations_open: true,
  // is_voting_open: true,
  // is_predictions_open: true,
  show_merch: false,
  alert: "",
  // "alert": "The awards show will be live at &lt;a href=\"https://www.twitch.tv/qtcinderella\" target=\"_blank\" rel=\"noreferrer\" class=\"components_Link__PJjbH\"&gt;twitch.tv/qtcinderella &lt;/a&gt;"
  // alert:
  //   'Pick\'em results are available now. Check &lt;a href="/pickem" class="components_Link__qwNWz"&gt;here&lt;/a&gt; to see where you stand.',
};

export const catNomCategories: Array<CategoryWithNominees> = [
  {
    id: 1,
    name: "Select 10 award categories",
    details: {
      description:
        "Here are 30 of the most popular games streamed in 2022. " +
        "Please select up to 10 games that you think should be showcased as a category to celebrate streamers that play them. " +
        "The games with the most votes will have their own award category at this year's show.",
      input: {
        type: InputType.CheckboxGroupCustom,
        custom_input_prompt:
          "Have we missed a game that you think deserves to have a category? Enter it here:",
      },
    },
    nominees: [
      {
        nominee_id: 38,
        name: "ASMR",
        details: { thumbnail_url: "assets/2023/categories/ASMR.jpg" },
      },
      {
        nominee_id: 37,
        name: "Rocket League",
        details: { thumbnail_url: "assets/2023/categories/Rocket League.jpg" },
      },
      {
        nominee_id: 21,
        name: "Elden Ring",
        details: { thumbnail_url: "assets/2023/categories/Elden Ring.jpg" },
      },
      {
        nominee_id: 26,
        name: "Teamfight Tactics",
        details: {
          thumbnail_url: "assets/2023/categories/Teamfight Tactics.jpg",
        },
      },
      {
        nominee_id: 34,
        name: "Hearthstone",
        details: { thumbnail_url: "assets/2023/categories/Hearthstone.jpg" },
      },
      {
        nominee_id: 15,
        name: "Apex Legends",
        details: { thumbnail_url: "assets/2023/categories/Apex Legends.jpg" },
      },
      {
        nominee_id: 41,
        name: "Art",
        details: { thumbnail_url: "assets/2023/categories/Art.jpg" },
      },
      {
        nominee_id: 33,
        name: "Music",
        details: { thumbnail_url: "assets/2023/categories/Music.jpg" },
      },
      {
        nominee_id: 17,
        name: "Minecraft",
        details: { thumbnail_url: "assets/2023/categories/Minecraft.jpg" },
      },
      {
        nominee_id: 28,
        name: "Fall Guys",
        details: { thumbnail_url: "assets/2023/categories/Fall Guys.jpg" },
      },
      {
        nominee_id: 29,
        name: "Virtual Casino",
        details: { thumbnail_url: "assets/2023/categories/Virtual Casino.jpg" },
      },
      {
        nominee_id: 16,
        name: "Fortnite",
        details: { thumbnail_url: "assets/2023/categories/Fortnite.jpg" },
      },
      {
        nominee_id: 25,
        name: "FIFA (22, 23)",
        details: { thumbnail_url: "assets/2023/categories/FIFA (22, 23).jpg" },
      },
      {
        nominee_id: 32,
        name: "Dead by Daylight",
        details: {
          thumbnail_url: "assets/2023/categories/Dead by Daylight.jpg",
        },
      },
      {
        nominee_id: 36,
        name: "Genshin Impact",
        details: { thumbnail_url: "assets/2023/categories/Genshin Impact.jpg" },
      },
      {
        nominee_id: 23,
        name: "Slots",
        details: { thumbnail_url: "assets/2023/categories/Slots.jpg" },
      },
      {
        nominee_id: 12,
        name: "Grand Theft Auto V",
        details: {
          thumbnail_url: "assets/2023/categories/Grand Theft Auto V.jpg",
        },
      },
      {
        nominee_id: 22,
        name: "Overwatch 2",
        details: { thumbnail_url: "assets/2023/categories/Overwatch 2.jpg" },
      },
      {
        nominee_id: 39,
        name: "PUBG: BATTLEGROUNDS",
        details: {
          thumbnail_url: "assets/2023/categories/PUBG: BATTLEGROUNDS.jpg",
        },
      },
      {
        nominee_id: 24,
        name: "World of Warcraft",
        details: {
          thumbnail_url: "assets/2023/categories/World of Warcraft.jpg",
        },
      },
      {
        nominee_id: 11,
        name: "League of Legends",
        details: {
          thumbnail_url: "assets/2023/categories/League of Legends.jpg",
        },
      },
      {
        nominee_id: 27,
        name: "Sports",
        details: { thumbnail_url: "assets/2023/categories/Sports.jpg" },
      },
      {
        nominee_id: 30,
        name: "Escape from Tarkov",
        details: {
          thumbnail_url: "assets/2023/categories/Escape from Tarkov.jpg",
        },
      },
      {
        nominee_id: 31,
        name: "Rust",
        details: { thumbnail_url: "assets/2023/categories/Rust.jpg" },
      },
      {
        nominee_id: 40,
        name: "Tom Clancy's Rainbow Six Siege",
        details: {
          thumbnail_url:
            "assets/2023/categories/Tom Clancy's Rainbow Six Siege.jpg",
        },
      },
      {
        nominee_id: 10,
        name: "Just Chatting",
        details: { thumbnail_url: "assets/2023/categories/Just Chatting.jpg" },
      },
      {
        nominee_id: 18,
        name: "Dota 2",
        details: { thumbnail_url: "assets/2023/categories/Dota 2.jpg" },
      },
      {
        nominee_id: 35,
        name: "Special Events",
        details: { thumbnail_url: "assets/2023/categories/Special Events.jpg" },
      },
      {
        nominee_id: 19,
        name: "Call of Duty (Warzone, Modern Warfare II)",
        details: {
          thumbnail_url:
            "assets/2023/categories/Call of Duty (Warzone, Modern Warfare II).jpg",
        },
      },
      {
        nominee_id: 13,
        name: "VALORANT",
        details: { thumbnail_url: "assets/2023/categories/VALORANT.jpg" },
      },
      {
        nominee_id: 14,
        name: "Counter-Strike: Global Offensive",
        details: {
          thumbnail_url:
            "assets/2023/categories/Counter-Strike: Global Offensive.jpg",
        },
      },
      {
        nominee_id: 20,
        name: "Lost Ark",
        details: { thumbnail_url: "assets/2023/categories/Lost Ark.jpg" },
      },
    ],
  },
  {
    id: 2,
    name: "Suggest an award category",
    details: {
      description:
        "Any other award categories that you think should be added? Enter it below.",
      input: { type: InputType.Text },
    },
  },
];

export const placeholderCategories: Array<CategoryWithNominees> = [
  {
    id: 1,
    name: "Best Just Chatting Streamer",
    details: {
      description:
        "Streamer that regularly does Just Chatting content such as conversing with chat, reacting to news and videos, doing art and crafts, telling stories, hanging out with friends on stream and so on.",
      // vote: {
      //   description:
      //     "Vote lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      // },
    },
    nominees: [
      {
        nominee_id: 1,
        name: "This is the name of a nomination that has many characters",
        details: {
          thumbnail_url: "assets/downloads/nominees-local/ludwig.jpg",
          url: "https://youtube.com/ludwig",
        },
      },
      {
        nominee_id: 2,
        name: "xQc",
        details: {
          thumbnail_url: "assets/downloads/nominees-local/xqc.jpg",
          url: "https://twitch.tv/ludwig",
        },
      },
      {
        nominee_id: 3,
        name: "Mizkif",
        details: {
          thumbnail_url: "assets/downloads/nominees-local/mizkif.jpg",

          url: "https://twitch.tv/mizkif",
        },
      },
      {
        nominee_id: 4,
        name: "QTC",
        details: {
          thumbnail_url: "assets/downloads/nominees-local/qtcinderella.jpg",

          url: "twitch.tv/qtc",
        },
      },
    ],
  },
  {
    id: 2,
    name: "Best Role Play Streamer",
    details: {
      description:
        "Streamer that does role play (RP) content in games like Rust, GTA RP NoPixel, VRChat, New World, Ark, Dungeons & Dragons etc.",
    },
    nominees: [
      {
        nominee_id: 5,
        name: "Shitcamp",
        details: {
          thumbnail_url: "assets/downloads/nominees-local/shitcamp.jpg",

          url: "https://youtube.com",
        },
      },
      {
        nominee_id: 6,
        name: "Rivals",
        details: {
          thumbnail_url: "assets/downloads/nominees-local/rivals.jpg",

          url: "https://youtube.com",
        },
      },
      {
        nominee_id: 7,
        name: "Disneyland",
        details: {
          thumbnail_url: "assets/downloads/nominees-local/disneyland.jpg",

          url: "https://youtube.com",
        },
      },
      {
        nominee_id: 8,
        name: "bbno$",
        details: {
          thumbnail_url: "assets/downloads/nominees-local/bbno$.jpg",
          url: "https://youtube.com",
        },
      },
    ],
  },
  {
    id: 3,
    name: "Best League of Legends Streamer",
    details: {
      description: "Vote for the Best League of Legends Streamer.",
    },
    nominees: [
      {
        nominee_id: 9,
        name: "Fortnite",
        details: {
          thumbnail_url: "assets/downloads/nominees-local/valorant.jpg",

          url: "https://youtube.com",
        },
      },
      {
        nominee_id: 10,
        name: "Fall Guys",
        details: {
          thumbnail_url: "assets/downloads/nominees-local/apexlegends.png",

          url: "https://youtube.com",
        },
      },
      {
        nominee_id: 11,
        name: "GTA",
        details: {
          thumbnail_url: "assets/downloads/nominees-local/grandtheftautov.jpg",

          url: "https://youtube.com",
        },
      },
      {
        nominee_id: 12,
        name: "The Sims",
        details: {
          thumbnail_url: "assets/downloads/nominees-local/minecraft.jpg",

          url: "https://youtube.com",
        },
      },
    ],
  },
  {
    id: 4,
    name: "Best Battle Royale Streamer",
    details: {
      description:
        "Streamer that provides the best content while streaming battle royale games like Fortnite, Apex Legends, PUBG etc.",
    },
  },
  {
    id: 5,
    name: "Best Valorant Streamer",
    details: {
      description:
        "Streamer that provides the best content while streaming Valorant.",
    },
  },
  {
    id: 6,
    name: "Best Minecraft Streamer",
    details: {
      description: "Streamer that provides the best Minecraft content.",
    },
  },
  {
    id: 7,
    name: "Best FPS Streamer",
    details: {
      description:
        "Streamer that provides the best content playing FPS games like Overwatch, Halo, Call of Duty, Counter Strike and others.",
    },
  },
  {
    id: 8,
    name: "Best ASMR Streamer",
    details: {
      description: "Streamer that creates the best ASMR content.",
    },
  },
  {
    id: 9,
    name: "Best VTuber Streamer",
    details: {
      description:
        "Streamer that provides the best content while using a Virtual Avatar instead of a traditional face-cam.",
    },
  },
  {
    id: 10,
    name: "Best Smash Streamer",
    details: {
      description:
        "Streamer that provides the best content while playing Smash games like SSB Melee, SSB Ultimate, etc.",
    },
  },
  {
    id: 11,
    name: "Best Sports Streamer",
    details: {
      description:
        "Streamer that provides the best sports content including games like FIFA, NBA 2K, Rocket League, NHL, Madden NFL, TrackMania, Forza Horizon and so on.",
      // NBA, FIFA etc.
    },
  },
  {
    id: 12,
    name: "Best MMORPG Streamer",
    details: {
      description:
        "Streamer that provides the best content while playing MMORPG games like World of Warcraft, New World, Final Fantasy, Runescape and so on.",
    },
  },
  {
    id: 13,
    name: "Best Music Streamer",
    details: {
      description: "Streamer that creates the best music content.",
    },
  },
  {
    id: 14,
    name: "Best Speedrunner",
    details: {
      description:
        "Streamer that provides the best content while speedrunning games.",
    },
  },
  {
    id: 15,
    name: "Best Chess Streamer",
    details: {
      description: "Vote for the Best Chess Streamer.",
    },
  },
  {
    id: 16,
    name: "Best IRL Streamer",
    details: {
      description:
        "Streamer that creates the best In Real Life (IRL) content such as vlogging, travelling, cooking, visting places etc.",
    },
  },
  {
    id: 17,
    name: "Best Strategy Game Streamer",
    details: {
      description:
        "Streamer that creates the best content streaming strategy games like Teamfight Tactics (TFT), Starcraft, Hearthstone, Super Auto Pets (SAP) and the like.",
    },
  },
  {
    id: 18,
    name: "Best Political Streamer",
    details: {
      description: "Streamer that broadcasts the best political content.",
    },
  },
  {
    id: 19,
    name: "Best Philanthropist Streamer",
    details: {
      description:
        "Streamer that contributes to philanthropic causes by organizing charity streams, events and initiatives.",
    },
  },
  // {
  //   id: 20,
  //   name: "Best Comedic Streamer",
  //   details: {
  //     nomination: {
  //       description: "Streamer that has the best comedic content.",
  //     },
  //     vote: {
  //       description: "Vote for the Best Comedic Streamer.",
  //     },
  //   },
  // },
  {
    id: 20,
    name: "Best Variety Streamer",
    details: {
      description:
        "Streamer that broadcasts a variety of content of different types and in many categories.",
    },
  },
  {
    id: 21,
    name: "Best Streamed Event",
    details: {
      description: "The best collaborative event of the year.",
    },
  },
  {
    id: 22,
    name: "Stream Game of the Year",
    details: {
      description:
        "The best game to watch on stream because it was very entertaining, novel, fun, interesting, cool etc.",
    },
  },
  {
    id: 23,
    name: "Best Content Organization",
    details: {
      description: "eSports or content org that has created the best content.",
    },
  },
  {
    id: 24,
    name: "Gamer of the Year",
    details: {
      description:
        "Streamer that has God-tier gaming abilities and has excelled at games over the past year.",
    },
  },
  {
    id: 25,
    name: "League of their Own",
    details: {
      description:
        "This streamer is a pioneer that creates one-of-a-kind content. With their creativity and out-of-the-box thinking, they are a trailblazer in the streaming industry.",
    },
  },
  {
    id: 26,
    name: "Rising Star Award",
    details: {
      description:
        "Up-and-coming streamer that has grown tremendously over the past year and has the potential to be a top streamer.",
    },
  },
  {
    id: 27,
    name: "Legacy Award",
    details: {
      description:
        "A legend who has contributed a great deal and has shaped the streaming industry, i.e., a career achievement award.",
    },
  },
  {
    id: 28,
    name: "Streamer of the Year",
    details: {
      description: "The best overall streamer of the year.",
    },
  },
];

export const winnersByCategory = {
  categories: [
    {
      id: 1,
      name: "Best Battle Royale Streamer",
      details: {
        description:
          "Streamer that provides the best content while streaming battle royale games like Fortnite, Apex Legends, PUBG etc.",
        for_prediction_only: false,
        nomination: {
          description: "",
        },
        vote: {
          description: "",
        },
      },
      nominees: [
        {
          nominee_id: 1,
          name: "aceu",
          details: {
            thumbnail_url: "assets/nominees/aceu.jpg",
            url: "https://www.twitch.tv/aceu",
          },
        },
        {
          nominee_id: 2,
          name: "Nickmercs",
          details: {
            thumbnail_url: "assets/nominees/nickmercs.jpg",
            url: "https://www.twitch.tv/Nickmercs",
          },
        },
        {
          nominee_id: 3,
          name: "ImperialHal",
          details: {
            thumbnail_url: "assets/nominees/tsm_imperialhal.jpg",
            url: "https://www.twitch.tv/TSM_ImperialHal",
          },
        },
        {
          nominee_id: 4,
          name: "LuluLuvely",
          details: {
            thumbnail_url: "assets/nominees/lululuvely1.jpg",
            url: "https://twitch.tv/LuluLuvely",
          },
        },
      ],
      winner: {
        nominee_id: 1,
        name: "aceu",
        details: {
          thumbnail_url: "assets/nominees/aceu.jpg",
          url: "https://www.twitch.tv/aceu",
        },
      },
    },
    {
      id: 16,
      name: "Best MMORPG Streamer",
      details: {
        description:
          "Most entertaining streamer that plays MMORPG games like World of Warcraft, New World, Final Fantasy, Runescape and so on.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 61,
          name: "itswill",
          details: {
            thumbnail_url: "assets/nominees/itswill.jpg",
            url: "https://twitch.tv/itswill",
          },
        },
        {
          nominee_id: 62,
          name: "RichWCampbell",
          details: {
            thumbnail_url: "assets/nominees/richwcampbell.jpg",
            url: "https://twitch.tv/richwcampbell",
          },
        },
        {
          nominee_id: 63,
          name: "EsfandTV",
          details: {
            thumbnail_url: "assets/nominees/esfandtv.jpg",
            url: "https://twitch.tv/EsfandTV",
          },
        },
        {
          nominee_id: 64,
          name: "Asmongold",
          details: {
            thumbnail_url: "assets/nominees/asmongold.jpg",
            url: "https://twitch.tv/Asmongold",
          },
        },
      ],
      winner: {
        nominee_id: 64,
        name: "Asmongold",
        details: {
          thumbnail_url: "assets/nominees/asmongold.jpg",
          url: "https://twitch.tv/Asmongold",
        },
      },
    },
    {
      id: 2,
      name: "Best Role-Play Streamer",
      details: {
        description:
          "Streamer that does role-play (RP) content in games like Rust, VRChat, New World, Ark, Dungeons & Dragons etc.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 5,
          name: "CriticalRole - Dungeons and Dragons",
          details: {
            thumbnail_url: "assets/nominees/criticalrole.jpg",
            url: "https://www.twitch.tv/criticalrole",
          },
        },
        {
          nominee_id: 6,
          name: "Roflgator - VRChat",
          details: {
            thumbnail_url: "assets/nominees/roflgator.jpg",
            url: "https://www.twitch.tv/roflgator",
          },
        },
        {
          nominee_id: 7,
          name: "MurderCrumpet - VRChat",
          details: {
            thumbnail_url: "assets/nominees/murdercrumpet.jpg",
            url: "https://www.twitch.tv/murdercrumpet",
          },
        },
        {
          nominee_id: 8,
          name: "Cyr - Rust",
          details: {
            thumbnail_url: "assets/nominees/cyr.jpg",
            url: "https://twitch.tv/cyr",
          },
        },
      ],
      winner: {
        nominee_id: 8,
        name: "Cyr - Rust",
        details: {
          thumbnail_url: "assets/nominees/cyr.jpg",
          url: "https://twitch.tv/cyr",
        },
      },
    },
    {
      id: 4,
      name: "Best FPS Streamer",
      details: {
        description:
          "Streamer that creates the best content while playing first-person shooter (FPS) games like Overwatch, Halo, Call of Duty and Counter Strike, to name a few.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 13,
          name: "Shroud",
          details: {
            thumbnail_url: "assets/nominees/shroud.jpg",
            url: "https://twitch.tv/Shroud",
          },
        },
        {
          nominee_id: 14,
          name: "supertf",
          details: {
            thumbnail_url: "assets/nominees/supertf.jpeg",
            url: "https://twitch.tv/supertf",
          },
        },
        {
          nominee_id: 15,
          name: "Tarik",
          details: {
            thumbnail_url: "assets/nominees/tarik.jpg",
            url: "https://twitch.tv/Tarik",
          },
        },
        {
          nominee_id: 16,
          name: "TenZ",
          details: {
            thumbnail_url: "assets/nominees/tenz.jpg",
            url: "https://twitch.tv/Tenz",
          },
        },
      ],
      winner: {
        nominee_id: 15,
        name: "Tarik",
        details: {
          thumbnail_url: "assets/nominees/tarik.jpg",
          url: "https://twitch.tv/Tarik",
        },
      },
    },
    {
      id: 13,
      name: "Best Super Smash Bros. Streamer",
      details: {
        description:
          "Streamer that provides the best content while playing Smash games like SSB Melee, SSB Ultimate, SSB Brawl etc.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 49,
          name: "HungryBox",
          details: {
            thumbnail_url: "assets/nominees/hungrybox.jpg",
            url: "https://twitch.tv/HungryBox",
          },
        },
        {
          nominee_id: 50,
          name: "Mang0",
          details: {
            thumbnail_url: "assets/nominees/mang0.jpg",
            url: "https://twitch.tv/Mang0",
          },
        },
        {
          nominee_id: 51,
          name: "Leffen",
          details: {
            thumbnail_url: "assets/nominees/leffen.jpg",
            url: "https://twitch.tv/Leffen",
          },
        },
        {
          nominee_id: 52,
          name: "iBDW",
          details: {
            thumbnail_url: "assets/nominees/ibdw.jpg",
            url: "https://twitch.tv/iBDW",
          },
        },
      ],
      winner: {
        nominee_id: 50,
        name: "Mang0",
        details: {
          thumbnail_url: "assets/nominees/mang0.jpg",
          url: "https://twitch.tv/Mang0",
        },
      },
    },
    {
      id: 10,
      name: "Best Chess Streamer",
      details: {
        description: "Best Streamer to watch for Chess content.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 37,
          name: "GMHikaru",
          details: {
            thumbnail_url: "assets/nominees/gmhikaru.jpg",
            url: "https://twitch.tv/GMHikaru",
          },
        },
        {
          nominee_id: 38,
          name: "BotezLive",
          details: {
            thumbnail_url: "assets/nominees/botezlive.jpg",
            url: "https://twitch.tv/BotezLive",
          },
        },
        {
          nominee_id: 39,
          name: "GothamChess",
          details: {
            thumbnail_url: "assets/nominees/gothamchess.jpg",
            url: "https://twitch.tv/GothamChess",
          },
        },
        {
          nominee_id: 40,
          name: "akaNemsko",
          details: {
            thumbnail_url: "assets/nominees/akanemsko.jpg",
            url: "https://twitch.tv/akanemsko",
          },
        },
      ],
      winner: {
        nominee_id: 38,
        name: "BotezLive",
        details: {
          thumbnail_url: "assets/nominees/botezlive.jpg",
          url: "https://twitch.tv/BotezLive",
        },
      },
    },
    {
      id: 7,
      name: "Best Strategy Game Streamer",
      details: {
        description:
          "Streamer that creates the best content while playing strategy games like Dota, Teamfight Tactics (TFT), Starcraft, Hearthstone, Super Auto Pets (SAP) and the like.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 25,
          name: "Northernlion",
          details: {
            thumbnail_url: "assets/nominees/northernlion.jpg",
            url: "https://twitch.tv/northernlion",
          },
        },
        {
          nominee_id: 26,
          name: "DisguisedToast",
          details: {
            thumbnail_url: "assets/nominees/disguisedtoast.jpeg",
            url: "https://twitch.tv/disguisedtoast",
          },
        },
        {
          nominee_id: 27,
          name: "Scarra",
          details: {
            thumbnail_url: "assets/nominees/scarra.jpg",
            url: "https://twitch.tv/Scarra",
          },
        },
        {
          nominee_id: 28,
          name: "BoxBox",
          details: {
            thumbnail_url: "assets/nominees/boxbox.jpg",
            url: "https://twitch.tv/boxbox",
          },
        },
      ],
      winner: {
        nominee_id: 26,
        name: "DisguisedToast",
        details: {
          thumbnail_url: "assets/nominees/disguisedtoast.jpeg",
          url: "https://twitch.tv/disguisedtoast",
        },
      },
    },
    {
      id: 12,
      name: "Best Speedrunner",
      details: {
        description:
          "Streamer that provides the best content while speedrunning games.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 45,
          name: "Simply",
          details: {
            thumbnail_url: "assets/nominees/simply.jpg",
            url: "https://twitch.tv/Simply",
          },
        },
        {
          nominee_id: 46,
          name: "SmallAnt",
          details: {
            thumbnail_url: "assets/nominees/smallant.jpg",
            url: "https://twitch.tv/SmallAnt",
          },
        },
        {
          nominee_id: 47,
          name: "Atrioc",
          details: {
            thumbnail_url: "assets/nominees/atrioc.jpg",
            url: "https://twitch.tv/Atrioc",
          },
        },
        {
          nominee_id: 48,
          name: "Wirtual",
          details: {
            thumbnail_url: "assets/nominees/wirtual.jpg",
            url: "https://twitch.tv/Wirtual",
          },
        },
      ],
      winner: {
        nominee_id: 46,
        name: "SmallAnt",
        details: {
          thumbnail_url: "assets/nominees/smallant.jpg",
          url: "https://twitch.tv/SmallAnt",
        },
      },
    },
    {
      id: 8,
      name: "Best ASMR Streamer",
      details: {
        description: "Streamer that provides the best ASMR content.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 29,
          name: "Amouranth",
          details: {
            thumbnail_url: "assets/nominees/amouranth.jpg",
            url: "https://twitch.tv/Amouranth",
          },
        },
        {
          nominee_id: 30,
          name: "FoxenKin",
          details: {
            thumbnail_url: "assets/nominees/foxenkin.jfif",
            url: "https://twitch.tv/FoxenKin",
          },
        },
        {
          nominee_id: 31,
          name: "Katrine",
          details: {
            thumbnail_url: "assets/nominees/katrine.jpg",
            url: "https://twitch.tv/Katrine",
          },
        },
        {
          nominee_id: 32,
          name: "SkepticalPickle",
          details: {
            thumbnail_url: "assets/nominees/skepticalpickle.jpg",
            url: "https://twitch.tv/SkepticalPickle",
          },
        },
      ],
      winner: {
        nominee_id: 29,
        name: "Amouranth",
        details: {
          thumbnail_url: "assets/nominees/amouranth.jpg",
          url: "https://twitch.tv/Amouranth",
        },
      },
    },
    {
      id: 6,
      name: "Best VTuber Streamer",
      details: {
        description:
          "Streamer who uses a Virtual Avatar instead of a traditional face-cam, and provides the most entertaining content.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 21,
          name: "Veibae",
          details: {
            thumbnail_url: "assets/nominees/veibae.jpg",
            url: "https://twitch.tv/Veibae",
          },
        },
        {
          nominee_id: 22,
          name: "Ironmouse",
          details: {
            thumbnail_url: "assets/nominees/ironmouse.jpg",
            url: "https://twitch.tv/Ironmouse",
          },
        },
        {
          nominee_id: 23,
          name: "Nyanners",
          details: {
            thumbnail_url: "assets/nominees/nyanners.jpg",
            url: "https://twitch.tv/Nyanners",
          },
        },
        {
          nominee_id: 24,
          name: "CodeMiko",
          details: {
            thumbnail_url: "assets/nominees/codemiko.jpg",
            url: "https://twitch.tv/CodeMiko",
          },
        },
      ],
      winner: {
        nominee_id: 24,
        name: "CodeMiko",
        details: {
          thumbnail_url: "assets/nominees/codemiko.jpg",
          url: "https://twitch.tv/CodeMiko",
        },
      },
    },
    {
      id: 9,
      name: "Best Music Streamer",
      details: {
        description: "Streamer that creates the best music content.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 33,
          name: "ARIatHOME",
          details: {
            thumbnail_url: "assets/nominees/ariathome.jpg",
            url: "https://twitch.tv/ARIatHOME",
          },
        },
        {
          nominee_id: 34,
          name: "SethDrums",
          details: {
            thumbnail_url: "assets/nominees/sethdrums.jpg",
            url: "https://twitch.tv/SethDrums",
          },
        },
        {
          nominee_id: 35,
          name: "T-Pain",
          details: {
            thumbnail_url: "assets/nominees/tpain.jpg",
            url: "https://twitch.tv/tpain",
          },
        },
        {
          nominee_id: 36,
          name: "LilyPichu",
          details: {
            thumbnail_url: "assets/nominees/lilypichu.jpg",
            url: "https://twitch.tv/lilypichu",
          },
        },
      ],
      winner: {
        nominee_id: 36,
        name: "LilyPichu",
        details: {
          thumbnail_url: "assets/nominees/lilypichu.jpg",
          url: "https://twitch.tv/lilypichu",
        },
      },
    },
    {
      id: 11,
      name: "Best IRL Streamer",
      details: {
        description:
          "Streamer that creates the best In Real Life (IRL) content such as vlogging, travelling, cooking, visting places etc.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 41,
          name: "HAchubby",
          details: {
            thumbnail_url: "assets/nominees/hachubby.jpg",
            url: "https://twitch.tv/hachubby",
          },
        },
        {
          nominee_id: 42,
          name: "Robcdee",
          details: {
            thumbnail_url: "assets/nominees/robcdee.jpg",
            url: "https://twitch.tv/robcdee",
          },
        },
        {
          nominee_id: 43,
          name: "jakenbakeLIVE",
          details: {
            thumbnail_url: "assets/nominees/jakenbakelive.jpg",
            url: "https://twitch.tv/jakenbakeLIVE",
          },
        },
        {
          nominee_id: 44,
          name: "Jinnytty",
          details: {
            thumbnail_url: "assets/nominees/jinnytty.jpg",
            url: "https://twitch.tv/Jinnytty",
          },
        },
      ],
      winner: {
        nominee_id: 41,
        name: "HAchubby",
        details: {
          thumbnail_url: "assets/nominees/hachubby.jpg",
          url: "https://twitch.tv/hachubby",
        },
      },
    },
    {
      id: 3,
      name: "Best League of Legends Streamer",
      details: {
        description:
          "Streamer that provides the best content while streaming League of Legends.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 9,
          name: "loltyler1",
          details: {
            thumbnail_url: "assets/nominees/loltyler1.jpg",
            url: "https://twitch.tv/loltyler1",
          },
        },
        {
          nominee_id: 10,
          name: "Emiru",
          details: {
            thumbnail_url: "assets/nominees/emiru.jpg",
            url: "https://twitch.tv/Emiru",
          },
        },
        {
          nominee_id: 11,
          name: "Doublelift",
          details: {
            thumbnail_url: "assets/nominees/doublelift.jpg",
            url: "https://twitch.tv/Doublelift",
          },
        },
        {
          nominee_id: 12,
          name: "LilyPichu",
          details: {
            thumbnail_url: "assets/nominees/lilypichu.jpg",
            url: "https://twitch.tv/LilyPichu",
          },
        },
      ],
      winner: {
        nominee_id: 9,
        name: "loltyler1",
        details: {
          thumbnail_url: "assets/nominees/loltyler1.jpg",
          url: "https://twitch.tv/loltyler1",
        },
      },
    },
    {
      id: 14,
      name: "Best Minecraft Streamer",
      details: {
        description: "Most enjoyable streamer to watch for Minecraft content.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 53,
          name: "TommyInnit",
          details: {
            thumbnail_url: "assets/nominees/tommyinnit.jpg",
            url: "https://twitch.tv/TommyInnit",
          },
        },
        {
          nominee_id: 54,
          name: "Forsen",
          details: {
            thumbnail_url: "assets/nominees/forsen.jpg",
            url: "https://twitch.tv/Forsen",
          },
        },
        {
          nominee_id: 55,
          name: "RanbooLive",
          details: {
            thumbnail_url: "assets/nominees/ranboolive.jpg",
            url: "https://twitch.tv/RanbooLive",
          },
        },
        {
          nominee_id: 56,
          name: "Tubbo",
          details: {
            thumbnail_url: "assets/nominees/tubbo.jpg",
            url: "https://twitch.tv/Tubbo",
          },
        },
      ],
      winner: {
        nominee_id: 53,
        name: "TommyInnit",
        details: {
          thumbnail_url: "assets/nominees/tommyinnit.jpg",
          url: "https://twitch.tv/TommyInnit",
        },
      },
    },
    {
      id: 28,
      name: "Best Valorant Streamer",
      details: {
        description: "Streamer that provides the best Valorant content.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 109,
          name: "iiTzTimmy",
          details: {
            thumbnail_url: "assets/nominees/iitztimmy.jpg",
            url: "https://www.twitch.tv/iiTzTimmy",
          },
        },
        {
          nominee_id: 110,
          name: "Punz",
          details: {
            thumbnail_url: "assets/nominees/punz.jpg",
            url: "https://twitch.tv/Punz",
          },
        },
        {
          nominee_id: 111,
          name: "Pokimane",
          details: {
            thumbnail_url: "assets/nominees/pokimane.jpg",
            url: "https://twitch.tv/pokimane",
          },
        },
        {
          nominee_id: 112,
          name: "QuarterJade",
          details: {
            thumbnail_url: "assets/nominees/quarterjade.jpg",
            url: "https://twitch.tv/quarterjade",
          },
        },
      ],
      winner: {
        nominee_id: 109,
        name: "iiTzTimmy",
        details: {
          thumbnail_url: "assets/nominees/iitztimmy.jpg",
          url: "https://www.twitch.tv/iiTzTimmy",
        },
      },
    },
    {
      id: 29,
      name: "Best GTA RP Streamer",
      details: {
        description:
          "Streamer that provides the best Grand Theft Auto role-play (GTA RP) content.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 113,
          name: "xQcOW",
          details: {
            thumbnail_url: "assets/nominees/xqcow.jpg",
            url: "https://twitch.tv/xQcOW",
          },
        },
        {
          nominee_id: 114,
          name: "Buddha",
          details: {
            thumbnail_url: "assets/nominees/buddha.png",
            url: "https://twitch.tv/Buddha",
          },
        },
        {
          nominee_id: 115,
          name: "Sykkuno",
          details: {
            thumbnail_url: "assets/nominees/sykkuno.jpg",
            url: "https://twitch.tv/Sykkuno",
          },
        },
        {
          nominee_id: 116,
          name: "Fuslie",
          details: {
            thumbnail_url: "assets/nominees/fuslie.jpg",
            url: "https://twitch.tv/Fuslie",
          },
        },
      ],
      winner: {
        nominee_id: 114,
        name: "Buddha",
        details: {
          thumbnail_url: "assets/nominees/buddha.png",
          url: "https://twitch.tv/Buddha",
        },
      },
    },
    {
      id: 18,
      name: "Best Philanthropic Streamer",
      details: {
        description:
          "Streamer that contributes to philanthropic causes by hosting charity streams, events, spreading awareness and other such initiatives.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 69,
          name: "Jacksepticeye",
          details: {
            thumbnail_url: "assets/nominees/jacksepticeye.jpg",
            url: "https://twitch.tv/Jacksepticeye",
          },
        },
        {
          nominee_id: 70,
          name: "HealthyGamer_GG",
          details: {
            thumbnail_url: "assets/nominees/healthygamergg1.jpg",
            url: "https://twitch.tv/HealthyGamer_GG",
          },
        },
        {
          nominee_id: 71,
          name: "DrLupo",
          details: {
            thumbnail_url: "assets/nominees/drlupo.jpeg",
            url: "https://www.youtube.com/DrLupo",
          },
        },
        {
          nominee_id: 72,
          name: "RanbooLive",
          details: {
            thumbnail_url: "assets/nominees/ranboolive.jpg",
            url: "https://twitch.tv/RanbooLive",
          },
        },
      ],
      winner: {
        nominee_id: 69,
        name: "Jacksepticeye",
        details: {
          thumbnail_url: "assets/nominees/jacksepticeye.jpg",
          url: "https://twitch.tv/Jacksepticeye",
        },
      },
    },
    {
      id: 21,
      name: "Stream Game of the Year",
      details: {
        description:
          "The best game to watch on stream during the past year, because it was very entertaining, novel, fun, interesting, cool etc.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 81,
          name: "GTA V",
          details: {
            thumbnail_url: "assets/nominees/gtav.jpg",
          },
        },
        {
          nominee_id: 82,
          name: "Valorant",
          details: {
            thumbnail_url: "assets/nominees/valorant.jpg",
          },
        },
        {
          nominee_id: 83,
          name: "Minecraft",
          details: {
            thumbnail_url: "assets/nominees/minecraft.jpg",
          },
        },
        {
          nominee_id: 84,
          name: "Apex Legends",
          details: {
            thumbnail_url: "assets/nominees/apexlegends.jpg",
          },
        },
      ],
      winner: {
        nominee_id: 83,
        name: "Minecraft",
        details: {
          thumbnail_url: "assets/nominees/minecraft.jpg",
        },
      },
    },
    {
      id: 19,
      name: "Best Variety Streamer",
      details: {
        description:
          "Best streamer that broadcasts a variety of content-  of different types and in many categories.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 73,
          name: "Moistcr1tikal",
          details: {
            thumbnail_url: "assets/nominees/moistcr1tikal.jpg",
            url: "https://twitch.tv/moistcr1tikal",
          },
        },
        {
          nominee_id: 74,
          name: "Ludwig",
          details: {
            thumbnail_url: "assets/nominees/ludwig.jpg",
            url: "https://www.youtube.com/c/Ludwigahgren",
          },
        },
        {
          nominee_id: 75,
          name: "Valkyrae",
          details: {
            thumbnail_url: "assets/nominees/valkyrae.jpg",
            url: "https://www.youtube.com/c/Valkyrae1",
          },
        },
        {
          nominee_id: 76,
          name: "DisguisedToast",
          details: {
            thumbnail_url: "assets/nominees/disguisedtoast.jpeg",
            url: "https://twitch.tv/disguisedtoast",
          },
        },
      ],
      winner: {
        nominee_id: 73,
        name: "Moistcr1tikal",
        details: {
          thumbnail_url: "assets/nominees/moistcr1tikal.jpg",
          url: "https://twitch.tv/moistcr1tikal",
        },
      },
    },
    {
      id: 5,
      name: "Best Just Chatting Streamer",
      details: {
        description:
          "Streamer that regularly does Just Chatting content such as conversing with chat, reacting to news and videos, arts and crafts, telling stories, hanging out with friends on stream and the like.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 17,
          name: "Mizkif",
          details: {
            thumbnail_url: "assets/nominees/mizkif.jpg",
            url: "https://www.twitch.tv/mizkif",
          },
        },
        {
          nominee_id: 18,
          name: "HasanAbi",
          details: {
            thumbnail_url: "assets/nominees/hasanabi.jpg",
            url: "https://www.twitch.tv/hasanabi",
          },
        },
        {
          nominee_id: 19,
          name: "PaymoneyWubby",
          details: {
            thumbnail_url: "assets/nominees/paymoneywubby.jpg",
            url: "https://www.twitch.tv/PaymoneyWubby",
          },
        },
        {
          nominee_id: 20,
          name: "BruceDropEmOff",
          details: {
            thumbnail_url: "assets/nominees/brucedropemoff.jpg",
            url: "https://www.twitch.tv/brucedropemoff",
          },
        },
      ],
      winner: {
        nominee_id: 17,
        name: "Mizkif",
        details: {
          thumbnail_url: "assets/nominees/mizkif.jpg",
          url: "https://www.twitch.tv/mizkif",
        },
      },
    },
    {
      id: 20,
      name: "Best Streamed Event",
      details: {
        description:
          "The most entertaining, interesting or enjoyable collaborative event streamed during the past year.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 77,
          name: "Balloon World Cup - Ibai",
          details: {
            thumbnail_url: "assets/nominees/baloonworldcup.jpeg",
            url: "https://www.youtube.com/playlist?list=PLHCPwKwATUgA0y1ckLH_tai4RX9h9oI74",
          },
        },
        {
          nominee_id: 78,
          name: "The Jerma985 Dollhouse - Jerma985",
          details: {
            thumbnail_url: "assets/nominees/thejerma985dollhouse.jpg",
            url: "https://www.youtube.com/watch?v=mMz6PWrvShU&list=PLkJjP9Lr7Bn725FUtmwvhFInGkrkenPsk",
          },
        },
        {
          nominee_id: 79,
          name: "The Price is Scuffed - PaymoneyWubby",
          details: {
            thumbnail_url: "assets/nominees/thepriceisscuffed.jpg",
            url: "https://www.youtube.com/watch?v=r9KYpyxnLyI",
          },
        },
        {
          nominee_id: 80,
          name: "Shit Con - Nmplol",
          details: {
            thumbnail_url: "assets/nominees/shitcon.jpg",
            url: "https://www.youtube.com/watch?v=czkvjUdt5ew",
          },
        },
      ],
      winner: {
        nominee_id: 78,
        name: "The Jerma985 Dollhouse - Jerma985",
        details: {
          thumbnail_url: "assets/nominees/thejerma985dollhouse.jpg",
          url: "https://www.youtube.com/watch?v=mMz6PWrvShU&list=PLkJjP9Lr7Bn725FUtmwvhFInGkrkenPsk",
        },
      },
    },
    {
      id: 22,
      name: "Best Content Organization",
      details: {
        description:
          "Esports or content organization that has created the best content during the past year.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 85,
          name: "100 Thieves",
          details: {
            thumbnail_url: "assets/nominees/100thieves.jpg",
            url: "https://100thieves.com/",
          },
        },
        {
          nominee_id: 86,
          name: "OTK",
          details: {
            thumbnail_url: "assets/nominees/otk.jpg",
            url: "https://otknetwork.com/",
          },
        },
        {
          nominee_id: 87,
          name: "OfflineTV",
          details: {
            thumbnail_url: "assets/nominees/offlinetv.jpg",
            url: "https://offlinetv.com/",
          },
        },
        {
          nominee_id: 88,
          name: "NRG",
          details: {
            thumbnail_url: "assets/nominees/nrg.jpg",
            url: "https://www.nrg.gg/",
          },
        },
      ],
      winner: {
        nominee_id: 86,
        name: "OTK",
        details: {
          thumbnail_url: "assets/nominees/otk.jpg",
          url: "https://otknetwork.com/",
        },
      },
    },
    {
      id: 24,
      name: "Rising Star Award",
      details: {
        description:
          "Up-and-coming streamer that has had tremendous growth over the past year and has the potential to be a top streamer.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 93,
          name: "Stanz",
          details: {
            thumbnail_url: "assets/nominees/stanz.jpg",
            url: "https://www.twitch.tv/stanz",
          },
        },
        {
          nominee_id: 94,
          name: "Frogan",
          details: {
            thumbnail_url: "assets/nominees/frogan.jfif",
            url: "https://www.twitch.tv/frogan",
          },
        },
        {
          nominee_id: 95,
          name: "Zoil",
          details: {
            thumbnail_url: "assets/nominees/zoil.jpg",
            url: "https://www.twitch.tv/zoil",
          },
        },
        {
          nominee_id: 96,
          name: "Purplecliffe",
          details: {
            thumbnail_url: "assets/nominees/purplecliffe.jpg",
            url: "https://www.twitch.tv/purplecliffe",
          },
        },
      ],
      winner: {
        nominee_id: 93,
        name: "Stanz",
        details: {
          thumbnail_url: "assets/nominees/stanz.jpg",
          url: "https://www.twitch.tv/stanz",
        },
      },
    },
    {
      id: 23,
      name: "League of their Own",
      details: {
        description:
          "This streamer is a pioneer that creates one-of-a-kind content. With their creativity and out-of-the-box thinking, they are a trailblazer in the streaming industry. Someone who relies on themselves for their content ideas and is genuinely in a league of their own.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 89,
          name: "Jerma985",
          details: {
            thumbnail_url: "assets/nominees/jerma985.jpg",
            url: "https://www.twitch.tv/jerma985",
          },
        },
        {
          nominee_id: 90,
          name: "Kitboga",
          details: {
            thumbnail_url: "assets/nominees/kitboga.jpg",
            url: "https://www.twitch.tv/kitboga",
          },
        },
        {
          nominee_id: 91,
          name: "TheSushiDragon",
          details: {
            thumbnail_url: "assets/nominees/thesushidragon.jpg",
            url: "https://www.twitch.tv/thesushidragon",
          },
        },
        {
          nominee_id: 92,
          name: "Ibai",
          details: {
            thumbnail_url: "assets/nominees/ibai.jfif",
            url: "https://www.twitch.tv/ibai",
          },
        },
      ],
      winner: {
        nominee_id: 89,
        name: "Jerma985",
        details: {
          thumbnail_url: "assets/nominees/jerma985.jpg",
          url: "https://www.twitch.tv/jerma985",
        },
      },
    },
    {
      id: 25,
      name: "Legacy Award",
      details: {
        description:
          "A legend who has contributed a great deal to and has shaped the streaming industry, i.e., a career achievement award.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 97,
          name: "Summit1g",
          details: {
            thumbnail_url: "assets/nominees/summit1g.jpg",
            url: "https://twitch.tv/Summit1g",
          },
        },
        {
          nominee_id: 98,
          name: "Pokimane",
          details: {
            thumbnail_url: "assets/nominees/pokimane.jpg",
            url: "https://twitch.tv/Pokimane",
          },
        },
        {
          nominee_id: 99,
          name: "Sodapoppin",
          details: {
            thumbnail_url: "assets/nominees/sodapoppin.jpg",
            url: "https://twitch.tv/Sodapoppin",
          },
        },
        {
          nominee_id: 100,
          name: "Scarra",
          details: {
            thumbnail_url: "assets/nominees/scarra.jpg",
            url: "https://twitch.tv/Scarra",
          },
        },
      ],
      winner: {
        nominee_id: 98,
        name: "Pokimane",
        details: {
          thumbnail_url: "assets/nominees/pokimane.jpg",
          url: "https://twitch.tv/Pokimane",
        },
      },
    },
    {
      id: 26,
      name: "Gamer of the Year",
      details: {
        description:
          "Streamer that has extraordinary gaming abilities and has excelled at games over the past year. No matter what game they're playing, you just have to watch.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 101,
          name: "Shroud",
          details: {
            thumbnail_url: "assets/nominees/shroud.jpg",
            url: "https://www.twitch.tv/Shroud",
          },
        },
        {
          nominee_id: 102,
          name: "iiTzTimmy",
          details: {
            thumbnail_url: "assets/nominees/iitztimmy.jpg",
            url: "https://www.twitch.tv/iiTzTimmy",
          },
        },
        {
          nominee_id: 103,
          name: "aceu",
          details: {
            thumbnail_url: "assets/nominees/aceu.jpg",
            url: "https://www.twitch.tv/aceu",
          },
        },
        {
          nominee_id: 104,
          name: "TenZ",
          details: {
            thumbnail_url: "assets/nominees/tenz.jpg",
            url: "https://www.twitch.tv/tenz",
          },
        },
      ],
      winner: {
        nominee_id: 101,
        name: "Shroud",
        details: {
          thumbnail_url: "assets/nominees/shroud.jpg",
          url: "https://www.twitch.tv/Shroud",
        },
      },
    },
    {
      id: 27,
      name: "Streamer of the Year",
      details: {
        description:
          "The best overall streamer for the past year. They saw great success with their entertaining content, elevated their brand, and in general, left their mark on viewers. This. was. their. year.",
        for_prediction_only: false,
      },
      nominees: [
        {
          nominee_id: 105,
          name: "Mizkif",
          details: {
            thumbnail_url: "assets/nominees/mizkif.jpg",
            url: "https://www.twitch.tv/mizkif",
          },
        },
        {
          nominee_id: 106,
          name: "Ludwig",
          details: {
            thumbnail_url: "assets/nominees/ludwig.jpg",
            url: "https://www.youtube.com/c/Ludwigahgren",
          },
        },
        {
          nominee_id: 107,
          name: "xQcOW",
          details: {
            thumbnail_url: "assets/nominees/xqcow.jpg",
            url: "https://www.twitch.tv/xqcow",
          },
        },
        {
          nominee_id: 108,
          name: "Sykkuno",
          details: {
            thumbnail_url: "assets/nominees/sykkuno.jpg",
            url: "https://www.twitch.tv/sykkuno",
          },
        },
      ],
      winner: {
        nominee_id: 106,
        name: "Ludwig",
        details: {
          thumbnail_url: "assets/nominees/ludwig.jpg",
          url: "https://www.youtube.com/c/Ludwigahgren",
        },
      },
    },
  ],
};

export const catNomsSubmission: GetUserCategorySubmissionResp = {
  submissions: [
    { category_id: 2, nominee_ids: [15], custom_responses: ["mock resp"] },
    { category_id: 3, nominee_ids: [16] },
    {
      category_id: 1,
      nominee_ids: [10, 13, 14, 15, 28, 29, 41],
      custom_responses: ["submitted text val", "val 2"],
    },
  ],
};

export const votesSubmission = {
  submissions: [
    { category_id: 1, nominee_id: 3 },
    { category_id: 2, nominee_id: 6 },
  ],
};
