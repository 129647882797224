import { Formik, Form as FormikForm, FormikHelpers } from "formik";
import React, { ReactNode, useState } from "react";
import toast from "react-hot-toast";
import { TailSpin } from "react-loader-spinner";

import SimpleBtn from "components/button/SimpleBtn";
import Auth from "components/auth/Auth";
import TextInput from "components/form/input/TextInput";
import RadioInputGroup from "components/form/input/RadioInputGroup";
import CheckboxInputGroup, {
  CheckboxInputOption,
  CheckboxInputGroupValue,
} from "components/form/input/CheckboxInputGroup";
import FancyBtn from "components/button/FancyBtn";
import ErrorBoundary from "components/error/ErrorBoundary";
import { logError, logInfo } from "api";

import { Nominee, CategoryWithNominees, InputType, InputDetails } from "types";

import styles from "components/form/Form.module.scss";

// https://medium.com/@nphivu414/build-a-multi-step-form-with-react-hooks-formik-yup-and-materialui-fa4f73545598

enum status {
  notInit = "not_init",
  pending = "pending_login",
  loggedIn = "logged_in",
}

const MAX_TEXT_INPUT_LEN = 150;

const MAX_NUMBER_INPUT_LEN = 20;
const MAX_NUMBER_INPUT_VALUE = 100000000;

const MAX_SELECTED_OPTIONS = 15;

const RADIO_NEXT_CLICK_DELAY_MS = 800;

export type FormValues = {
  [id: number]: string | number | CheckboxInputGroupValue;
};

function getDefaultResponses(
  categories?: Array<CategoryWithNominees>
): FormValues {
  const initialResponses: FormValues = {};

  categories &&
    categories.forEach(({ id }) => {
      initialResponses[id] = "";
    });

  /*
  initialResponses[1] = "2";
  initialResponses[0] = {
    checked: ["222"],
    custom: ["initial text val", "val 2"],
  };
  */

  return initialResponses;
}

type FormProps = {
  formType?: "nomination" | "vote" | "pickem"; // TODO: remove
  categories: Array<CategoryWithNominees>;
  checkmarkColour?: string;
  fetchInitialValues: () => Promise<FormValues>;
  onSubmitForm: (values: FormValues) => Promise<void>;
  onFormTouched: () => void;
};

const Form: React.FunctionComponent<FormProps> = ({
  formType,
  categories,
  checkmarkColour = "green",
  fetchInitialValues,
  onSubmitForm,
  onFormTouched,
}) => {
  let [authStatus, setAuthStatus] = useState<status>(status.notInit);
  let [formTouched, setFormTouched] = useState<boolean>(false);

  let authRef: HTMLElement | null = null;

  const handleFormTouched = () => {
    if (formTouched) {
      return;
    }

    setFormTouched(true);

    onFormTouched();
  };

  let [initialValues, setInitialValues] = useState(
    getDefaultResponses(categories)
  );

  const categoryRefs: { [cID: number]: HTMLDivElement | null } = {};
  const inputRefs: { [cID: number]: HTMLInputElement | HTMLDivElement | null } =
    {};
  const clearInputFnMap: { [cID: number]: () => void } = {};

  const handleNextClick = (currIdx: number, delayMs?: number) => {
    const nextIdx = currIdx === categories.length - 1 ? currIdx : currIdx + 1;
    const nextCatId = categories[nextIdx].id;

    if (!delayMs) {
      delayMs = 0;
    }

    setTimeout(() => {
      const input = inputRefs[nextCatId];
      input && input.focus();

      const catRef = categoryRefs[nextCatId];
      catRef && catRef.scrollIntoView(); // { behavior: "smooth" }
    }, delayMs);
  };

  const clearInput = (catId: number) => {
    const f = clearInputFnMap[catId];
    f && f();
  };

  const validate = (values: FormValues) => {
    switch (authStatus) {
      case status.notInit:
        return "Could not load Google Auth to log in. To submit your responses, try enabling all cookies or do not use incognito mode.";

      case status.pending:
        logInfo("login_required");

        const y = authRef
          ? authRef.getBoundingClientRect().top + window.pageYOffset - 100
          : 0;
        window.scrollTo({ top: y });

        // authRef && authRef.scrollIntoView(false); // { behavior: "smooth" }
        // window.scrollTo(0, 0);
        return "You need to log in before submitting.";

      case status.loggedIn:
        break;

      default:
        logError("unable_to_login-should_not_happen");
        return "Unable to load Google Login due to an error.";
    }

    let hasAnyInput = false;
    let error = null;

    Object.values(values).forEach((v) => {
      if (v !== "") {
        hasAnyInput = true;
      }

      // TODO: validate charset utf8mb4

      const n = Number(v);
      if (n && n > MAX_NUMBER_INPUT_VALUE) {
        error = `Number input should not exceed ${MAX_NUMBER_INPUT_VALUE.toLocaleString()}`;
      }
    });
    if (error) {
      return error;
    }
    if (!hasAnyInput) {
      return "You haven't entered any responses to submit.";
    }

    return "";
  };

  const handleSubmitForm = (
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>
  ) => {
    setSubmitting(true);

    const err = validate(values);
    if (err) {
      toast.error(err);
      setSubmitting(false);
      return;
    }

    async function sendAsyncRequest(values: FormValues) {
      await onSubmitForm(values);
      setSubmitting(false);
    }
    sendAsyncRequest(values);
  };

  const getInputElement = (
    catId: number,
    idx: number,
    nominees?: Nominee[],
    input?: InputDetails
  ): ReactNode => {
    if (input == null || input.type == null) {
      switch (formType) {
        case "vote":
        case "pickem":
          // if (details?.input_type ===InputType.Number) {
          //     inputElement = (
          //       <TextInput
          //         label={`${catId}`}
          //         name={`${catId}`}
          //         type="number"
          //         placeholder="Enter number..."
          //         maxLength={20}
          //         max={MAX_NUMBER_INPUT}
          //         checkmarkColour={checkmarkColour}
          //         handleClick={handleFormTouched}
          //         onEnterKeyDown={() => {
          //           handleNextClick(idx);
          //         }}
          //         setClearInput={(f) => {
          //           clearInputFnMap[catId] = f;
          //         }}
          //         ref={(input) => {
          //           inputRefs[catId] = input;
          //         }}
          //       />
          //     );
          //   }else {

          return (
            <RadioInputGroup
              name={`${catId}`}
              choices={nominees}
              handleClick={handleFormTouched}
              checkmarkColour={checkmarkColour}
              onRadioChoiceCheck={() => {
                handleNextClick(idx, RADIO_NEXT_CLICK_DELAY_MS);
              }}
              setClearInput={(f: () => void) => {
                clearInputFnMap[catId] = f;
              }}
              ref={(input) => {
                inputRefs[catId] = input;
              }}
            />
          );
        // }

        case "nomination":
        default:
          return (
            <TextInput
              label={`${catId}`}
              name={`${catId}`}
              type="text"
              placeholder="Enter nomination…"
              maxLength={64}
              checkmarkColour={checkmarkColour}
              onEnterKeyDown={() => {
                handleNextClick(idx);
              }}
              handleClick={handleFormTouched}
              ref={(input) => {
                inputRefs[catId] = input;
              }}
              setClearInput={(f) => {
                clearInputFnMap[catId] = f;
              }}
            />
          );
      }
    }

    const {
      type: inputType,
      custom_input_prompt,
      max_selections,
      max_length,
      max_value,
    } = input;

    switch (inputType) {
      case InputType.RadioGroup:
        return (
          <RadioInputGroup
            name={`${catId}`}
            choices={nominees}
            handleClick={handleFormTouched}
            checkmarkColour={checkmarkColour}
            onRadioChoiceCheck={() => {
              handleNextClick(idx, RADIO_NEXT_CLICK_DELAY_MS);
            }}
            setClearInput={(f: () => void) => {
              clearInputFnMap[catId] = f;
            }}
            ref={(input) => {
              inputRefs[catId] = input;
            }}
          />
        );

      case InputType.CheckboxGroup:
      case InputType.CheckboxGroupCustom:
        const options: CheckboxInputOption[] =
          nominees == null
            ? []
            : nominees.map((n) => {
                let url = undefined;
                let thumbnail_url = undefined;
                if (n.details != null) {
                  url = n.details.url;
                  thumbnail_url = n.details.thumbnail_url;
                }

                return {
                  label: n.name,
                  value: String(n.nominee_id),
                  url: url,
                  thumbnail_url: thumbnail_url,
                };
              });

        return (
          <CheckboxInputGroup
            name={`${catId}`}
            options={options}
            maxCheckedOptionsAllowed={
              max_selections != null ? max_selections : MAX_SELECTED_OPTIONS
            }
            numCustomInputs={
              input.type === InputType.CheckboxGroupCustom ? 1 : 0
            }
            customInputPrompt={custom_input_prompt}
            maxLength={max_length != null ? max_length : MAX_TEXT_INPUT_LEN}
            checkboxColour={checkmarkColour}
            handleClick={handleFormTouched}
            setClearInput={(f) => {
              clearInputFnMap[catId] = f;
            }}
            ref={(input) => {
              inputRefs[catId] = input;
            }}
          />
        );

      case InputType.Text:
      case InputType.Number:
      default:
        let textType = "text";
        let placeholder =
          custom_input_prompt != null
            ? custom_input_prompt
            : "Enter nomination…";
        let maxLen = max_length != null ? max_length : MAX_TEXT_INPUT_LEN;
        let max = undefined;

        if (inputType === InputType.Number) {
          textType = "number";
          placeholder =
            custom_input_prompt != null
              ? custom_input_prompt
              : "Enter number...";
          maxLen = max_length != null ? max_length : MAX_NUMBER_INPUT_LEN;
          max = max_value != null ? max_value : MAX_NUMBER_INPUT_VALUE;
        }

        return (
          <TextInput
            label={`${catId}`}
            name={`${catId}`}
            type={textType}
            placeholder={placeholder}
            maxLength={maxLen}
            max={max}
            checkmarkColour={checkmarkColour}
            onEnterKeyDown={() => {
              handleNextClick(idx);
            }}
            handleClick={handleFormTouched}
            ref={(input) => {
              inputRefs[catId] = input;
            }}
            setClearInput={(f) => {
              clearInputFnMap[catId] = f;
            }}
          />
        );
    }
  };

  const renderCategoryContent = (
    c: CategoryWithNominees,
    idx: number
  ): ReactNode => {
    const { id: catId, name, details, nominees } = c;

    const description = details?.description || "";
    const inputElement = getInputElement(catId, idx, nominees, details?.input);

    let clearInputText = "Clear";
    switch (details?.input?.type) {
      case InputType.CheckboxGroup:
      case InputType.CheckboxGroupCustom:
        clearInputText = "Clear Selections";
        break;
    }

    return (
      <div
        key={catId}
        id={name ? name.toLowerCase().replace(/ /g, "-") : undefined}
        className={`${styles.Category} ${idx % 2 === 0 && styles.Bkgd}`}
        ref={(element) => {
          categoryRefs[catId] = element;
        }}
      >
        <p className={styles.CategoryIdx}>
          {idx + 1}/{categories.length}
        </p>
        <p className={styles.Title}>{name}</p>

        <div className={styles.Input}>
          {description !== "" && (
            <p className={styles.Description}>{description}</p>
          )}

          {inputElement}

          <div className={styles.BtnContainer}>
            <SimpleBtn
              handleClick={() => {
                clearInput(catId);
              }}
              align="left"
              className={styles.SkipBtnContainer}
            >
              {clearInputText}
            </SimpleBtn>

            {/* {idx !== categories.length - 1 && ( */}
            <SimpleBtn
              handleClick={() => {
                handleNextClick(idx);
              }}
              align="right"
              className={styles.SkipBtnContainer}
            >
              Skip
            </SimpleBtn>
            {/* )} */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <ErrorBoundary>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
      >
        {({ handleSubmit, values, isSubmitting }) => {
          return (
            <FormikForm className={styles.Form}>
              <Auth
                onAuthInit={() => {
                  setAuthStatus(status.pending);
                }}
                onLogin={async () => {
                  setAuthStatus(status.loggedIn);

                  const initialValues = await fetchInitialValues();
                  setInitialValues(initialValues);
                }}
                onLogout={() => {
                  setAuthStatus(status.pending);

                  setInitialValues(getDefaultResponses(categories));
                }}
                ref={(element) => {
                  authRef = element;
                }}
              />

              <div className={styles.CategoryContainer}>
                {categories.map((c, idx) => {
                  return renderCategoryContent(c, idx);
                })}
              </div>

              <div className={styles.SubmitAllContainer}>
                <FancyBtn
                  disabled={isSubmitting}
                  handleClick={(e) => {
                    handleSubmit();
                  }}
                  className={styles.SubmitBtn}
                >
                  {isSubmitting && (
                    <TailSpin
                      ariaLabel="submit-loading-indicator"
                      color="#ffffff"
                      radius={0}
                      height={"100%"}
                      width={"100%"}
                      wrapperClass={styles.LoadingWrapper}
                    />
                  )}
                  <span>Submit All</span>
                </FancyBtn>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </ErrorBoundary>
  );
};

export default Form;
