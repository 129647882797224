import React from "react";
import { useLocation, useHistory } from "react-router-dom";

export function useQuery() {
  const { search } = useLocation();
  const history = useHistory();

  return {
    query: React.useMemo(() => new URLSearchParams(search), [search]),
    setQuery: (params: URLSearchParams) => {
      history.push({ search: params.toString() });
    },
  };
}
