import { year } from "types";
import { GetClientConfigResp, GetWinnersByCategoryResp } from "types/responses";

const winners2022: GetWinnersByCategoryResp = {
  year: 2023,
  categories: [
    {
      id: 1,
      name: "Best Battle Royale Streamer",
      details: {
        description:
          "Streamer that provides the best content while streaming battle royale games like Fortnite, Apex Legends, PUBG etc.",
      },
      winner: {
        nominee_id: 1,
        name: "aceu",
        details: {
          thumbnail_url: "assets/nominees/aceu.jpg",
          url: "https://www.twitch.tv/aceu",
        },
      },
      nominees: [
        {
          nominee_id: 1,
          name: "aceu",
          details: {
            thumbnail_url: "assets/nominees/aceu.jpg",
            url: "https://www.twitch.tv/aceu",
          },
        },
        {
          nominee_id: 2,
          name: "Nickmercs",
          details: {
            thumbnail_url: "assets/nominees/nickmercs.jpg",
            url: "https://www.twitch.tv/Nickmercs",
          },
        },
        {
          nominee_id: 3,
          name: "ImperialHal",
          details: {
            thumbnail_url: "assets/nominees/tsm_imperialhal.jpg",
            url: "https://www.twitch.tv/TSM_ImperialHal",
          },
        },
        {
          nominee_id: 4,
          name: "LuluLuvely",
          details: {
            thumbnail_url: "assets/nominees/lululuvely1.jpg",
            url: "https://twitch.tv/LuluLuvely",
          },
        },
      ],
    },
    {
      id: 16,
      name: "Best MMORPG Streamer",
      details: {
        description:
          "Most entertaining streamer that plays MMORPG games like World of Warcraft, New World, Final Fantasy, Runescape and so on.",
      },
      winner: {
        nominee_id: 64,
        name: "Asmongold",
        details: {
          thumbnail_url: "assets/nominees/asmongold.jpg",
          url: "https://twitch.tv/Asmongold",
        },
      },
      nominees: [
        {
          nominee_id: 61,
          name: "itswill",
          details: {
            thumbnail_url: "assets/nominees/itswill.jpg",
            url: "https://twitch.tv/itswill",
          },
        },
        {
          nominee_id: 62,
          name: "RichWCampbell",
          details: {
            thumbnail_url: "assets/nominees/richwcampbell.jpg",
            url: "https://twitch.tv/richwcampbell",
          },
        },
        {
          nominee_id: 63,
          name: "EsfandTV",
          details: {
            thumbnail_url: "assets/nominees/esfandtv.jpg",
            url: "https://twitch.tv/EsfandTV",
          },
        },
        {
          nominee_id: 64,
          name: "Asmongold",
          details: {
            thumbnail_url: "assets/nominees/asmongold.jpg",
            url: "https://twitch.tv/Asmongold",
          },
        },
      ],
    },
    {
      id: 2,
      name: "Best Role-Play Streamer",
      details: {
        description:
          "Streamer that does role-play (RP) content in games like Rust, VRChat, New World, Ark, Dungeons & Dragons etc.",
      },
      winner: {
        nominee_id: 8,
        name: "Cyr - Rust",
        details: {
          thumbnail_url: "assets/nominees/cyr.jpg",
          url: "https://twitch.tv/cyr",
        },
      },
      nominees: [
        {
          nominee_id: 5,
          name: "CriticalRole - Dungeons and Dragons",
          details: {
            thumbnail_url: "assets/nominees/criticalrole.jpg",
            url: "https://www.twitch.tv/criticalrole",
          },
        },
        {
          nominee_id: 6,
          name: "Roflgator - VRChat",
          details: {
            thumbnail_url: "assets/nominees/roflgator.jpg",
            url: "https://www.twitch.tv/roflgator",
          },
        },
        {
          nominee_id: 7,
          name: "MurderCrumpet - VRChat",
          details: {
            thumbnail_url: "assets/nominees/murdercrumpet.jpg",
            url: "https://www.twitch.tv/murdercrumpet",
          },
        },
        {
          nominee_id: 8,
          name: "Cyr - Rust",
          details: {
            thumbnail_url: "assets/nominees/cyr.jpg",
            url: "https://twitch.tv/cyr",
          },
        },
      ],
    },
    {
      id: 4,
      name: "Best FPS Streamer",
      details: {
        description:
          "Streamer that creates the best content while playing first-person shooter (FPS) games like Overwatch, Halo, Call of Duty and Counter Strike, to name a few.",
      },
      winner: {
        nominee_id: 15,
        name: "Tarik",
        details: {
          thumbnail_url: "assets/nominees/tarik.jpg",
          url: "https://twitch.tv/Tarik",
        },
      },
      nominees: [
        {
          nominee_id: 13,
          name: "Shroud",
          details: {
            thumbnail_url: "assets/nominees/shroud.jpg",
            url: "https://twitch.tv/Shroud",
          },
        },
        {
          nominee_id: 14,
          name: "supertf",
          details: {
            thumbnail_url: "assets/nominees/supertf.jpeg",
            url: "https://twitch.tv/supertf",
          },
        },
        {
          nominee_id: 15,
          name: "Tarik",
          details: {
            thumbnail_url: "assets/nominees/tarik.jpg",
            url: "https://twitch.tv/Tarik",
          },
        },
        {
          nominee_id: 16,
          name: "TenZ",
          details: {
            thumbnail_url: "assets/nominees/tenz.jpg",
            url: "https://twitch.tv/Tenz",
          },
        },
      ],
    },
    {
      id: 13,
      name: "Best Super Smash Bros. Streamer",
      details: {
        description:
          "Streamer that provides the best content while playing Smash games like SSB Melee, SSB Ultimate, SSB Brawl etc.",
      },
      winner: {
        nominee_id: 50,
        name: "Mang0",
        details: {
          thumbnail_url: "assets/nominees/mang0.jpg",
          url: "https://twitch.tv/Mang0",
        },
      },
      nominees: [
        {
          nominee_id: 49,
          name: "HungryBox",
          details: {
            thumbnail_url: "assets/nominees/hungrybox.jpg",
            url: "https://twitch.tv/HungryBox",
          },
        },
        {
          nominee_id: 50,
          name: "Mang0",
          details: {
            thumbnail_url: "assets/nominees/mang0.jpg",
            url: "https://twitch.tv/Mang0",
          },
        },
        {
          nominee_id: 51,
          name: "Leffen",
          details: {
            thumbnail_url: "assets/nominees/leffen.jpg",
            url: "https://twitch.tv/Leffen",
          },
        },
        {
          nominee_id: 52,
          name: "iBDW",
          details: {
            thumbnail_url: "assets/nominees/ibdw.jpg",
            url: "https://twitch.tv/iBDW",
          },
        },
      ],
    },
    {
      id: 10,
      name: "Best Chess Streamer",
      details: {
        description: "Best Streamer to watch for Chess content.",
      },
      winner: {
        nominee_id: 38,
        name: "BotezLive",
        details: {
          thumbnail_url: "assets/nominees/botezlive.jpg",
          url: "https://twitch.tv/BotezLive",
        },
      },
      nominees: [
        {
          nominee_id: 37,
          name: "GMHikaru",
          details: {
            thumbnail_url: "assets/nominees/gmhikaru.jpg",
            url: "https://twitch.tv/GMHikaru",
          },
        },
        {
          nominee_id: 38,
          name: "BotezLive",
          details: {
            thumbnail_url: "assets/nominees/botezlive.jpg",
            url: "https://twitch.tv/BotezLive",
          },
        },
        {
          nominee_id: 39,
          name: "GothamChess",
          details: {
            thumbnail_url: "assets/nominees/gothamchess.jpg",
            url: "https://twitch.tv/GothamChess",
          },
        },
        {
          nominee_id: 40,
          name: "akaNemsko",
          details: {
            thumbnail_url: "assets/nominees/akanemsko.jpg",
            url: "https://twitch.tv/akanemsko",
          },
        },
      ],
    },
    {
      id: 7,
      name: "Best Strategy Game Streamer",
      details: {
        description:
          "Streamer that creates the best content while playing strategy games like Dota, Teamfight Tactics (TFT), Starcraft, Hearthstone, Super Auto Pets (SAP) and the like.",
      },
      winner: {
        nominee_id: 26,
        name: "DisguisedToast",
        details: {
          thumbnail_url: "assets/nominees/disguisedtoast.jpeg",
          url: "https://twitch.tv/disguisedtoast",
        },
      },
      nominees: [
        {
          nominee_id: 25,
          name: "Northernlion",
          details: {
            thumbnail_url: "assets/nominees/northernlion.jpg",
            url: "https://twitch.tv/northernlion",
          },
        },
        {
          nominee_id: 26,
          name: "DisguisedToast",
          details: {
            thumbnail_url: "assets/nominees/disguisedtoast.jpeg",
            url: "https://twitch.tv/disguisedtoast",
          },
        },
        {
          nominee_id: 27,
          name: "Scarra",
          details: {
            thumbnail_url: "assets/nominees/scarra.jpg",
            url: "https://twitch.tv/Scarra",
          },
        },
        {
          nominee_id: 28,
          name: "BoxBox",
          details: {
            thumbnail_url: "assets/nominees/boxbox.jpg",
            url: "https://twitch.tv/boxbox",
          },
        },
      ],
    },
    {
      id: 12,
      name: "Best Speedrunner",
      details: {
        description:
          "Streamer that provides the best content while speedrunning games.",
      },
      winner: {
        nominee_id: 46,
        name: "SmallAnt",
        details: {
          thumbnail_url: "assets/nominees/smallant.jpg",
          url: "https://twitch.tv/SmallAnt",
        },
      },
      nominees: [
        {
          nominee_id: 45,
          name: "Simply",
          details: {
            thumbnail_url: "assets/nominees/simply.jpg",
            url: "https://twitch.tv/Simply",
          },
        },
        {
          nominee_id: 46,
          name: "SmallAnt",
          details: {
            thumbnail_url: "assets/nominees/smallant.jpg",
            url: "https://twitch.tv/SmallAnt",
          },
        },
        {
          nominee_id: 47,
          name: "Atrioc",
          details: {
            thumbnail_url: "assets/nominees/atrioc.jpg",
            url: "https://twitch.tv/Atrioc",
          },
        },
        {
          nominee_id: 48,
          name: "Wirtual",
          details: {
            thumbnail_url: "assets/nominees/wirtual.jpg",
            url: "https://twitch.tv/Wirtual",
          },
        },
      ],
    },
    {
      id: 8,
      name: "Best ASMR Streamer",
      details: {
        description: "Streamer that provides the best ASMR content.",
      },
      winner: {
        nominee_id: 29,
        name: "Amouranth",
        details: {
          thumbnail_url: "assets/nominees/amouranth.jpg",
          url: "https://twitch.tv/Amouranth",
        },
      },
      nominees: [
        {
          nominee_id: 29,
          name: "Amouranth",
          details: {
            thumbnail_url: "assets/nominees/amouranth.jpg",
            url: "https://twitch.tv/Amouranth",
          },
        },
        {
          nominee_id: 30,
          name: "FoxenKin",
          details: {
            thumbnail_url: "assets/nominees/foxenkin.jfif",
            url: "https://twitch.tv/FoxenKin",
          },
        },
        {
          nominee_id: 31,
          name: "Katrine",
          details: {
            thumbnail_url: "assets/nominees/katrine.jpg",
            url: "https://twitch.tv/Katrine",
          },
        },
        {
          nominee_id: 32,
          name: "SkepticalPickle",
          details: {
            thumbnail_url: "assets/nominees/skepticalpickle.jpg",
            url: "https://twitch.tv/SkepticalPickle",
          },
        },
      ],
    },
    {
      id: 6,
      name: "Best VTuber Streamer",
      details: {
        description:
          "Streamer who uses a Virtual Avatar instead of a traditional face-cam, and provides the most entertaining content.",
      },
      winner: {
        nominee_id: 24,
        name: "CodeMiko",
        details: {
          thumbnail_url: "assets/nominees/codemiko.jpg",
          url: "https://twitch.tv/CodeMiko",
        },
      },
      nominees: [
        {
          nominee_id: 21,
          name: "Veibae",
          details: {
            thumbnail_url: "assets/nominees/veibae.jpg",
            url: "https://twitch.tv/Veibae",
          },
        },
        {
          nominee_id: 22,
          name: "Ironmouse",
          details: {
            thumbnail_url: "assets/nominees/ironmouse.jpg",
            url: "https://twitch.tv/Ironmouse",
          },
        },
        {
          nominee_id: 23,
          name: "Nyanners",
          details: {
            thumbnail_url: "assets/nominees/nyanners.jpg",
            url: "https://twitch.tv/Nyanners",
          },
        },
        {
          nominee_id: 24,
          name: "CodeMiko",
          details: {
            thumbnail_url: "assets/nominees/codemiko.jpg",
            url: "https://twitch.tv/CodeMiko",
          },
        },
      ],
    },
    {
      id: 9,
      name: "Best Music Streamer",
      details: {
        description: "Streamer that creates the best music content.",
      },
      winner: {
        nominee_id: 36,
        name: "LilyPichu",
        details: {
          thumbnail_url: "assets/nominees/lilypichu.jpg",
          url: "https://twitch.tv/lilypichu",
        },
      },
      nominees: [
        {
          nominee_id: 33,
          name: "ARIatHOME",
          details: {
            thumbnail_url: "assets/nominees/ariathome.jpg",
            url: "https://twitch.tv/ARIatHOME",
          },
        },
        {
          nominee_id: 34,
          name: "SethDrums",
          details: {
            thumbnail_url: "assets/nominees/sethdrums.jpg",
            url: "https://twitch.tv/SethDrums",
          },
        },
        {
          nominee_id: 35,
          name: "T-Pain",
          details: {
            thumbnail_url: "assets/nominees/tpain.jpg",
            url: "https://twitch.tv/tpain",
          },
        },
        {
          nominee_id: 36,
          name: "LilyPichu",
          details: {
            thumbnail_url: "assets/nominees/lilypichu.jpg",
            url: "https://twitch.tv/lilypichu",
          },
        },
      ],
    },
    {
      id: 11,
      name: "Best IRL Streamer",
      details: {
        description:
          "Streamer that creates the best In Real Life (IRL) content such as vlogging, travelling, cooking, visting places etc.",
      },
      winner: {
        nominee_id: 41,
        name: "HAchubby",
        details: {
          thumbnail_url: "assets/nominees/hachubby.jpg",
          url: "https://twitch.tv/hachubby",
        },
      },
      nominees: [
        {
          nominee_id: 41,
          name: "HAchubby",
          details: {
            thumbnail_url: "assets/nominees/hachubby.jpg",
            url: "https://twitch.tv/hachubby",
          },
        },
        {
          nominee_id: 42,
          name: "Robcdee",
          details: {
            thumbnail_url: "assets/nominees/robcdee.jpg",
            url: "https://twitch.tv/robcdee",
          },
        },
        {
          nominee_id: 43,
          name: "jakenbakeLIVE",
          details: {
            thumbnail_url: "assets/nominees/jakenbakelive.jpg",
            url: "https://twitch.tv/jakenbakeLIVE",
          },
        },
        {
          nominee_id: 44,
          name: "Jinnytty",
          details: {
            thumbnail_url: "assets/nominees/jinnytty.jpg",
            url: "https://twitch.tv/Jinnytty",
          },
        },
      ],
    },
    {
      id: 3,
      name: "Best League of Legends Streamer",
      details: {
        description:
          "Streamer that provides the best content while streaming League of Legends.",
      },
      winner: {
        nominee_id: 9,
        name: "loltyler1",
        details: {
          thumbnail_url: "assets/nominees/loltyler1.jpg",
          url: "https://twitch.tv/loltyler1",
        },
      },
      nominees: [
        {
          nominee_id: 9,
          name: "loltyler1",
          details: {
            thumbnail_url: "assets/nominees/loltyler1.jpg",
            url: "https://twitch.tv/loltyler1",
          },
        },
        {
          nominee_id: 10,
          name: "Emiru",
          details: {
            thumbnail_url: "assets/nominees/emiru.jpg",
            url: "https://twitch.tv/Emiru",
          },
        },
        {
          nominee_id: 11,
          name: "Doublelift",
          details: {
            thumbnail_url: "assets/nominees/doublelift.jpg",
            url: "https://twitch.tv/Doublelift",
          },
        },
        {
          nominee_id: 12,
          name: "LilyPichu",
          details: {
            thumbnail_url: "assets/nominees/lilypichu.jpg",
            url: "https://twitch.tv/LilyPichu",
          },
        },
      ],
    },
    {
      id: 14,
      name: "Best Minecraft Streamer",
      details: {
        description: "Most enjoyable streamer to watch for Minecraft content.",
      },
      winner: {
        nominee_id: 53,
        name: "TommyInnit",
        details: {
          thumbnail_url: "assets/nominees/tommyinnit.jpg",
          url: "https://twitch.tv/TommyInnit",
        },
      },
      nominees: [
        {
          nominee_id: 53,
          name: "TommyInnit",
          details: {
            thumbnail_url: "assets/nominees/tommyinnit.jpg",
            url: "https://twitch.tv/TommyInnit",
          },
        },
        {
          nominee_id: 54,
          name: "Forsen",
          details: {
            thumbnail_url: "assets/nominees/forsen.jpg",
            url: "https://twitch.tv/Forsen",
          },
        },
        {
          nominee_id: 55,
          name: "RanbooLive",
          details: {
            thumbnail_url: "assets/nominees/ranboolive.jpg",
            url: "https://twitch.tv/RanbooLive",
          },
        },
        {
          nominee_id: 56,
          name: "Tubbo",
          details: {
            thumbnail_url: "assets/nominees/tubbo.jpg",
            url: "https://twitch.tv/Tubbo",
          },
        },
      ],
    },
    {
      id: 28,
      name: "Best Valorant Streamer",
      details: {
        description: "Streamer that provides the best Valorant content.",
      },
      winner: {
        nominee_id: 109,
        name: "iiTzTimmy",
        details: {
          thumbnail_url: "assets/nominees/iitztimmy.jpg",
          url: "https://www.twitch.tv/iiTzTimmy",
        },
      },
      nominees: [
        {
          nominee_id: 109,
          name: "iiTzTimmy",
          details: {
            thumbnail_url: "assets/nominees/iitztimmy.jpg",
            url: "https://www.twitch.tv/iiTzTimmy",
          },
        },
        {
          nominee_id: 110,
          name: "Punz",
          details: {
            thumbnail_url: "assets/nominees/punz.jpg",
            url: "https://twitch.tv/Punz",
          },
        },
        {
          nominee_id: 111,
          name: "Pokimane",
          details: {
            thumbnail_url: "assets/nominees/pokimane.jpg",
            url: "https://twitch.tv/pokimane",
          },
        },
        {
          nominee_id: 112,
          name: "QuarterJade",
          details: {
            thumbnail_url: "assets/nominees/quarterjade.jpg",
            url: "https://twitch.tv/quarterjade",
          },
        },
      ],
    },
    {
      id: 29,
      name: "Best GTA RP Streamer",
      details: {
        description:
          "Streamer that provides the best Grand Theft Auto role-play (GTA RP) content.",
      },
      winner: {
        nominee_id: 114,
        name: "Buddha",
        details: {
          thumbnail_url: "assets/nominees/buddha.png",
          url: "https://twitch.tv/Buddha",
        },
      },
      nominees: [
        {
          nominee_id: 113,
          name: "xQcOW",
          details: {
            thumbnail_url: "assets/nominees/xqcow.jpg",
            url: "https://twitch.tv/xQcOW",
          },
        },
        {
          nominee_id: 114,
          name: "Buddha",
          details: {
            thumbnail_url: "assets/nominees/buddha.png",
            url: "https://twitch.tv/Buddha",
          },
        },
        {
          nominee_id: 115,
          name: "Sykkuno",
          details: {
            thumbnail_url: "assets/nominees/sykkuno.jpg",
            url: "https://twitch.tv/Sykkuno",
          },
        },
        {
          nominee_id: 116,
          name: "Fuslie",
          details: {
            thumbnail_url: "assets/nominees/fuslie.jpg",
            url: "https://twitch.tv/Fuslie",
          },
        },
      ],
    },
    {
      id: 18,
      name: "Best Philanthropic Streamer",
      details: {
        description:
          "Streamer that contributes to philanthropic causes by hosting charity streams, events, spreading awareness and other such initiatives.",
      },
      winner: {
        nominee_id: 69,
        name: "Jacksepticeye",
        details: {
          thumbnail_url: "assets/nominees/jacksepticeye.jpg",
          url: "https://twitch.tv/Jacksepticeye",
        },
      },
      nominees: [
        {
          nominee_id: 69,
          name: "Jacksepticeye",
          details: {
            thumbnail_url: "assets/nominees/jacksepticeye.jpg",
            url: "https://twitch.tv/Jacksepticeye",
          },
        },
        {
          nominee_id: 70,
          name: "HealthyGamer_GG",
          details: {
            thumbnail_url: "assets/nominees/healthygamergg1.jpg",
            url: "https://twitch.tv/HealthyGamer_GG",
          },
        },
        {
          nominee_id: 71,
          name: "DrLupo",
          details: {
            thumbnail_url: "assets/nominees/drlupo.jpeg",
            url: "https://www.youtube.com/DrLupo",
          },
        },
        {
          nominee_id: 72,
          name: "RanbooLive",
          details: {
            thumbnail_url: "assets/nominees/ranboolive.jpg",
            url: "https://twitch.tv/RanbooLive",
          },
        },
      ],
    },
    {
      id: 21,
      name: "Stream Game of the Year",
      details: {
        description:
          "The best game to watch on stream during the past year, because it was very entertaining, novel, fun, interesting, cool etc.",
      },
      winner: {
        nominee_id: 83,
        name: "Minecraft",
        details: {
          thumbnail_url: "assets/nominees/minecraft.jpg",
        },
      },
      nominees: [
        {
          nominee_id: 81,
          name: "GTA V",
          details: {
            thumbnail_url: "assets/nominees/gtav.jpg",
          },
        },
        {
          nominee_id: 82,
          name: "Valorant",
          details: {
            thumbnail_url: "assets/nominees/valorant.jpg",
          },
        },
        {
          nominee_id: 83,
          name: "Minecraft",
          details: {
            thumbnail_url: "assets/nominees/minecraft.jpg",
          },
        },
        {
          nominee_id: 84,
          name: "Apex Legends",
          details: {
            thumbnail_url: "assets/nominees/apexlegends.jpg",
          },
        },
      ],
    },
    {
      id: 19,
      name: "Best Variety Streamer",
      details: {
        description:
          "Best streamer that broadcasts a variety of content-  of different types and in many categories.",
      },
      winner: {
        nominee_id: 73,
        name: "Moistcr1tikal",
        details: {
          thumbnail_url: "assets/nominees/moistcr1tikal.jpg",
          url: "https://twitch.tv/moistcr1tikal",
        },
      },
      nominees: [
        {
          nominee_id: 73,
          name: "Moistcr1tikal",
          details: {
            thumbnail_url: "assets/nominees/moistcr1tikal.jpg",
            url: "https://twitch.tv/moistcr1tikal",
          },
        },
        {
          nominee_id: 74,
          name: "Ludwig",
          details: {
            thumbnail_url: "assets/nominees/ludwig.jpg",
            url: "https://www.youtube.com/c/Ludwigahgren",
          },
        },
        {
          nominee_id: 75,
          name: "Valkyrae",
          details: {
            thumbnail_url: "assets/nominees/valkyrae.jpg",
            url: "https://www.youtube.com/c/Valkyrae1",
          },
        },
        {
          nominee_id: 76,
          name: "DisguisedToast",
          details: {
            thumbnail_url: "assets/nominees/disguisedtoast.jpeg",
            url: "https://twitch.tv/disguisedtoast",
          },
        },
      ],
    },
    {
      id: 5,
      name: "Best Just Chatting Streamer",
      details: {
        description:
          "Streamer that regularly does Just Chatting content such as conversing with chat, reacting to news and videos, arts and crafts, telling stories, hanging out with friends on stream and the like.",
      },
      winner: {
        nominee_id: 17,
        name: "Mizkif",
        details: {
          thumbnail_url: "assets/nominees/mizkif.jpg",
          url: "https://www.twitch.tv/mizkif",
        },
      },
      nominees: [
        {
          nominee_id: 17,
          name: "Mizkif",
          details: {
            thumbnail_url: "assets/nominees/mizkif.jpg",
            url: "https://www.twitch.tv/mizkif",
          },
        },
        {
          nominee_id: 18,
          name: "HasanAbi",
          details: {
            thumbnail_url: "assets/nominees/hasanabi.jpg",
            url: "https://www.twitch.tv/hasanabi",
          },
        },
        {
          nominee_id: 19,
          name: "PaymoneyWubby",
          details: {
            thumbnail_url: "assets/nominees/paymoneywubby.jpg",
            url: "https://www.twitch.tv/PaymoneyWubby",
          },
        },
        {
          nominee_id: 20,
          name: "BruceDropEmOff",
          details: {
            thumbnail_url: "assets/nominees/brucedropemoff.jpg",
            url: "https://www.twitch.tv/brucedropemoff",
          },
        },
      ],
    },
    {
      id: 20,
      name: "Best Streamed Event",
      details: {
        description:
          "The most entertaining, interesting or enjoyable collaborative event streamed during the past year.",
      },
      winner: {
        nominee_id: 78,
        name: "The Jerma985 Dollhouse - Jerma985",
        details: {
          thumbnail_url: "assets/nominees/thejerma985dollhouse.jpg",
          url: "https://www.youtube.com/watch?v=mMz6PWrvShU&list=PLkJjP9Lr7Bn725FUtmwvhFInGkrkenPsk",
        },
      },
      nominees: [
        {
          nominee_id: 77,
          name: "Balloon World Cup - Ibai",
          details: {
            thumbnail_url: "assets/nominees/baloonworldcup.jpeg",
            url: "https://www.youtube.com/playlist?list=PLHCPwKwATUgA0y1ckLH_tai4RX9h9oI74",
          },
        },
        {
          nominee_id: 78,
          name: "The Jerma985 Dollhouse - Jerma985",
          details: {
            thumbnail_url: "assets/nominees/thejerma985dollhouse.jpg",
            url: "https://www.youtube.com/watch?v=mMz6PWrvShU&list=PLkJjP9Lr7Bn725FUtmwvhFInGkrkenPsk",
          },
        },
        {
          nominee_id: 79,
          name: "The Price is Scuffed - PaymoneyWubby",
          details: {
            thumbnail_url: "assets/nominees/thepriceisscuffed.jpg",
            url: "https://www.youtube.com/watch?v=r9KYpyxnLyI",
          },
        },
        {
          nominee_id: 80,
          name: "Shit Con - Nmplol",
          details: {
            thumbnail_url: "assets/nominees/shitcon.jpg",
            url: "https://www.youtube.com/watch?v=czkvjUdt5ew",
          },
        },
      ],
    },
    {
      id: 22,
      name: "Best Content Organization",
      details: {
        description:
          "Esports or content organization that has created the best content during the past year.",
      },
      winner: {
        nominee_id: 86,
        name: "OTK",
        details: {
          thumbnail_url: "assets/nominees/otk.jpg",
          url: "https://otknetwork.com/",
        },
      },
      nominees: [
        {
          nominee_id: 85,
          name: "100 Thieves",
          details: {
            thumbnail_url: "assets/nominees/100thieves.jpg",
            url: "https://100thieves.com/",
          },
        },
        {
          nominee_id: 86,
          name: "OTK",
          details: {
            thumbnail_url: "assets/nominees/otk.jpg",
            url: "https://otknetwork.com/",
          },
        },
        {
          nominee_id: 87,
          name: "OfflineTV",
          details: {
            thumbnail_url: "assets/nominees/offlinetv.jpg",
            url: "https://offlinetv.com/",
          },
        },
        {
          nominee_id: 88,
          name: "NRG",
          details: {
            thumbnail_url: "assets/nominees/nrg.jpg",
            url: "https://www.nrg.gg/",
          },
        },
      ],
    },
    {
      id: 24,
      name: "Rising Star Award",
      details: {
        description:
          "Up-and-coming streamer that has had tremendous growth over the past year and has the potential to be a top streamer.",
      },
      winner: {
        nominee_id: 93,
        name: "Stanz",
        details: {
          thumbnail_url: "assets/nominees/stanz.jpg",
          url: "https://www.twitch.tv/stanz",
        },
      },
      nominees: [
        {
          nominee_id: 93,
          name: "Stanz",
          details: {
            thumbnail_url: "assets/nominees/stanz.jpg",
            url: "https://www.twitch.tv/stanz",
          },
        },
        {
          nominee_id: 94,
          name: "Frogan",
          details: {
            thumbnail_url: "assets/nominees/frogan.jfif",
            url: "https://www.twitch.tv/frogan",
          },
        },
        {
          nominee_id: 95,
          name: "Zoil",
          details: {
            thumbnail_url: "assets/nominees/zoil.jpg",
            url: "https://www.twitch.tv/zoil",
          },
        },
        {
          nominee_id: 96,
          name: "Purplecliffe",
          details: {
            thumbnail_url: "assets/nominees/purplecliffe.jpg",
            url: "https://www.twitch.tv/purplecliffe",
          },
        },
      ],
    },
    {
      id: 23,
      name: "League of their Own",
      details: {
        description:
          "This streamer is a pioneer that creates one-of-a-kind content. With their creativity and out-of-the-box thinking, they are a trailblazer in the streaming industry. Someone who relies on themselves for their content ideas and is genuinely in a league of their own.",
      },
      winner: {
        nominee_id: 89,
        name: "Jerma985",
        details: {
          thumbnail_url: "assets/nominees/jerma985.jpg",
          url: "https://www.twitch.tv/jerma985",
        },
      },
      nominees: [
        {
          nominee_id: 89,
          name: "Jerma985",
          details: {
            thumbnail_url: "assets/nominees/jerma985.jpg",
            url: "https://www.twitch.tv/jerma985",
          },
        },
        {
          nominee_id: 90,
          name: "Kitboga",
          details: {
            thumbnail_url: "assets/nominees/kitboga.jpg",
            url: "https://www.twitch.tv/kitboga",
          },
        },
        {
          nominee_id: 91,
          name: "TheSushiDragon",
          details: {
            thumbnail_url: "assets/nominees/thesushidragon.jpg",
            url: "https://www.twitch.tv/thesushidragon",
          },
        },
        {
          nominee_id: 92,
          name: "Ibai",
          details: {
            thumbnail_url: "assets/nominees/ibai.jfif",
            url: "https://www.twitch.tv/ibai",
          },
        },
      ],
    },
    {
      id: 25,
      name: "Legacy Award",
      details: {
        description:
          "A legend who has contributed a great deal to and has shaped the streaming industry, i.e., a career achievement award.",
      },
      winner: {
        nominee_id: 98,
        name: "Pokimane",
        details: {
          thumbnail_url: "assets/nominees/pokimane.jpg",
          url: "https://twitch.tv/Pokimane",
        },
      },
      nominees: [
        {
          nominee_id: 97,
          name: "Summit1g",
          details: {
            thumbnail_url: "assets/nominees/summit1g.jpg",
            url: "https://twitch.tv/Summit1g",
          },
        },
        {
          nominee_id: 98,
          name: "Pokimane",
          details: {
            thumbnail_url: "assets/nominees/pokimane.jpg",
            url: "https://twitch.tv/Pokimane",
          },
        },
        {
          nominee_id: 99,
          name: "Sodapoppin",
          details: {
            thumbnail_url: "assets/nominees/sodapoppin.jpg",
            url: "https://twitch.tv/Sodapoppin",
          },
        },
        {
          nominee_id: 100,
          name: "Scarra",
          details: {
            thumbnail_url: "assets/nominees/scarra.jpg",
            url: "https://twitch.tv/Scarra",
          },
        },
      ],
    },
    {
      id: 26,
      name: "Gamer of the Year",
      details: {
        description:
          "Streamer that has extraordinary gaming abilities and has excelled at games over the past year. No matter what game they're playing, you just have to watch.",
      },
      winner: {
        nominee_id: 101,
        name: "Shroud",
        details: {
          thumbnail_url: "assets/nominees/shroud.jpg",
          url: "https://www.twitch.tv/Shroud",
        },
      },
      nominees: [
        {
          nominee_id: 101,
          name: "Shroud",
          details: {
            thumbnail_url: "assets/nominees/shroud.jpg",
            url: "https://www.twitch.tv/Shroud",
          },
        },
        {
          nominee_id: 102,
          name: "iiTzTimmy",
          details: {
            thumbnail_url: "assets/nominees/iitztimmy.jpg",
            url: "https://www.twitch.tv/iiTzTimmy",
          },
        },
        {
          nominee_id: 103,
          name: "aceu",
          details: {
            thumbnail_url: "assets/nominees/aceu.jpg",
            url: "https://www.twitch.tv/aceu",
          },
        },
        {
          nominee_id: 104,
          name: "TenZ",
          details: {
            thumbnail_url: "assets/nominees/tenz.jpg",
            url: "https://www.twitch.tv/tenz",
          },
        },
      ],
    },
    {
      id: 27,
      name: "Streamer of the Year",
      details: {
        description:
          "The best overall streamer for the past year. They saw great success with their entertaining content, elevated their brand, and in general, left their mark on viewers. This. was. their. year.",
      },
      winner: {
        nominee_id: 106,
        name: "Ludwig",
        details: {
          thumbnail_url: "assets/nominees/ludwig.jpg",
          url: "https://www.youtube.com/c/Ludwigahgren",
        },
      },
      nominees: [
        {
          nominee_id: 105,
          name: "Mizkif",
          details: {
            thumbnail_url: "assets/nominees/mizkif.jpg",
            url: "https://www.twitch.tv/mizkif",
          },
        },
        {
          nominee_id: 106,
          name: "Ludwig",
          details: {
            thumbnail_url: "assets/nominees/ludwig.jpg",
            url: "https://www.youtube.com/c/Ludwigahgren",
          },
        },
        {
          nominee_id: 107,
          name: "xQcOW",
          details: {
            thumbnail_url: "assets/nominees/xqcow.jpg",
            url: "https://www.twitch.tv/xqcow",
          },
        },
        {
          nominee_id: 108,
          name: "Sykkuno",
          details: {
            thumbnail_url: "assets/nominees/sykkuno.jpg",
            url: "https://www.twitch.tv/sykkuno",
          },
        },
      ],
    },
  ],
};

const winners2023: GetWinnersByCategoryResp = {
  year: 2023,
  categories: [
    {
      id: 31,
      name: "Best MMORPG Streamer",
      details: {
        description:
          "Streamer who has the most entertaining MMORPG content while playing games like World of Warcraft, New World, Final Fantasy XIV, Runescape and so on.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 150,
          year: 2023,
          name: "EsfandTV",
          details: {
            thumbnail_url: "/assets/2023/nominees/EsfandTV.jpg",
            clip_url:
              "https://clips.twitch.tv/TsundereHonestFerretPermaSmug-_eNISDAw3quvf7n7",
            url: "https://www.twitch.tv/esfandtv",
          },
        },
        {
          nominee_id: 134,
          year: 2023,
          name: "B0aty",
          details: {
            thumbnail_url: "/assets/2023/nominees/B0aty.jpg",
            clip_url:
              "https://www.twitch.tv/b0aty/clip/ThirstyCovertStorkPanicVis-GLtlZV7GCf3zMmGw",
            url: "https://www.twitch.tv/b0aty",
          },
        },
        {
          nominee_id: 135,
          year: 2023,
          name: "Asmongold",
          details: {
            thumbnail_url: "/assets/2023/nominees/Asmongold.jpg",
            clip_url:
              "https://clips.twitch.tv/FrozenAmazonianNigiriEleGiggle-NWD2QT0yNdn0u7TQ",
            url: "https://www.twitch.tv/asmongold",
          },
        },
        {
          nominee_id: 161,
          year: 2023,
          name: "Maximum",
          details: {
            thumbnail_url: "/assets/2023/nominees/Maximum.jpg",
            clip_url:
              "https://www.twitch.tv/maximum/clip/FaithfulAffluentBunnyOSkomodo-H4ynnTjKXv26bExE",
            url: "https://www.twitch.tv/maximum",
          },
        },
      ],
      winner: {
        nominee_id: 135,
        year: 2023,
        name: "Asmongold",
        details: {
          thumbnail_url: "/assets/2023/nominees/Asmongold.jpg",
          clip_url:
            "https://clips.twitch.tv/FrozenAmazonianNigiriEleGiggle-NWD2QT0yNdn0u7TQ",
          url: "https://www.twitch.tv/asmongold",
        },
      },
    },
    {
      id: 34,
      name: "Best Soulslike Streamer",
      details: {
        description:
          "Best streamer to watch playing Soulslike games like Elden Ring, Bloodborne, Demon's Souls, Dark Souls series, Sekiro: Shadows Die Twice, and Star Wars Jedi: Fallen Order among others.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 185,
          year: 2023,
          name: "Distortion2",
          details: {
            thumbnail_url: "/assets/2023/nominees/Distortion2.jpg",
            clip_url:
              "https://clips.twitch.tv/StrangePiercingPeachSmoocherZ-zI8dUJQ20qGzwHZQ",
            url: "https://www.twitch.tv/distortion2",
          },
        },
        {
          nominee_id: 145,
          year: 2023,
          name: "MissMikkaa",
          details: {
            thumbnail_url: "/assets/2023/nominees/MissMikkaa.jpg",
            clip_url:
              "https://clips.twitch.tv/TangibleObedientTrayShazBotstix-Zfy2lfl0UOymVvS4",
            url: "https://www.twitch.tv/missmikkaa",
          },
        },
        {
          nominee_id: 136,
          year: 2023,
          name: "LilAggy",
          details: {
            thumbnail_url: "/assets/2023/nominees/LilAggy.jpg",
            clip_url:
              "https://clips.twitch.tv/CleanObedientTroutThunBeast-nHhEgREyZu3tagC3",
            url: "https://www.twitch.tv/lilaggy",
          },
        },
        {
          nominee_id: 186,
          year: 2023,
          name: "LobosJr",
          details: {
            thumbnail_url: "/assets/2023/nominees/LobosJr.jpg",
            clip_url:
              "https://www.twitch.tv/lobosjr/clip/TenderHomelyLlamaWoofer",
            url: "https://www.twitch.tv/lobosjr",
          },
        },
      ],
      winner: {
        nominee_id: 145,
        year: 2023,
        name: "MissMikkaa",
        details: {
          thumbnail_url: "/assets/2023/nominees/MissMikkaa.jpg",
          clip_url:
            "https://clips.twitch.tv/TangibleObedientTrayShazBotstix-Zfy2lfl0UOymVvS4",
          url: "https://www.twitch.tv/missmikkaa",
        },
      },
    },
    {
      id: 42,
      name: "Best Art Streamer",
      details: {
        description:
          "The best streamer to watch creating art such as paintings, crafts, digital art, animations, graphics and so on.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 169,
          year: 2023,
          name: "DyaRikku",
          details: {
            thumbnail_url: "/assets/2023/nominees/Dyarikku.jpg",
            clip_url:
              "https://clips.twitch.tv/AstuteRoughPotDoggo-b6eJROO3X4tkeCN0",
            url: "https://www.twitch.tv/dyarikku",
          },
        },
        {
          nominee_id: 139,
          year: 2023,
          name: "MeatCanyon",
          details: {
            thumbnail_url: "/assets/2023/nominees/MeatCanyon.jpg",
            clip_url:
              "https://clips.twitch.tv/CheerfulObliviousConsoleWow-uorsHyRBhQFFjnOR",
            url: "https://www.twitch.tv/meatcanyon",
          },
        },
        {
          nominee_id: 211,
          year: 2023,
          name: "RubberRoss",
          details: {
            thumbnail_url: "/assets/2023/nominees/RubberRoss.jpg",
            clip_url:
              "https://www.twitch.tv/rubberross/clip/AwkwardAcceptableGalagoKappaClaus-ueTzGlvWxJfSeHpA",
            url: "https://www.twitch.tv/rubberross",
          },
        },
        {
          nominee_id: 194,
          year: 2023,
          name: "NeonBeat",
          details: {
            thumbnail_url: "/assets/2023/nominees/NeonBeat.jpg",
            clip_url:
              "https://clips.twitch.tv/FlaccidBombasticFiddleheadsDeIlluminati-NTZmbLF8q9EEl1jh",
            url: "https://www.twitch.tv/neonbeat",
          },
        },
      ],
      winner: {
        nominee_id: 139,
        year: 2023,
        name: "MeatCanyon",
        details: {
          thumbnail_url: "/assets/2023/nominees/MeatCanyon.jpg",
          clip_url:
            "https://clips.twitch.tv/CheerfulObliviousConsoleWow-uorsHyRBhQFFjnOR",
          url: "https://www.twitch.tv/meatcanyon",
        },
      },
    },
    {
      id: 37,
      name: "Best Strategy Game Streamer",
      details: {
        description:
          "Streamer that creates the best content while playing strategy games like Dota, Teamfight Tactics (TFT), Starcraft, Hearthstone, Super Auto Pets (SAP) and the like.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 158,
          year: 2023,
          name: "k3soju",
          details: {
            thumbnail_url: "/assets/2023/nominees/k3soju.jpg",
            clip_url:
              "https://clips.twitch.tv/BreakableResourcefulFriesPJSugar-zp0FUmeC2hisz5Nz",
            url: "https://www.twitch.tv/k3soju",
          },
        },
        {
          nominee_id: 181,
          year: 2023,
          name: "BoxBox",
          details: {
            thumbnail_url: "/assets/2023/nominees/BoxBox.jpg",
            clip_url:
              "https://clips.twitch.tv/SolidSmellyTardigradeShadyLulu-JZY_AIpOrccMFFmf",
            url: "https://www.twitch.tv/boxbox",
          },
        },
        {
          nominee_id: 209,
          year: 2023,
          name: "Scarra",
          details: {
            thumbnail_url: "/assets/2023/nominees/Scarra.jpg",
            clip_url:
              "https://www.twitch.tv/scarra/clip/SourAggressiveStarAliens",
            url: "https://www.twitch.tv/scarra",
          },
        },
        {
          nominee_id: 171,
          year: 2023,
          name: "Dkayed",
          details: {
            thumbnail_url: "/assets/2023/nominees/Dkayed.jpg",
            clip_url:
              "https://clips.twitch.tv/FancyPlausibleAirGuitarStrawBeary-ggxoJuwmRitjWK3-",
            url: "https://www.twitch.tv/dkayed",
          },
        },
        {
          nominee_id: 198,
          year: 2023,
          name: "Gorgc",
          details: {
            thumbnail_url: "/assets/2023/nominees/Gorgc.jpg",
            clip_url:
              "https://clips.twitch.tv/AttractiveBitterTildePeanutButterJellyTime-aen1g5tn2jVPhG1_",
            url: "https://www.twitch.tv/gorgc",
          },
        },
      ],
      winner: {
        nominee_id: 181,
        year: 2023,
        name: "BoxBox",
        details: {
          thumbnail_url: "/assets/2023/nominees/BoxBox.jpg",
          clip_url:
            "https://clips.twitch.tv/SolidSmellyTardigradeShadyLulu-JZY_AIpOrccMFFmf",
          url: "https://www.twitch.tv/boxbox",
        },
      },
    },
    {
      id: 39,
      name: "Best Chess Streamer",
      details: {
        description: "Best streamer to watch for Chess content.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 138,
          year: 2023,
          name: "BotezLive",
          details: {
            thumbnail_url: "/assets/2023/nominees/BotezLive.jpg",
            clip_url:
              "https://clips.twitch.tv/PerfectMotionlessTigerBleedPurple-84JG9I3Evv_0qF9x",
            url: "https://www.twitch.tv/botezlive",
          },
        },
        {
          nominee_id: 157,
          year: 2023,
          name: "AnnaCramling",
          details: {
            thumbnail_url: "/assets/2023/nominees/AnnaCramling.jpg",
            clip_url:
              "https://clips.twitch.tv/GiantBoldLemurBudBlast-zCFTzUNy9LLfw0si",
            url: "https://www.twitch.tv/annacramling",
          },
        },
        {
          nominee_id: 180,
          year: 2023,
          name: "GMHikaru",
          details: {
            thumbnail_url: "/assets/2023/nominees/GMHikaru.jpg",
            clip_url:
              "https://clips.twitch.tv/AssiduousSillyDiamondDoritosChip-iSWTRsuHH69ClNTh",
            url: "https://www.twitch.tv/gmhikaru",
          },
        },
        {
          nominee_id: 179,
          year: 2023,
          name: "GothamChess",
          details: {
            thumbnail_url: "/assets/2023/nominees/GothamChess.jpg",
            clip_url: "https://youtu.be/Na5mbv500aA?t=30",
            url: "https://www.twitch.tv/gothamchess",
          },
        },
      ],
      winner: {
        nominee_id: 179,
        year: 2023,
        name: "GothamChess",
        details: {
          thumbnail_url: "/assets/2023/nominees/GothamChess.jpg",
          clip_url: "https://youtu.be/Na5mbv500aA?t=30",
          url: "https://www.twitch.tv/gothamchess",
        },
      },
    },
    {
      id: 33,
      name: "Best League of Legends Streamer",
      details: {
        description:
          "Streamer that provides the best content while streaming League of Legends.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 144,
          year: 2023,
          name: "Thebausffs",
          details: {
            thumbnail_url: "/assets/2023/nominees/Thebausffs.jpg",
            clip_url:
              "https://www.twitch.tv/thebausffs/clip/KawaiiCharmingCaribouBloodTrail-pwjPlYrETAu87EIF",
            url: "https://www.twitch.tv/thebausffs",
          },
        },
        {
          nominee_id: 149,
          year: 2023,
          name: "Caedrel",
          details: {
            thumbnail_url: "/assets/2023/nominees/Caedrel.jpg",
            clip_url:
              "https://clips.twitch.tv/SmellySingleJamMau5-dLfFwlFdZy5tZZEI",
            url: "https://www.twitch.tv/caedrel",
          },
        },
        {
          nominee_id: 148,
          year: 2023,
          name: "loltyler1",
          details: {
            thumbnail_url: "/assets/2023/nominees/loltyler1.jpg",
            clip_url:
              "https://clips.twitch.tv/HonestEnticingParrotNononoCat-PlnB4JlQAunypNR4",
            url: "https://www.twitch.tv/loltyler1",
          },
        },
        {
          nominee_id: 132,
          year: 2023,
          name: "Dantes",
          details: {
            thumbnail_url: "/assets/2023/nominees/Dantes.jpg",
            clip_url:
              "https://clips.twitch.tv/CheerfulExcitedRuffChefFrank-27Wzq9UqUjOLYAM2",
            url: "https://www.twitch.tv/dantes",
          },
        },
      ],
      winner: {
        nominee_id: 148,
        year: 2023,
        name: "loltyler1",
        details: {
          thumbnail_url: "/assets/2023/nominees/loltyler1.jpg",
          clip_url:
            "https://clips.twitch.tv/HonestEnticingParrotNononoCat-PlnB4JlQAunypNR4",
          url: "https://www.twitch.tv/loltyler1",
        },
      },
    },
    {
      id: 30,
      name: "Best Battle Royale Streamer",
      details: {
        description:
          "Best streamer to watch playing battle royale games like Fortnite, Apex Legends, PUBG, Call of Duty: Warzone etc.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 175,
          year: 2023,
          name: "iiTzTimmy",
          details: {
            thumbnail_url: "/assets/2023/nominees/iiTzTimmy.jpg",
            clip_url:
              "https://clips.twitch.tv/TenaciousFunnyWaspKeepo-wYjv4YsAOY0_AThZ",
            url: "https://www.twitch.tv/iitztimmy",
          },
        },
        {
          nominee_id: 128,
          year: 2023,
          name: "Apricot",
          details: {
            thumbnail_url: "/assets/2023/nominees/Apricot.jpg",
            clip_url:
              "https://clips.twitch.tv/ZealousTrappedDogSoBayed-xaKxNG8bc5vprlOt",
            url: "https://www.twitch.tv/apricot",
          },
        },
        {
          nominee_id: 160,
          year: 2023,
          name: "Symfuhny",
          details: {
            thumbnail_url: "/assets/2023/nominees/Symfuhny.jpg",
            clip_url:
              "https://clips.twitch.tv/AgitatedHotHamsterRitzMitz-K7VGQRM01fjU-0DY",
            url: "https://www.twitch.tv/symfuhny",
          },
        },
        {
          nominee_id: 159,
          year: 2023,
          name: "Nadia",
          details: {
            thumbnail_url: "/assets/2023/nominees/Nadia.jpg",
            clip_url:
              "https://clips.twitch.tv/IgnorantPowerfulTardigradeStinkyCheese-2gpFpVSd_K8JuE5h",
            url: "https://www.twitch.tv/nadia",
          },
        },
        {
          nominee_id: 127,
          year: 2023,
          name: "Clix",
          details: {
            thumbnail_url: "/assets/2023/nominees/Clix.jpg",
            clip_url:
              "https://clips.twitch.tv/RespectfulDarlingOrangeWOOP-YP5SKjEHAlFAwqUq",
            url: "https://www.twitch.tv/clix",
          },
        },
      ],
      winner: {
        nominee_id: 175,
        year: 2023,
        name: "iiTzTimmy",
        details: {
          thumbnail_url: "/assets/2023/nominees/iiTzTimmy.jpg",
          clip_url:
            "https://clips.twitch.tv/TenaciousFunnyWaspKeepo-wYjv4YsAOY0_AThZ",
          url: "https://www.twitch.tv/iitztimmy",
        },
      },
    },
    {
      id: 40,
      name: "Best Music Streamer",
      details: {
        description: "Streamer that produces the best music content.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 192,
          year: 2023,
          name: "Hakumai",
          details: {
            thumbnail_url: "/assets/2023/nominees/Hakumai.jpg",
            clip_url: "https://youtu.be/2lsAleSY5r4",
            url: "https://www.twitch.tv/hakumai",
          },
        },
        {
          nominee_id: 156,
          year: 2023,
          name: "TPAIN",
          details: {
            thumbnail_url: "/assets/2023/nominees/TPAIN.jpg",
            clip_url:
              "https://clips.twitch.tv/SneakyBrightPterodactylBudBlast-7uQprqmzpsRsfhEB",
            url: "https://www.twitch.tv/tpain",
          },
        },
        {
          nominee_id: 163,
          year: 2023,
          name: "The8BitDrummer",
          details: {
            thumbnail_url: "/assets/2023/nominees/The8BitDrummer.jpg",
            clip_url:
              "https://clips.twitch.tv/ExpensiveWonderfulVultureRitzMitz-z6NxbsPeVR5Bm-Vv",
            url: "https://www.twitch.tv/the8bitdrummer",
          },
        },
        {
          nominee_id: 191,
          year: 2023,
          name: "Chrisnxtdoor",
          details: {
            thumbnail_url: "/assets/2023/nominees/Chrisnxtdoor.jpg",
            clip_url:
              "https://clips.twitch.tv/FantasticArborealHorseradishOMGScoots-RYxEZR0RertdX_dx",
            url: "https://www.twitch.tv/chrisnxtdoor",
          },
        },
      ],
      winner: {
        nominee_id: 156,
        year: 2023,
        name: "TPAIN",
        details: {
          thumbnail_url: "/assets/2023/nominees/TPAIN.jpg",
          clip_url:
            "https://clips.twitch.tv/SneakyBrightPterodactylBudBlast-7uQprqmzpsRsfhEB",
          url: "https://www.twitch.tv/tpain",
        },
      },
    },
    {
      id: 36,
      name: "Best FPS Streamer",
      details: {
        description:
          "Most enjoyable streamer to watch playing first-person shooter (FPS) games like Overwatch, Call of Duty, Halo, and Counter Strike: Global Offensive, to name a few.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 208,
          year: 2023,
          name: "supertf",
          details: {
            thumbnail_url: "/assets/2023/nominees/supertf.jpg",
            clip_url:
              "https://clips.twitch.tv/DarlingSmallBeaverDancingBaby-bQ4xCvfTVngKFpcn",
            url: "https://www.twitch.tv/supertf",
          },
        },
        {
          nominee_id: 182,
          year: 2023,
          name: "Scump",
          details: {
            thumbnail_url: "/assets/2023/nominees/Scump.jpg",
            clip_url:
              "https://clips.twitch.tv/ArtisticAbstrusePastaANELE-QLKjtshadpTgGwxp",
            url: "https://www.twitch.tv/scump",
          },
        },
        {
          nominee_id: 170,
          year: 2023,
          name: "summit1g",
          details: {
            thumbnail_url: "/assets/2023/nominees/summit1g.jpg",
            clip_url:
              "https://clips.twitch.tv/ManlyMildPlumberTakeNRG-zQDytUMGnbuWFRq_",
            url: "https://www.twitch.tv/summit1g",
          },
        },
        {
          nominee_id: 151,
          year: 2023,
          name: "aceu",
          details: {
            thumbnail_url: "/assets/2023/nominees/Aceu.jpg",
            clip_url:
              "https://clips.twitch.tv/FitDeterminedPartridgeStrawBeary-bz4BDlghHbTpl-ah",
            url: "https://www.twitch.tv/aceu",
          },
        },
        {
          nominee_id: 190,
          year: 2023,
          name: "Flats",
          details: {
            thumbnail_url: "/assets/2023/nominees/Flats.jpg",
            clip_url:
              "https://www.twitch.tv/flats/clip/LachrymoseCleanJaguarOneHand-5nPR98xmgpmDxA8d",
            url: "https://www.twitch.tv/flats",
          },
        },
      ],
      winner: {
        nominee_id: 151,
        year: 2023,
        name: "aceu",
        details: {
          thumbnail_url: "/assets/2023/nominees/Aceu.jpg",
          clip_url:
            "https://clips.twitch.tv/FitDeterminedPartridgeStrawBeary-bz4BDlghHbTpl-ah",
          url: "https://www.twitch.tv/aceu",
        },
      },
    },
    {
      id: 43,
      name: "Best Speedrun Streamer",
      details: {
        description:
          "Streamer that creates the best content while speedrunning games.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 140,
          year: 2023,
          name: "Squeex",
          details: {
            thumbnail_url: "/assets/2023/nominees/Squeex.jpg",
            clip_url:
              "https://clips.twitch.tv/RelatedSpotlessBubbleteaBleedPurple-Cc1rwCDRDeG2Fz1c",
            url: "https://www.twitch.tv/squeex",
          },
        },
        {
          nominee_id: 177,
          year: 2023,
          name: "Wirtual",
          details: {
            thumbnail_url: "/assets/2023/nominees/Wirtual.jpg",
            clip_url:
              "https://www.twitch.tv/wirtual/clip/NimbleDeadNoodleBleedPurple-DmR0r1_uWCzRi6wg",
            url: "https://www.twitch.tv/wirtual",
          },
        },
        {
          nominee_id: 212,
          year: 2023,
          name: "Simply",
          details: {
            thumbnail_url: "/assets/2023/nominees/Simply.jpg",
            clip_url:
              "https://clips.twitch.tv/HyperArtsyBaboonPeanutButterJellyTime-zpcoiL6WP4padUH2",
            url: "https://www.twitch.tv/simply",
          },
        },
        {
          nominee_id: 168,
          year: 2023,
          name: "PointCrow",
          details: {
            thumbnail_url: "/assets/2023/nominees/Pointcrow.jpg",
            clip_url:
              "https://clips.twitch.tv/YawningSincereMooseNomNom-oI6j3JkYFM7EsKDQ",
            url: "https://www.twitch.tv/pointcrow ",
          },
        },
      ],
      winner: {
        nominee_id: 168,
        year: 2023,
        name: "PointCrow",
        details: {
          thumbnail_url: "/assets/2023/nominees/Pointcrow.jpg",
          clip_url:
            "https://clips.twitch.tv/YawningSincereMooseNomNom-oI6j3JkYFM7EsKDQ",
          url: "https://www.twitch.tv/pointcrow ",
        },
      },
    },
    {
      id: 45,
      name: "Hidden Gem Award",
      details: {
        description:
          "This award serves to highlight a streamer with less than 100 average viewers during 2022. They are a hidden gem that deserves to shine.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 165,
          year: 2023,
          name: "HelloNeptune",
          details: {
            thumbnail_url: "/assets/2023/nominees/HelloNeptune.jpg",
            clip_url:
              "https://clips.twitch.tv/MushyWimpyWeaselLeeroyJenkins-fFrJwnYKCiuipBBM",
            url: "https://www.twitch.tv/helloneptune",
          },
        },
        {
          nominee_id: 131,
          year: 2023,
          name: "Avghans",
          details: {
            thumbnail_url: "/assets/2023/nominees/Avghans.jpg",
            clip_url:
              "https://clips.twitch.tv/DreamyBreakableSalmonWholeWheat-b4WJ0oTq3jQ1W7jh",
            url: "https://www.twitch.tv/avghans",
          },
        },
        {
          nominee_id: 130,
          year: 2023,
          name: "KingSammelot",
          details: {
            thumbnail_url: "/assets/2023/nominees/KingSammelot.jpg",
            clip_url:
              "https://clips.twitch.tv/SpotlessCuteBubbleteaTinyFace-DnLN_PSIjeVaF5ly",
            url: "https://www.twitch.tv/kingsammelot",
          },
        },
        {
          nominee_id: 184,
          year: 2023,
          name: "Nixolay",
          details: {
            thumbnail_url: "/assets/2023/nominees/Nixolay.jpg",
            clip_url:
              "https://clips.twitch.tv/ViscousImpossibleOxPeteZarollTie-qT0Gp7rozKCZg_vE",
            url: "https://www.twitch.tv/nixolay",
          },
        },
      ],
      winner: {
        nominee_id: 130,
        year: 2023,
        name: "KingSammelot",
        details: {
          thumbnail_url: "/assets/2023/nominees/KingSammelot.jpg",
          clip_url:
            "https://clips.twitch.tv/SpotlessCuteBubbleteaTinyFace-DnLN_PSIjeVaF5ly",
          url: "https://www.twitch.tv/kingsammelot",
        },
      },
    },
    {
      id: 44,
      name: "Best IRL Streamer",
      details: {
        description:
          "Streamer that creates the best In Real Life (IRL) content such as vlogging, travelling, cooking, visiting places etc.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 174,
          year: 2023,
          name: "fanfan",
          details: {
            thumbnail_url: "/assets/2023/nominees/fanfan.jpg",
            clip_url:
              "https://clips.twitch.tv/BigAlertSpiderOSfrog-o1o0vHTiydL9FwgL",
            url: "https://www.twitch.tv/fanfan",
          },
        },
        {
          nominee_id: 129,
          year: 2023,
          name: "JoeyKaotyk",
          details: {
            thumbnail_url: "/assets/2023/nominees/JoeyKaotyk.jpg",
            clip_url: "https://www.twitch.tv/videos/707726090",
            url: "https://www.twitch.tv/joeykaotyk",
          },
        },
        {
          nominee_id: 166,
          year: 2023,
          name: "jakenbakeLIVE",
          details: {
            thumbnail_url: "/assets/2023/nominees/jakenbakeLIVE.jpg",
            clip_url:
              "https://clips.twitch.tv/EnticingMistyWrenchDancingBanana-y3E63TjedreM5rUG",
            url: "https://www.twitch.tv/jakenbakelive",
          },
        },
        {
          nominee_id: 183,
          year: 2023,
          name: "Jinnytty",
          details: {
            thumbnail_url: "/assets/2023/nominees/Jinnytty.jpg",
            clip_url:
              "https://www.twitch.tv/Jinnytty/clip/ResilientRepleteOkapiAliens-sr6zZ7WoS7qLAwlI",
            url: "https://www.twitch.tv/jinnytty",
          },
        },
        {
          nominee_id: 195,
          year: 2023,
          name: "ExtraEmily",
          details: {
            thumbnail_url: "/assets/2023/nominees/ExtraEmily.jpg",
            clip_url:
              "https://clips.twitch.tv/TangentialAltruisticEchidnaPMSTwin-cfW8CqVVrXmmhEea",
            url: "https://www.twitch.tv/extraemily",
          },
        },
      ],
      winner: {
        nominee_id: 166,
        year: 2023,
        name: "jakenbakeLIVE",
        details: {
          thumbnail_url: "/assets/2023/nominees/jakenbakeLIVE.jpg",
          clip_url:
            "https://clips.twitch.tv/EnticingMistyWrenchDancingBanana-y3E63TjedreM5rUG",
          url: "https://www.twitch.tv/jakenbakelive",
        },
      },
    },
    {
      id: 35,
      name: "Best RolePlay Streamer",
      details: {
        description:
          "Streamer with the best role-play (RP) content in games like GTA RP, Rust, VRChat, Ark, Dungeons & Dragons etc.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 187,
          year: 2023,
          name: "Fuslie",
          details: {
            thumbnail_url: "/assets/2023/nominees/Fuslie.jpg",
            clip_url: "https://www.youtube.com/watch?v=XFKCwAXi4FY&t=8&end=43",
            url: "https://www.youtube.com/@fuslie",
          },
        },
        {
          nominee_id: 137,
          year: 2023,
          name: "Buddha",
          details: {
            thumbnail_url: "/assets/2023/nominees/Buddha.jpg",
            clip_url:
              "https://clips.twitch.tv/DeliciousOilyCheetahKappaWealth-fbA2Vm6QWCqOqrLe",
            url: "https://www.twitch.tv/buddha",
          },
        },
        {
          nominee_id: 172,
          year: 2023,
          name: "Fanum",
          details: {
            thumbnail_url: "/assets/2023/nominees/Fanum.jpg",
            clip_url:
              "https://clips.twitch.tv/CredulousBoldWheelTBCheesePull-tiLRdeKOf99QJ_5F",
            url: "https://www.twitch.tv/fanum",
          },
        },
        {
          nominee_id: 173,
          year: 2023,
          name: "Zerkaa",
          details: {
            thumbnail_url: "/assets/2023/nominees/Zerkaa.jpg",
            clip_url:
              "https://clips.twitch.tv/ArborealGlamorousApeYouWHY-EnB2zsmLPVPRIkR4",
            url: "https://www.twitch.tv/zerkaa",
          },
        },
      ],
      winner: {
        nominee_id: 172,
        year: 2023,
        name: "Fanum",
        details: {
          thumbnail_url: "/assets/2023/nominees/Fanum.jpg",
          clip_url:
            "https://clips.twitch.tv/CredulousBoldWheelTBCheesePull-tiLRdeKOf99QJ_5F",
          url: "https://www.twitch.tv/fanum",
        },
      },
    },
    {
      id: 32,
      name: "Best VALORANT Streamer",
      details: {
        description: "Streamer that creates the best VALORANT content.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 141,
          year: 2023,
          name: "Tarik",
          details: {
            thumbnail_url: "/assets/2023/nominees/Tarik.jpg",
            clip_url: "https://www.youtube.com/watch?v=E6J9wIsS6yc",
            url: "https://www.twitch.tv/tarik",
          },
        },
        {
          nominee_id: 133,
          year: 2023,
          name: "QuarterJade",
          details: {
            thumbnail_url: "/assets/2023/nominees/QuarterJade.jpg",
            clip_url:
              "https://clips.twitch.tv/CrepuscularOriginalTurnipMau5-sLH46KvvC92VvRwX?tt_content=url&tt_medium=clips_api",
            url: "https://www.twitch.tv/quarterjade",
          },
        },
        {
          nominee_id: 147,
          year: 2023,
          name: "ShahZaM",
          details: {
            thumbnail_url: "/assets/2023/nominees/ShahZaM.jpg",
            clip_url:
              "https://clips.twitch.tv/FrigidCalmZucchiniSmoocherZ--DG5CdgDg1G6FdEW?tt_content=url&tt_medium=clips_api",
            url: "https://www.twitch.tv/shahzam",
          },
        },
        {
          nominee_id: 146,
          year: 2023,
          name: "Kyedae",
          details: {
            thumbnail_url: "/assets/2023/nominees/Kyedae.jpg",
            clip_url:
              "https://clips.twitch.tv/BombasticFunnyDiscDBstyle-AMfYwm1LZnoGfNNf?tt_content=url&tt_medium=clips_api",
            url: "https://www.twitch.tv/kyedae",
          },
        },
      ],
      winner: {
        nominee_id: 146,
        year: 2023,
        name: "Kyedae",
        details: {
          thumbnail_url: "/assets/2023/nominees/Kyedae.jpg",
          clip_url:
            "https://clips.twitch.tv/BombasticFunnyDiscDBstyle-AMfYwm1LZnoGfNNf?tt_content=url&tt_medium=clips_api",
          url: "https://www.twitch.tv/kyedae",
        },
      },
    },
    {
      id: 41,
      name: "Best Minecraft Streamer",
      details: {
        description: "Most enjoyable streamer to watch for Minecraft content.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 153,
          year: 2023,
          name: "Tubbo",
          details: {
            thumbnail_url: "/assets/2023/nominees/Tubbo.jpg",
            clip_url:
              "https://clips.twitch.tv/BillowingRefinedSandwichCoolCat-n9nRSwW2QAc7t3gy",
            url: "https://www.twitch.tv/tubbo",
          },
        },
        {
          nominee_id: 162,
          year: 2023,
          name: "Quackity",
          details: {
            thumbnail_url: "/assets/2023/nominees/Quackity.jpg",
            clip_url:
              "https://clips.twitch.tv/LittleImpartialFriesBCWarrior-unyH2jXAN2fYWc3M",
            url: "https://www.twitch.tv/quackity",
          },
        },
        {
          nominee_id: 178,
          year: 2023,
          name: "Foolish_Gamers",
          details: {
            thumbnail_url: "/assets/2023/nominees/Foolish_Gamers.jpg",
            clip_url:
              "https://clips.twitch.tv/KawaiiUglyCatKappa-erkbamnKS7W6Hi7t",
            url: "https://www.twitch.tv/foolish_gamers",
          },
        },
        {
          nominee_id: 210,
          year: 2023,
          name: "SmallAnt",
          details: {
            thumbnail_url: "/assets/2023/nominees/SmallAnt.jpg",
            clip_url:
              "https://clips.twitch.tv/QuaintSweetSashimiDxAbomb-bybIisLSrzU-vVjH",
            url: "https://www.twitch.tv/smallant",
          },
        },
      ],
      winner: {
        nominee_id: 162,
        year: 2023,
        name: "Quackity",
        details: {
          thumbnail_url: "/assets/2023/nominees/Quackity.jpg",
          clip_url:
            "https://clips.twitch.tv/LittleImpartialFriesBCWarrior-unyH2jXAN2fYWc3M",
          url: "https://www.twitch.tv/quackity",
        },
      },
    },
    {
      id: 52,
      name: "Rising Star Award ",
      details: {
        description:
          "Up-and-coming streamer that has grown tremendously over the past year and is on the path to becoming a top streamer.  Note that to be eligible, the streamer must  have had less than 1,000 average concurrent viewers in 2022.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 196,
          year: 2023,
          name: "Frogan",
          details: {
            thumbnail_url: "/assets/2023/nominees/Frogan.jpg",
            clip_url:
              "https://clips.twitch.tv/PeacefulTangibleStrawberryBleedPurple-CnPw7ffRlcbkTy3s",
            url: "https://www.twitch.tv/frogan",
          },
        },
        {
          nominee_id: 167,
          year: 2023,
          name: "Alluux",
          details: {
            thumbnail_url: "/assets/2023/nominees/Alluux.jpg",
            clip_url:
              "https://www.twitch.tv/alluux/clip/SuspiciousGentleCockroachCorgiDerp-ygYscU3mCyz6LHv2",
            url: "https://www.twitch.tv/alluux",
          },
        },
        {
          nominee_id: 199,
          year: 2023,
          name: "Keeoh",
          details: {
            thumbnail_url: "/assets/2023/nominees/Keeoh.jpg",
            clip_url:
              "https://www.twitch.tv/keeoh/clip/ImpossibleJoyousWebSoonerLater-Pss_S0sHN5d0gou5",
            url: "https://www.twitch.tv/keeoh",
          },
        },
        {
          nominee_id: 142,
          year: 2023,
          name: "PrinceZamLIVE",
          details: {
            thumbnail_url: "/assets/2023/nominees/PrinceZamLIVE.jpg",
            clip_url:
              "https://clips.twitch.tv/AdorableShakingPheasantKevinTurtle-lYd6SCZoVLYcoE_I",
            url: "https://www.twitch.tv/princezamlive",
          },
        },
      ],
      winner: {
        nominee_id: 196,
        year: 2023,
        name: "Frogan",
        details: {
          thumbnail_url: "/assets/2023/nominees/Frogan.jpg",
          clip_url:
            "https://clips.twitch.tv/PeacefulTangibleStrawberryBleedPurple-CnPw7ffRlcbkTy3s",
          url: "https://www.twitch.tv/frogan",
        },
      },
    },
    {
      id: 47,
      name: "Stream Game of the Year",
      details: {
        description:
          "The best game to watch on stream during the past year, because it was very entertaining, novel, fun, interesting, etc.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 7,
          year: 2023,
          name: "Minecraft",
          details: {
            thumbnail_url: "/assets/2023/categories/Minecraft.jpg",
            clip_url:
              "https://clips.twitch.tv/NimblePatientTigerKevinTurtle-V7ZWdxwUsrAxX7A4",
            url: "https://www.minecraft.net/",
          },
        },
        {
          nominee_id: 11,
          year: 2023,
          name: "Elden Ring",
          details: {
            thumbnail_url: "/assets/2023/categories/Elden Ring.jpg",
            clip_url:
              "https://clips.twitch.tv/RoundEnticingCoyoteBudStar-93U2hvEstIcyZuHi",
            url: "https://en.bandainamcoent.eu/elden-ring/elden-ring",
          },
        },
        {
          nominee_id: 197,
          year: 2023,
          name: "God of War Ragnarök",
          details: {
            thumbnail_url: "/assets/2023/nominees/God of War.jpg",
            clip_url:
              "https://clips.twitch.tv/BadBoxyLettuceKappa-KdsTEWpjaE8EpgqS?tt_content=url&tt_medium=clips_api",
            url: "https://www.playstation.com/en-us/games/god-of-war-ragnarok/",
          },
        },
        {
          nominee_id: 3,
          year: 2023,
          name: "VALORANT",
          details: {
            thumbnail_url: "/assets/2023/categories/VALORANT.jpg",
            clip_url:
              "https://clips.twitch.tv/BoxyHandsomeChimpanzeeDerp-2Fqw_hyfoSoJ1x5u",
            url: "https://playvalorant.com/",
          },
        },
      ],
      winner: {
        nominee_id: 11,
        year: 2023,
        name: "Elden Ring",
        details: {
          thumbnail_url: "/assets/2023/categories/Elden Ring.jpg",
          clip_url:
            "https://clips.twitch.tv/RoundEnticingCoyoteBudStar-93U2hvEstIcyZuHi",
          url: "https://en.bandainamcoent.eu/elden-ring/elden-ring",
        },
      },
    },
    {
      id: 51,
      name: "Best Content Organization",
      details: {
        description:
          "Esports or content organization that has created the best content during the past year. Note: this category is not subject to the hours streamed requirements.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 204,
          year: 2023,
          name: "OfflineTV",
          details: {
            thumbnail_url: "/assets/2023/nominees/OfflineTV.jpg",
            clip_url:
              "https://clips.twitch.tv/ConcernedCuteScallionBabyRage-60LoIMl47xV-Ipy3",
            url: "https://offlinetv.com/",
          },
        },
        {
          nominee_id: 207,
          year: 2023,
          name: "VShojo",
          details: {
            thumbnail_url: "/assets/2023/nominees/VShojo.jpg",
            clip_url:
              "https://clips.twitch.tv/ArtisticVictoriousClipzPogChamp-PenXy97hnzw2gLOv",
            url: "https://www.vshojo.com/",
          },
        },
        {
          nominee_id: 205,
          year: 2023,
          name: "OTK Network",
          details: {
            thumbnail_url: "/assets/2023/nominees/OTK Network.jpg",
            clip_url:
              "https://clips.twitch.tv/LovelyTangibleBorkM4xHeh-8gw5e5kuLtmD-ldX?tt_content=url&tt_medium=clips_api",
            url: "https://otknetwork.com/",
          },
        },
        {
          nominee_id: 206,
          year: 2023,
          name: "100 Thieves",
          details: {
            thumbnail_url: "/assets/2023/nominees/100 Thieves.jpg",
            clip_url:
              "https://www.youtube.com/watch?v=PA2-2rxvFZA&t=11079s&ab_channel=CallofDuty",
            url: "https://100thieves.com/",
          },
        },
      ],
      winner: {
        nominee_id: 204,
        year: 2023,
        name: "OfflineTV",
        details: {
          thumbnail_url: "/assets/2023/nominees/OfflineTV.jpg",
          clip_url:
            "https://clips.twitch.tv/ConcernedCuteScallionBabyRage-60LoIMl47xV-Ipy3",
          url: "https://offlinetv.com/",
        },
      },
    },
    {
      id: 46,
      name: "Best Philanthropic Stream Event",
      details: {
        description:
          "The best event streamed for philanthropy- such as raising money for charity, spreading awareness and other such initiatives.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 203,
          year: 2023,
          name: "Holiday Charity Week - Roomies",
          details: {
            thumbnail_url:
              "/assets/2023/nominees/Roomies Holiday Charity Week.jpg",
            clip_url:
              "https://www.youtube.com/watch?v=47Frpj0gLDA&t=7821s&ab_channel=Sykkuno",
            url: "https://www.youtube.com/playlist?list=PLfpWQ3hEiloP-dhWC5hmPVXR5bumFZgeq",
          },
        },
        {
          nominee_id: 201,
          year: 2023,
          name: "Build Against Cancer - DrLupo",
          details: {
            thumbnail_url: "/assets/2023/nominees/Build Against Cancer.jpg",
            clip_url:
              "https://www.youtube.com/live/MHwaP3AlUl4?feature=share&t=42385",
            url: "https://www.youtube.com/watch?v=MHwaP3AlUl4&ab_channel=DrLupo",
          },
        },
        {
          nominee_id: 213,
          year: 2023,
          name: "Thankmas - Jacksepticeye",
          details: {
            thumbnail_url: "/assets/2023/nominees/Thankmas.jpg",
            clip_url: "https://www.youtube.com/watch?v=MeVupLko4GA",
            url: "https://www.youtube.com/watch?v=gQDX-sf89p4&ab_channel=jacksepticeye",
          },
        },
        {
          nominee_id: 202,
          year: 2023,
          name: "500 mile Cyclethon - CDawgVA ",
          details: {
            thumbnail_url:
              "/assets/2023/nominees/Connor's 500 mile Cyclethon.jpg",
            clip_url:
              "https://www.twitch.tv/cdawgva/clip/HonestFurryHorseradishThisIsSparta-CVrql2h7nWBRufzt",
            url: "https://www.youtube.com/watch?v=omcbPZL3ur4",
          },
        },
      ],
      winner: {
        nominee_id: 202,
        year: 2023,
        name: "500 mile Cyclethon - CDawgVA ",
        details: {
          thumbnail_url:
            "/assets/2023/nominees/Connor's 500 mile Cyclethon.jpg",
          clip_url:
            "https://www.twitch.tv/cdawgva/clip/HonestFurryHorseradishThisIsSparta-CVrql2h7nWBRufzt",
          url: "https://www.youtube.com/watch?v=omcbPZL3ur4",
        },
      },
    },
    {
      id: 38,
      name: "Best VTuber",
      details: {
        description:
          "Best streamer who uses a virtual avatar instead of a traditional face-cam, and creates the most entertaining content.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 152,
          year: 2023,
          name: "shxtou",
          details: {
            thumbnail_url: "/assets/2023/nominees/Shxtou.jpg",
            clip_url: "https://www.youtube.com/watch?v=9qqHRQGvxWM",
            url: "https://www.twitch.tv/shxtou",
          },
        },
        {
          nominee_id: 189,
          year: 2023,
          name: "Shylily",
          details: {
            thumbnail_url: "/assets/2023/nominees/Shylily.jpg",
            clip_url:
              "https://www.twitch.tv/shylily/clip/ImpossibleGracefulPidgeonAsianGlow-9SwmsLaukwvZ4mpw",
            url: "https://www.twitch.tv/shylily",
          },
        },
        {
          nominee_id: 164,
          year: 2023,
          name: "Veibae",
          details: {
            thumbnail_url: "/assets/2023/nominees/Veibae.jpg",
            clip_url:
              "https://clips.twitch.tv/HonestBelovedWoodpeckerCorgiDerp-ejZpE0qb-fQA7s-Q",
            url: "https://www.twitch.tv/veibae",
          },
        },
        {
          nominee_id: 188,
          year: 2023,
          name: "Ironmouse",
          details: {
            thumbnail_url: "/assets/2023/nominees/Ironmouse.jpg",
            clip_url:
              "https://www.twitch.tv/ironmouse/clip/ObliviousStupidStinkbugKeepo-UyZDkpRVGiO5iZls",
            url: "https://www.twitch.tv/ironmouse",
          },
        },
      ],
      winner: {
        nominee_id: 188,
        year: 2023,
        name: "Ironmouse",
        details: {
          thumbnail_url: "/assets/2023/nominees/Ironmouse.jpg",
          clip_url:
            "https://www.twitch.tv/ironmouse/clip/ObliviousStupidStinkbugKeepo-UyZDkpRVGiO5iZls",
          url: "https://www.twitch.tv/ironmouse",
        },
      },
    },
    {
      id: 50,
      name: "Best Streamed Event",
      details: {
        description:
          "The most entertaining, interesting or enjoyable event streamed during the past year.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 221,
          year: 2023,
          name: "Jerma Baseball Stream",
          details: {
            thumbnail_url: "/assets/2023/nominees/Jerma Baseball Stream.jpg",
            clip_url:
              "https://clips.twitch.tv/SplendidSlickPicklesTooSpicy-G2pJ9n_NeyxcXap_",
            url: "https://www.youtube.com/watch?v=iquAwcSJe7o&ab_channel=2ndJerma",
          },
        },
        {
          nominee_id: 218,
          year: 2023,
          name: "Mogul Chessboxing Championship",
          details: {
            thumbnail_url:
              "/assets/2023/nominees/Mogul Chessboxing Championship.jpg",
            clip_url: "https://www.youtube.com/watch?v=UMdpvDMYNGI",
            url: "https://www.youtube.com/watch?v=tg9CH4KlKMc",
          },
        },
        {
          nominee_id: 219,
          year: 2023,
          name: "PointCrow Party",
          details: {
            thumbnail_url: "/assets/2023/nominees/PointCrow Party.jpg",
            clip_url:
              "https://www.youtube.com/watch?v=gTWCwGid_rs&t=8242s&end=8263s",
            url: "https://www.youtube.com/watch?v=IJQz5ChvOKU",
          },
        },
        {
          nominee_id: 220,
          year: 2023,
          name: "Camp Knut",
          details: {
            thumbnail_url: "/assets/2023/nominees/Camp Knut.jpg",
            clip_url:
              "https://clips.twitch.tv/DullPrettyKangarooRaccAttack-86vWu5vHoAxbk9X9",
            url: "https://www.youtube.com/playlist?list=PLdota7s-x18pEP-_EX0jRirZAMtAVUcax",
          },
        },
      ],
      winner: {
        nominee_id: 218,
        year: 2023,
        name: "Mogul Chessboxing Championship",
        details: {
          thumbnail_url:
            "/assets/2023/nominees/Mogul Chessboxing Championship.jpg",
          clip_url: "https://www.youtube.com/watch?v=UMdpvDMYNGI",
          url: "https://www.youtube.com/watch?v=tg9CH4KlKMc",
        },
      },
    },
    {
      id: 49,
      name: "Best Just Chatting Streamer",
      details: {
        description:
          "Streamer that regularly does Just Chatting content such as conversing with chat, reacting to news and videos, telling stories, hanging out with friends on stream and the like.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 215,
          year: 2023,
          name: "PaymoneyWubby",
          details: {
            thumbnail_url: "/assets/2023/nominees/PaymoneyWubby.jpg",
            clip_url:
              "https://clips.twitch.tv/AuspiciousFilthySpaghettiPermaSmug-3mfCzOpSO6jZd4EQ",
            url: "https://www.twitch.tv/paymoneywubby",
          },
        },
        {
          nominee_id: 188,
          year: 2023,
          name: "Ironmouse",
          details: {
            thumbnail_url: "/assets/2023/nominees/Ironmouse.jpg",
            clip_url:
              "https://www.twitch.tv/ironmouse/clip/ObliviousStupidStinkbugKeepo-UyZDkpRVGiO5iZls",
            url: "https://www.twitch.tv/ironmouse",
          },
        },
        {
          nominee_id: 216,
          year: 2023,
          name: "YourRAGEGaming",
          details: {
            thumbnail_url: "/assets/2023/nominees/YourRAGEGaming.jpg",
            clip_url:
              "https://clips.twitch.tv/TolerantEnjoyableMoonUWot-m6n6c0qLYD3pXtTe",
            url: "https://www.twitch.tv/yourragegaming",
          },
        },
        {
          nominee_id: 217,
          year: 2023,
          name: "HasanAbi",
          details: {
            thumbnail_url: "/assets/2023/nominees/HasanAbi.jpg",
            clip_url:
              "https://clips.twitch.tv/SuaveCuriousPeachKappaWealth-LFlcdFED1-cesSBW",
            url: "https://www.twitch.tv/hasanabi",
          },
        },
      ],
      winner: {
        nominee_id: 217,
        year: 2023,
        name: "HasanAbi",
        details: {
          thumbnail_url: "/assets/2023/nominees/HasanAbi.jpg",
          clip_url:
            "https://clips.twitch.tv/SuaveCuriousPeachKappaWealth-LFlcdFED1-cesSBW",
          url: "https://www.twitch.tv/hasanabi",
        },
      },
    },
    {
      id: 48,
      name: "Best Variety Streamer",
      details: {
        description:
          "Best streamer that broadcasts variety content across multiple categories.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 155,
          year: 2023,
          name: "Im_Dontai",
          details: {
            thumbnail_url: "/assets/2023/nominees/ImDontai.jpg",
            clip_url:
              "https://clips.twitch.tv/TardyAnimatedKuduPanicBasket-IMQPTMNlbuWjBMgA",
            url: "https://www.twitch.tv/im_dontai",
          },
        },
        {
          nominee_id: 154,
          year: 2023,
          name: "CDawgVA",
          details: {
            thumbnail_url: "/assets/2023/nominees/CDawgVA.jpg",
            clip_url:
              "https://www.twitch.tv/cdawgva/clip/MuddyGiantJellyfishGingerPower-lkIaHCLXZtEzquq9",
            url: "https://www.twitch.tv/cdawgva",
          },
        },
        {
          nominee_id: 193,
          year: 2023,
          name: "39daph",
          details: {
            thumbnail_url: "/assets/2023/nominees/39daph.jpg",
            url: "https://www.twitch.tv/39daph",
          },
        },
        {
          nominee_id: 214,
          year: 2023,
          name: "Erobb221",
          details: {
            thumbnail_url: "/assets/2023/nominees/Erobb221.jpg",
            clip_url:
              "https://clips.twitch.tv/PowerfulPolishedNewtCopyThis-BafIboOH2asxsX8f",
            url: "https://www.twitch.tv/erobb221",
          },
        },
        {
          nominee_id: 143,
          year: 2023,
          name: "xQc",
          details: {
            thumbnail_url: "/assets/2023/nominees/xQc.jpg",
            clip_url:
              "https://clips.twitch.tv/ShySavoryWerewolfTTours-gtkYnILCafbYWyMJ",
            url: "https://www.twitch.tv/xqc",
          },
        },
      ],
      winner: {
        nominee_id: 143,
        year: 2023,
        name: "xQc",
        details: {
          thumbnail_url: "/assets/2023/nominees/xQc.jpg",
          clip_url:
            "https://clips.twitch.tv/ShySavoryWerewolfTTours-gtkYnILCafbYWyMJ",
          url: "https://www.twitch.tv/xqc",
        },
      },
    },
    {
      id: 53,
      name: "League of their Own ",
      details: {
        description:
          "This streamer is a pioneer that creates one-of-a-kind content. With their creativity and out-of-the-box thinking, they are a trailblazer in the streaming industry. Someone who relies on themselves for their content ideas and is genuinely in a league of their own.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 222,
          year: 2023,
          name: "WillNeff",
          details: {
            thumbnail_url: "/assets/2023/nominees/WillNeff.jpg",
            clip_url:
              "https://clips.twitch.tv/PoisedSpeedyGrassPlanking-u-guo3rgsk5i7lln",
            url: "https://www.twitch.tv/willneff",
          },
        },
        {
          nominee_id: 224,
          year: 2023,
          name: "Ludwig",
          details: {
            thumbnail_url: "/assets/2023/nominees/Ludwig.jpg",
            clip_url: "https://www.youtube.com/watch?v=DDKlMxwicI8",
            url: "https://www.youtube.com/@ludwig",
          },
        },
        {
          nominee_id: 200,
          year: 2023,
          name: "DougDoug",
          details: {
            thumbnail_url: "/assets/2023/nominees/DougDougW.jpg",
            clip_url:
              "https://clips.twitch.tv/TardyPolishedFiddleheadsDBstyle-WluufHQSRh7uGOpp",
            url: "https://www.twitch.tv/dougdoug",
          },
        },
        {
          nominee_id: 223,
          year: 2023,
          name: "Nmplol",
          details: {
            thumbnail_url: "/assets/2023/nominees/Nmplol.jpg",
            clip_url:
              "https://clips.twitch.tv/PerfectRoundButterflyRiPepperonis-Bf4O-HoSbZxQTX4F",
            url: "https://www.twitch.tv/nmplol",
          },
        },
      ],
      winner: {
        nominee_id: 200,
        year: 2023,
        name: "DougDoug",
        details: {
          thumbnail_url: "/assets/2023/nominees/DougDougW.jpg",
          clip_url:
            "https://clips.twitch.tv/TardyPolishedFiddleheadsDBstyle-WluufHQSRh7uGOpp",
          url: "https://www.twitch.tv/dougdoug",
        },
      },
    },
    {
      id: 91,
      name: "Streamer's Streamer Award",
      details: {
        description:
          "Favorite streamer that streamers like to watch, as voted live by the streamers at the show.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 215,
          year: 2023,
          name: "PaymoneyWubby",
          details: {
            thumbnail_url: "/assets/2023/nominees/PaymoneyWubby.jpg",
            clip_url:
              "https://clips.twitch.tv/AuspiciousFilthySpaghettiPermaSmug-3mfCzOpSO6jZd4EQ",
            url: "https://www.twitch.tv/paymoneywubby",
          },
        },
      ],
      winner: {
        nominee_id: 215,
        year: 2023,
        name: "PaymoneyWubby",
        details: {
          thumbnail_url: "/assets/2023/nominees/PaymoneyWubby.jpg",
          clip_url:
            "https://clips.twitch.tv/AuspiciousFilthySpaghettiPermaSmug-3mfCzOpSO6jZd4EQ",
          url: "https://www.twitch.tv/paymoneywubby",
        },
      },
    },
    {
      id: 92,
      name: "Legacy Award",
      details: {
        description:
          "A legend who has contributed a great deal to and has had a profound impact on the streaming industry, i.e., a career achievement award.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 226,
          year: 2023,
          name: "Jerma985",
          details: {
            thumbnail_url: "/assets/2023/nominees/Jerma985.jpg",
            clip_url:
              "https://clips.twitch.tv/NurturingImportantYamResidentSleeper-IECApXCix_WIBRs2",
            url: "https://www.twitch.tv/jerma985",
          },
        },
      ],
      winner: {
        nominee_id: 226,
        year: 2023,
        name: "Jerma985",
        details: {
          thumbnail_url: "/assets/2023/nominees/Jerma985.jpg",
          clip_url:
            "https://clips.twitch.tv/NurturingImportantYamResidentSleeper-IECApXCix_WIBRs2",
          url: "https://www.twitch.tv/jerma985",
        },
      },
    },
    {
      id: 54,
      name: "Gamer of the Year ",
      details: {
        description:
          "Streamer that has extraordinary gaming abilities and has excelled at games over the past year. No matter what game they're playing, you just have to watch.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 141,
          year: 2023,
          name: "Tarik",
          details: {
            thumbnail_url: "/assets/2023/nominees/Tarik.jpg",
            clip_url: "https://www.youtube.com/watch?v=E6J9wIsS6yc",
            url: "https://www.twitch.tv/tarik",
          },
        },
        {
          nominee_id: 151,
          year: 2023,
          name: "aceu",
          details: {
            thumbnail_url: "/assets/2023/nominees/Aceu.jpg",
            clip_url:
              "https://clips.twitch.tv/FitDeterminedPartridgeStrawBeary-bz4BDlghHbTpl-ah",
            url: "https://www.twitch.tv/aceu",
          },
        },
        {
          nominee_id: 176,
          year: 2023,
          name: "TenZ",
          details: {
            thumbnail_url: "/assets/2023/nominees/Tenz.jpg",
            clip_url:
              "https://clips.twitch.tv/ConsiderateBovineTildeAllenHuhu-vml8S7v8MKGRq7jK",
            url: "https://www.twitch.tv/tenz",
          },
        },
        {
          nominee_id: 175,
          year: 2023,
          name: "iiTzTimmy",
          details: {
            thumbnail_url: "/assets/2023/nominees/iiTzTimmy.jpg",
            clip_url:
              "https://clips.twitch.tv/TenaciousFunnyWaspKeepo-wYjv4YsAOY0_AThZ",
            url: "https://www.twitch.tv/iitztimmy",
          },
        },
      ],
      winner: {
        nominee_id: 176,
        year: 2023,
        name: "TenZ",
        details: {
          thumbnail_url: "/assets/2023/nominees/Tenz.jpg",
          clip_url:
            "https://clips.twitch.tv/ConsiderateBovineTildeAllenHuhu-vml8S7v8MKGRq7jK",
          url: "https://www.twitch.tv/tenz",
        },
      },
    },
    {
      id: 55,
      name: "Streamer of the Year",
      details: {
        description:
          "The best overall streamer for the past year. They saw great success with their entertaining content, elevated their brand, and in general, left their mark on viewers.",
        input: {},
      },
      nominees: [
        {
          nominee_id: 217,
          year: 2023,
          name: "HasanAbi",
          details: {
            thumbnail_url: "/assets/2023/nominees/HasanAbi.jpg",
            clip_url:
              "https://clips.twitch.tv/SuaveCuriousPeachKappaWealth-LFlcdFED1-cesSBW",
            url: "https://www.twitch.tv/hasanabi",
          },
        },
        {
          nominee_id: 226,
          year: 2023,
          name: "Jerma985",
          details: {
            thumbnail_url: "/assets/2023/nominees/Jerma985.jpg",
            clip_url:
              "https://clips.twitch.tv/NurturingImportantYamResidentSleeper-IECApXCix_WIBRs2",
            url: "https://www.twitch.tv/jerma985",
          },
        },
        {
          nominee_id: 227,
          year: 2023,
          name: "xQc ",
          details: {
            thumbnail_url: "/assets/2023/nominees/xQc.jpg",
            clip_url:
              "https://clips.twitch.tv/ShySavoryWerewolfTTours-gtkYnILCafbYWyMJ",
            url: "https://www.twitch.tv/xqc",
          },
        },
        {
          nominee_id: 225,
          year: 2023,
          name: "KaiCenat",
          details: {
            thumbnail_url: "/assets/2023/nominees/KaiCenat.jpg",
            clip_url:
              "https://clips.twitch.tv/ExpensiveOptimisticMangoStinkyCheese-GDZFUkOzkqI_NhZj",
            url: "https://www.twitch.tv/kaicenat",
          },
        },
      ],
      winner: {
        nominee_id: 225,
        year: 2023,
        name: "KaiCenat",
        details: {
          thumbnail_url: "/assets/2023/nominees/KaiCenat.jpg",
          clip_url:
            "https://clips.twitch.tv/ExpensiveOptimisticMangoStinkyCheese-GDZFUkOzkqI_NhZj",
          url: "https://www.twitch.tv/kaicenat",
        },
      },
    },
  ],
};

export const clientConfig: GetClientConfigResp = {
  is_category_noms_open: false,
  is_nominations_open: false,
  is_voting_open: false,
  is_predictions_open: false,

  show_merch: false,
  alert: "",
};

export const winnersByCategory: Map<year, GetWinnersByCategoryResp> = new Map();
winnersByCategory.set(2022, winners2022);
winnersByCategory.set(2023, winners2023);
