import { ReactNode, useEffect } from "react";

import SimpleBtn from "components/button/SimpleBtn";

import styles from "components/modals/Modal.module.scss";

type ModalProps = {
  onClose: () => void;
  show: boolean;
  children?: ReactNode;
  className?: string;
  btnClassName?: string;
};

const ESC_KEY = 27;

// Adapted from https://www.digitalocean.com/community/tutorials/react-modal-component
const Modal: React.FC<ModalProps> = ({
  onClose,
  show,
  children,
  className,
  btnClassName,
}) => {
  const handleKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === "Escape" || e.keyCode === ESC_KEY) {
      e.stopPropagation();
      onClose();
    }
  };

  const handleOutsideClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleClick: React.MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();
  };

  if (!show) {
    return null;
  }

  return (
    <div
      className={styles.ModalPage}
      // className={`${styles.ModalPage}  ${show ? "" : styles.Hide} `}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onClick={handleOutsideClick}
    >
      <section
        className={`${styles.Modal} ${className ? className : ""}`}
        onClick={handleClick}
      >
        <div className={styles.CloseContainer}>
          <SimpleBtn
            handleClick={onClose}
            className={`${styles.Close} ${btnClassName ? btnClassName : ""}`}
          >
            ✕
          </SimpleBtn>
        </div>

        {children}
      </section>
    </div>
  );
};

export default Modal;
