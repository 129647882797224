import React from "react";
import { Link } from "react-router-dom";

import SEO from "components/seo";
import YoutubeEmbed from "components/video/YoutubeEmbed";
import TwitchVideo from "components/video/TwitchVideo";
import SimpleBtn from "components/button/SimpleBtn";
import FancyBtn from "components/button/FancyBtn";

import { Phase, currentPhase, awardShowTimestamp } from "common/dates";
import { formatDateTime, getDateDiffInSecs } from "utils";
import { PageProps } from "types";

import styles from "pages/home/Home.module.scss";
import globalStyles from "styles/components.module.scss";
import { CURRENT_AWARDS_YEAR } from "common";

type HomePageProps = PageProps;

const HomePage: React.FunctionComponent<HomePageProps> = ({}) => {
  const renderPhaseComponent = () => {
    switch (currentPhase) {
      case Phase.AWARDS_SHOW:
        if (getDateDiffInSecs(awardShowTimestamp, new Date()) > 1 * 60 * 60) {
          return null;
        }

        // return <TwitchVideo channel="qtcinderella" />;
        return null;

      default:
        return null;
    }
  };

  const renderCallToActionBtn = () => {
    switch (currentPhase) {
      case Phase.CATEGORY_NOMINATIONS:
        return (
          <Link to="/categories">
            <SimpleBtn align="left">Choose categories now→</SimpleBtn>
          </Link>
        );

      case Phase.NOMINATIONS:
        return (
          <Link to="/nominations">
            <SimpleBtn align="left">Nominate now→</SimpleBtn>
          </Link>
        );

      case Phase.VOTING:
        return (
          <Link to="/vote">
            <SimpleBtn align="left">Vote now→</SimpleBtn>
          </Link>
        );

      case Phase.PICKEMS:
        return (
          <Link to="/pickem">
            <SimpleBtn align="left">Submit predictions now→</SimpleBtn>
          </Link>
        );

      case Phase.AWARDS_SHOW:
        return (
          <a
            href="https://www.twitch.tv/qtcinderella"
            target="_blank"
            rel="noreferrer"
          >
            <SimpleBtn align="left">Watch live→</SimpleBtn>
          </a>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <SEO title="" description="" />

      {renderPhaseComponent()}

      <div className={styles.Container}>
        <div className={styles.Intro}>
          <p className={styles.Title}>Recognize the best of live streaming</p>
          <p className={styles.Description}>
            The biggest community powered, streamer oriented awards show. Have
            your say by nominating and voting to commemorate the achievements of
            the best streamers of the past year.
          </p>

          <div className={styles.BtnGroup}>
            <div>{renderCallToActionBtn()}</div>
            <div>
              <Link to="/about">
                <FancyBtn>Learn more</FancyBtn>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={styles.Container}>
        <p>
          The Streamer Awards {CURRENT_AWARDS_YEAR} will be live streamed on{" "}
          <span className={globalStyles.Time}>
            {formatDateTime(awardShowTimestamp, true, true)}
          </span>{" "}
          at{" "}
          <a
            href="https://www.twitch.tv/qtcinderella"
            target="_blank"
            rel="noreferrer"
            className={globalStyles.Link}
          >
            twitch.tv/qtcinderella
          </a>
          .
        </p>
      </div> */}

      <YoutubeEmbed
        border
        embedId="FnoiaRk7eqM"
        title="The Streamer Awards 2023"
        className={globalStyles.YoutubeVideo}
      />
      <YoutubeEmbed
        border
        embedId="Fqz2bTlGifg"
        title="The Streamer Awards 2023- Red Carpet"
        className={globalStyles.YoutubeVideo}
      />
      {/* <TwitchVideo videoID="1762508100" className={globalStyles.TwitchVideo} /> */}
    </div>
  );
};

export default HomePage;
