import React, { ReactNode } from "react";

import styles from "components/alert/Alert.module.scss";

type AlertProps = {
  innerHtml?: string;
  children?: ReactNode;
  className?: string;
};

const Alert: React.FunctionComponent<AlertProps> = ({
  innerHtml,
  children,
  className,
}) => {
  // https://stackoverflow.com/a/39758631/8802161
  const htmlDecode = (spanInnerHtml: string): string => {
    var e = document.createElement("span");
    e.innerHTML = spanInnerHtml;

    if (e.childNodes.length === 0 || e.childNodes[0].nodeValue == null) {
      return "";
    }

    return e.childNodes[0].nodeValue;
  };

  return (
    <div className={`${styles.AlertContainer} ${className ? className : ""}`}>
      <p className={styles.Text}>
        {innerHtml && (
          <span dangerouslySetInnerHTML={{ __html: htmlDecode(innerHtml) }} />
        )}
      </p>

      {children && <div className={styles.Text}>{children}</div>}
    </div>
  );
};

export default React.memo(Alert);
