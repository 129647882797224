import React, { useEffect } from "react";

import { PageProps } from "types";

import globalStyles from "styles/components.module.scss";

type LoginSuccessPageProps = PageProps;

const LoginSuccessPage: React.FunctionComponent<
  LoginSuccessPageProps
> = ({}) => {
  const handleLoad = () => {
    setTimeout(() => window.close(), 500);
  };

  useEffect(() => {
    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <div>
      <p>Logged in successfully. You may close this window.</p>
    </div>
  );
};

export default LoginSuccessPage;
