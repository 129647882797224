import React, { ReactNode } from "react";

import styles from "components/button/FancyBtn.module.scss";

type FancyBtnProps = {
  handleClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  children: ReactNode;
  className?: string;
};

const FancyBtn: React.FunctionComponent<FancyBtnProps> = ({
  handleClick,
  disabled,
  children,
  className,
}) => {
  return (
    <span className={styles.BtnContainer}>
      <button
        type="button"
        disabled={disabled}
        onClick={(e) => {
          handleClick && handleClick(e);
        }}
        className={`${styles.Btn} ${className ? className : ""}`}
      >
        {children}
      </button>
    </span>
  );
};

export default FancyBtn;
