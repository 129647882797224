import React, { ReactNode, useState } from "react";
import { Link } from "react-router-dom";

import Alert from "components/alert";
import SimpleBtn from "components/button/SimpleBtn";
import CategoryNomination from "pages/category";
import Nomination from "pages/nomination";
import Vote from "pages/vote";
import Home from "pages/home";
import PickEmPage from "pages/pickem";

import {
  voteStartDate,
  currentPhase,
  Phase,
  nominationStartDate,
  pickEmStartDate,
} from "common/dates";

import globalStyles from "styles/components.module.scss";
import styles from "pages/splash/SplashPage.module.scss";
import { PageProps } from "types";

type RootPageProps = {} & PageProps;

const RootPage: React.FunctionComponent<RootPageProps> = ({
  config,
  ...props
}) => {
  let [showWelcome, setShowWelcome] = useState(true);

  const getPhaseComponents = (
    phase: Phase
  ): {
    welcomeAlert?: ReactNode;
    showLearnMore?: boolean;
    pageComponent: ReactNode;
  } => {
    switch (phase) {
      case Phase.CATEGORY_NOMINATIONS:
        return {
          welcomeAlert: (
            <React.Fragment>
              Welcome to the <b>The Streamer Awards 2023</b>! This year, we're
              starting out with something different.
            </React.Fragment>
          ),
          pageComponent: (
            <CategoryNomination
              onSubmitSuccess={() => {
                setShowWelcome(false);
              }}
              config={config}
              {...props}
            />
          ),
        };

      case Phase.TABULATING_CATEGORIES:
        return {
          welcomeAlert: (
            <React.Fragment>
              The choosing categories period has ended. Come back on{" "}
              {nominationStartDate} to nominate your favorites for awards.
            </React.Fragment>
          ),

          pageComponent: <Home config={config} {...props} />,
        };

      case Phase.NOMINATIONS:
        return {
          welcomeAlert: null,
          //   welcomeAlert: (
          //   <React.Fragment>
          //     Welcome to the nominations phase of{" "}
          //     <b>The Streamer Awards 2023</b>!
          //   </React.Fragment>
          // ),
          pageComponent: (
            <Nomination
              onSubmitSuccess={() => {
                setShowWelcome(false);
              }}
              config={config}
              {...props}
            />
          ),
        };

      case Phase.TABULATING_NOMINEES:
        return {
          welcomeAlert: (
            <React.Fragment>
              The nominations period has ended. Come back to vote for your
              winners on {voteStartDate}.
            </React.Fragment>
          ),

          pageComponent: <Home config={config} {...props} />,
        };

      case Phase.VOTING:
        if (config == null || config?.is_voting_open) {
          return {
            welcomeAlert: null,
            //   welcomeAlert: (
            //   <React.Fragment>
            //     Welcome to the voting phase of <b>The Streamer Awards 2023</b>!
            //   </React.Fragment>
            // ),

            pageComponent: (
              <Vote
                onSubmitSuccess={() => {
                  setShowWelcome(false);
                }}
                config={config}
                {...props}
              />
            ),
          };
        }

        return {
          welcomeAlert: (
            <React.Fragment>
              The nominations period has ended. The voting phase will open soon.
              Follow{" "}
              <a
                href="https://twitter.com/StreamerAwards"
                target="_blank"
                rel="noreferrer"
                className={globalStyles.Link}
              >
                <span>&#64;</span>StreamerAwards
              </a>{" "}
              for updates.
            </React.Fragment>
          ),

          pageComponent: <Home config={config} {...props} />,
        };

      case Phase.TABULATING_VOTES:
        return {
          welcomeAlert: (
            <React.Fragment>
              The voting period has ended. Come back on {pickEmStartDate} to
              submit your predictions and stand a chance at winning prizes.
            </React.Fragment>
          ),
          showLearnMore: false,

          pageComponent: <Home config={config} {...props} />,
        };

      case Phase.PICKEMS:
        return {
          welcomeAlert: (
            <React.Fragment>
              The voting period has ended. You can submit your predictions
              though!
            </React.Fragment>
          ),
          showLearnMore: false,

          pageComponent: (
            <PickEmPage
              onSubmitSuccess={() => {
                setShowWelcome(false);
              }}
              config={config}
              {...props}
            />
          ),
        };

      default:
        return {
          welcomeAlert: null,
          pageComponent: <Home config={config} {...props} />,
        };
    }
  };

  const currComponents = getPhaseComponents(currentPhase);

  return (
    <React.Fragment>
      {showWelcome && currComponents.welcomeAlert && (
        <Alert>
          <div>
            <p className={styles.WelcomeText}>{currComponents.welcomeAlert}</p>

            {currComponents.showLearnMore !== false && (
              <SimpleBtn className={styles.BtnInline}>
                <Link to="/about#how-it-works" className={styles.BtnLink}>
                  Learn more→
                </Link>
              </SimpleBtn>
            )}
          </div>
        </Alert>
      )}

      {currComponents.pageComponent}
    </React.Fragment>
  );
};

export default React.memo(RootPage);
