import React, { ReactNode, useState } from "react";
import { useField } from "formik";

import ClipModal from "components/modals/ClipModal";
import Checkmark from "components/form/input/Checkmark";
import { logInfo } from "api";
import { GACategory, GALabel, Nominee } from "types";

import styles from "components/form/input/RadioInputGroup.module.scss";
import defaultPic from "assets/default-placeholder.png";
import playIcon from "components/form/input/assets/play.png";

const UNSELECT_RADIO_VALUE = null;

type RadioInputProps = {
  handleClick: () => void;
  thumbnailUrl?: string;
  clipUrl?: string;
  url?: string;
  onRadioCheck: () => void;
  displayedName: string;
  setClearInput: (f: () => void) => void;
  checkmarkColour?: string;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

// https://formik.org/docs/tutorial#leveraging-react-context
const RadioInput = React.forwardRef<HTMLInputElement, RadioInputProps>(
  (
    {
      handleClick,
      thumbnailUrl,
      clipUrl,
      url,
      onRadioCheck,
      displayedName,
      setClearInput,
      checkmarkColour,
      ...props
    },
    ref
  ) => {
    const [field, meta, helpers] = useField({ ...props, type: "radio" } as any);
    const [playVideoUrl, setPlayVideoUrl] = useState<string | null>("");

    setClearInput(() => {
      const { setValue } = helpers;
      setValue(UNSELECT_RADIO_VALUE);
    });

    const handleRadioClick: React.MouseEventHandler<HTMLInputElement> = (e) => {
      if (e.ctrlKey && url) {
        logInfo(`nominee_link-${url}`, "", GACategory.Visit, GALabel.Radio);
        window.open(url, "_blank");
        return;
      }

      handleClick();

      // https://stackoverflow.com/a/27980522/8802161
      const isKeyboardChange = (
        e: React.MouseEvent<HTMLInputElement>
      ): boolean => {
        return e.clientX === 0 && e.clientY === 0;
      };

      if (!isKeyboardChange(e)) {
        onRadioCheck();
      }
    };

    const handlePlayClick: React.MouseEventHandler = (e) => {
      if (!clipUrl) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      logInfo(
        `nominee_clip-${displayedName}`,
        "",
        GACategory.Visit,
        GALabel.Radio
      );

      setPlayVideoUrl(clipUrl);
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
      if (e.keyCode === 13 || e.code === "13" || e.key === "Enter") {
        onRadioCheck();
      }
    };

    return (
      <div>
        <a href={url} target="_blank" rel="noreferrer" title={displayedName}>
          <label>
            <input
              type="radio"
              className={styles.RadioInput}
              onClick={handleRadioClick}
              onKeyDown={handleKeyDown}
              ref={ref}
              {...field}
              {...props}
            />

            <div className={styles.RadioContainer}>
              <div className={styles.ImgContainer} onClick={handlePlayClick}>
                <img
                  className={styles.RadioImg}
                  src={process.env.PUBLIC_URL + thumbnailUrl}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = defaultPic;
                  }}
                  alt="nominee-pic"
                />
                {clipUrl && (
                  <img
                    className={styles.PlayBtn}
                    src={playIcon}
                    title="Watch nominee clip"
                    alt="play-clip"
                  />
                )}
              </div>

              <div
                className={`${styles.RadioSpan} ${
                  clipUrl ? styles.Offset : ""
                }`}
              >
                <div className={styles.RadioSpanText}>{displayedName}</div>
                {field.checked && (
                  <span
                    className={`${styles.ValidInput} ${
                      clipUrl ? styles.Offset : ""
                    }`}
                  >
                    <Checkmark colour={checkmarkColour} />
                  </span>
                )}
              </div>
            </div>
          </label>
        </a>

        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}

        {playVideoUrl && (
          // TODO: use single common modal
          <ClipModal
            url={playVideoUrl}
            show={!!playVideoUrl}
            closeModal={() => setPlayVideoUrl(null)}
          />
        )}
      </div>
    );
  }
);

type RadioInputGroupProps = {
  name: string;
  choices?: Array<Nominee>;
  onRadioChoiceCheck: () => void;
  handleClick: () => void;
  setClearInput: (f: () => void) => void;
  checkmarkColour?: string;
};

const RadioInputGroup = React.forwardRef<HTMLDivElement, RadioInputGroupProps>(
  (
    {
      name,
      choices,
      onRadioChoiceCheck,
      handleClick,
      setClearInput,
      checkmarkColour,
    },
    ref
  ) => {
    if (!choices || choices.length === 0) {
      return <p>Sorry, an error occurred. No nominees found.</p>;
    }

    return (
      <div
        role="group"
        aria-labelledby="radio-group"
        className={styles.RadioGroup}
      >
        {choices.map(({ nominee_id: nomId, name: nomName, details }, idx) => {
          return (
            <RadioInput
              key={nomId}
              name={name}
              value={`${nomId}`}
              thumbnailUrl={details?.thumbnail_url}
              url={details?.url}
              clipUrl={details?.clip_url}
              onRadioCheck={onRadioChoiceCheck}
              ref={idx === 0 ? (ref as any) : null}
              displayedName={nomName}
              handleClick={handleClick}
              setClearInput={setClearInput}
              checkmarkColour={checkmarkColour}
            />
          );
        })}
      </div>
    );
  }
);

export default RadioInputGroup;
