export type seconds = number;
export type milliseconds = number;
export type year = number;

export enum PageStage {
  loading = "loading",
  input = "form",
  complete = "submissionComplete",
}

export enum GACategory {
  General = "general",
  Visit = "visit",
}

export enum GALabel {
  Navbar = "navbar",
  Alert = "alert",
  StickyAlert = "sticky_alert",
  Modal = "modal",
  Radio = "radio",
  Checkbox = "checkbox",
  Link = "link",
}

export type User = {
  name: string;
};

export type ClientConfig = {
  is_category_noms_open?: boolean;
  is_nominations_open?: boolean;
  is_voting_open?: boolean;
  is_predictions_open?: boolean;
  show_merch?: boolean;
  alert?: string;
};

export type PageProps = {
  config?: ClientConfig;
};

export enum InputType {
  Text = "text",
  Number = "number",
  RadioGroup = "radio_group",
  RadioGroupCustom = "radio_group-custom",
  CheckboxGroup = "checkbox_group",
  CheckboxGroupCustom = "checkbox_group-custom",
}

export type InputDetails = {
  type?: InputType;
  max_selections?: number;
  max_length?: number;
  max_value?: number;
  custom_input_prompt?: string;
};

export type Category = {
  id: number;
  name: string;
  details?: {
    description: string;
    input?: InputDetails;
  };
};

export type Nominee = {
  nominee_id: number;
  name: string;
  year?: year;
  details?: {
    clip_url?: string;
    thumbnail_url?: string;
    url?: string;
  };
};

export type CategoryWithNominees = Category & {
  nominees?: Array<Nominee>;
};

export type CategoryWithNomineesAndWinner = CategoryWithNominees & {
  winner?: Nominee;
};

export type UserCategorySubmission = {
  category_id: number;
  nominee_ids?: Array<number>;
  custom_responses?: Array<string>;
};

export type UserNominationSubmission = {
  category_id: number;
  nomination?: string;
};

export type UserVoteSubmission = {
  category_id: number;
  nominee_id?: number;
};

export type UserPredictionSubmission = {
  category_id: number;
  nominee_ids?: Array<number>;
  custom_responses?: Array<string>;
};

type PredictionPick = {
  category: string;
  nominee: string;
};

export type UserPredictionsResult = {
  num_correct: number;
  score: number;
  percentile: number;
  share_message: string;
  top_picks?: Array<PredictionPick>;
};
