import { ReactNode } from "react";

import styles from "components/button/SimpleBtn.module.scss";

type SimpleBtnProps = {
  handleClick?: () => void;
  align?: "left" | "center" | "right";
  disabled?: boolean;
  children: ReactNode;
  className?: string;
};

const SimpleBtn: React.FunctionComponent<SimpleBtnProps> = ({
  handleClick,
  align = "right",
  disabled,
  children,
  className,
}) => {
  var alignStyle = "";
  switch (align) {
    case "left":
      alignStyle = styles.Left;
      break;
    case "center":
      alignStyle = styles.Center;
      break;
    case "right":
      alignStyle = styles.Right;
      break;
    default:
      alignStyle = styles.Right;
      break;
  }

  return (
    <div
      className={`${styles.BtnContainer}  ${alignStyle} ${
        className ? className : ""
      }`}
    >
      <button
        onClick={() => {
          handleClick && handleClick();
        }}
        disabled={disabled}
        type="button"
        className={styles.Btn}
      >
        {children}
      </button>
    </div>
  );
};

export default SimpleBtn;
