import { useEffect, useState } from "react";

import Modal from "components/modals/Modal";

import { logInfo } from "api";

import styles from "components/modals/ClipModal.module.scss";
import YoutubeEmbed from "components/video/YoutubeEmbed";
import { getTwitchClipId, getTwitchVideoId, getYoutubeVideoId } from "utils";
import TwitchClip from "components/video/TwitchClip";
import TwitchVideo from "components/video/TwitchVideo";

type ClipModalProps = {
  url: string;
  show: boolean;
  closeModal: () => void;
  className?: string;
};

const ClipModal: React.FC<ClipModalProps> = ({
  url,
  show,
  closeModal,
  className,
}) => {
  const [canClose, setCanClose] = useState<boolean>(false);

  const delayCanCloseMs = 500;

  useEffect(() => {
    setTimeout(() => {
      setCanClose(true);
    }, delayCanCloseMs);
  }, []);

  const close = () => {
    if (!canClose) {
      return;
    }

    closeModal();
  };

  const renderVideo = (url: string) => {
    const ytId = getYoutubeVideoId(url);
    if (ytId != null) {
      const u = new URL(url);
      let startTime = u.searchParams.get("t");
      if (startTime == null) {
        startTime = u.searchParams.get("start");
      }
      const endTime = u.searchParams.get("end");

      return (
        <YoutubeEmbed
          embedId={ytId}
          autoplay
          startTime={startTime != null ? parseInt(startTime) : undefined}
          endTime={endTime != null ? parseInt(endTime) : undefined}
          className={styles.YoutubeVideo}
        />
      );
    }

    const clipId = getTwitchClipId(url);
    if (clipId != null) {
      return <TwitchClip clipId={clipId} className={styles.TwitchClip} />;
    }

    const videoId = getTwitchVideoId(url);
    if (videoId != null) {
      const u = new URL(url);
      const startTime = u.searchParams.get("t");

      return (
        <TwitchVideo
          videoID={videoId}
          time={startTime}
          className={styles.TwitchVideo}
        />
      );
    }

    return (
      <div className={styles.TextModal}>
        <p>The clip could not be played.</p>
      </div>
    );
  };

  return (
    <Modal
      onClose={close}
      show={show}
      className={`${styles.ClipModal} ${className}`}
      btnClassName={styles.CloseBtn}
    >
      {renderVideo(url)}
    </Modal>
    // TODO: * Clip was submitted by the nominee.
  );
};

export default ClipModal;
