import React from "react";

import { logError } from "api";

class ErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, componentInfo) {
    logError(
      "react_error",
      `error: ${JSON.stringify(error)}-${error}, cInfo: ${componentInfo}`
    );
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <p>Something went wrong</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
