import React from "react";

import ReadOnlyRadioInputGroup from "components/input/ReadOnlyRadioInputGroup";

import styles from "pages/winners/Winners.module.scss";
import { CategoryWithNomineesAndWinner, Nominee } from "types";

type WinnersListProps = {
  categories: CategoryWithNomineesAndWinner[];
  selectedCategoryNominees?: { [catId: number]: number };
};

const WinnersList: React.FunctionComponent<WinnersListProps> = ({
  categories,
  selectedCategoryNominees,
}) => {
  const renderCategoryContent = (
    cats: CategoryWithNomineesAndWinner[],
    idx: number
  ) => {
    const { id: catId, name, details, nominees, winner } = cats[idx];

    var description = details?.description || "";
    var inputElement = (
      <ReadOnlyRadioInputGroup
        name={`${catId}`}
        choices={nominees}
        checkedChoiceId={winner?.nominee_id}
        checkmarkColour={"#008000"}
        selectedChoiceId={
          selectedCategoryNominees && selectedCategoryNominees[catId]
        }
        selectedCheckmarkColour={"#1326f2"}
      />
    );

    return (
      <div
        key={catId}
        className={`${styles.Category} ${idx % 2 === 0 && styles.Bkgd}`}
      >
        <p className={styles.CategoryIdx}>
          {idx + 1}/{categories.length}
        </p>
        <p className={styles.Title}>{name}</p>

        <div className={styles.Input}>
          {description !== "" && (
            <p className={styles.Description}>{description}</p>
          )}

          {inputElement}
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={styles.CategoryContainer}>
        {categories.map((c, idx) => {
          return renderCategoryContent(categories, idx);
        })}
      </div>
    </React.Fragment>
  );
};

export default React.memo(WinnersList);
