import { createContext } from "react";

import type { User } from "types";

export type userContext = {
  user?: User | null;
  setUser?: (user: User | null) => void;
};

const UserContext = createContext<userContext>({ user: null });

export default UserContext;
