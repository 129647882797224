import React, { ReactNode, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";

import UserContext from "contexts/UserContext";
import { getUserProfile, logError, logoutUser } from "api";
import useDocumentVisibility from "hooks/useDocumentVisibility";

import styles from "components/auth/Auth.module.scss";
import { ReactComponent as GoogleIcon } from "components/auth/assets/google-icon.svg";

type ButtonProps = {
  btnText: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const Button: React.FunctionComponent<ButtonProps> = ({ btnText, onClick }) => {
  return (
    <button type="button" onClick={onClick} className={styles.AuthBtn}>
      <div className={styles.BtnIcon}>
        <GoogleIcon />
      </div>
      <span className={styles.BtnText}>{btnText}</span>
    </button>
  );
};

type AuthProps = {
  onAuthInit: () => void;
  onLogin: () => void;
  onLogout: () => void;
};

const Auth = React.forwardRef<HTMLInputElement, AuthProps>(
  ({ onAuthInit, onLogin, onLogout }, ref) => {
    const { user, setUser } = useContext(UserContext);
    const visibility = useDocumentVisibility();

    // useThirdPartyCookieCheck(isDevMode);

    const setUserProfile = async () => {
      const ret = await getUserProfile();
      if (ret.error != null || ret.resp == null) {
        // console.log("not logged in:", e);
        setUser && setUser(null);
        return;
      }

      setUser && setUser(ret.resp);
    };

    const handleSignOut = async () => {
      const ret = await logoutUser();
      if (ret.error != null) {
        toast.error("An error occurred when logging out.");
        logError("auth_logout", `${JSON.stringify(ret.error)}`);
        return;
      }

      setUser && setUser(null);
    };

    const handleUserChange = () => {
      if (isSignedIn) {
        onLogin();
      } else {
        onLogout();
      }
    };

    // const redirectUrl = window.location.href;
    const redirectUrl = window.location.origin + "/login_success";
    const isSignedIn = user != null;
    const isLoading = user === undefined;

    useEffect(() => {
      handleUserChange();
    }, [user]);

    useEffect(() => {
      if (visibility === "hidden") {
        return;
      }

      if (!isSignedIn) {
        setUserProfile();
      }
    }, [visibility]);

    const renderButtons = (): ReactNode => {
      if (isSignedIn) {
        return (
          <>
            <p>
              You are currently logged in as{" "}
              <span className={styles.User}>{user.name}</span>
            </p>
            <Button onClick={handleSignOut} btnText="Logout" />
          </>
        );
      }

      return (
        //  Don't use fetch from react to api to oauth service. Use a link (anchor/location.href)
        // instead and let the flow of the oauth process take over in the browser.
        // https://github.com/jaredhanson/passport/issues/734#issuecomment-509727963
        <>
          <a
            href={`${process.env.REACT_APP_SERVER_URL}/api/auth/google?redirect_to=${redirectUrl}`}
            target="popup"
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_SERVER_URL}/api/auth/google?redirect_to=${redirectUrl}`,
                "popup",
                "width=600,height=600"
              );
              return false;
            }}
          >
            <Button btnText="Log in with Google" />
          </a>
        </>
      );
    };

    return (
      <div className={styles.AuthContainer} ref={ref}>
        {!isLoading && renderButtons()}
      </div>
    );
  }
);

export default Auth;
