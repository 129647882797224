import React from "react";

import { DEFAULT_PARENT_DOMAINS } from "common";

import styles from "components/video/TwitchClip.module.scss";

type Props = {
  clipId: string;
  title?: string;
  className?: string;
};

// https://dev.twitch.tv/docs/embed/video-and-clips/#non-interactive-iframes-for-clips
// https://github.com/talk2MeGooseman/react-twitch-embed-video
// https://philna.sh/blog/2020/03/23/responsive-twitch-embed/
const TwitchClip: React.FC<Props> = ({ clipId, title = "", className }) => {
  const domainName = window.location.hostname;

  const url = new URL("https://clips.twitch.tv/embed");

  const qParams = new URLSearchParams();
  qParams.append("allowfullscreen", String(true));
  qParams.append("clip", clipId);
  qParams.append("preload", "auto");
  qParams.append("parent", domainName);
  if (!domainName.startsWith("www.")) {
    qParams.append("parent", "www." + domainName);
  }
  DEFAULT_PARENT_DOMAINS.forEach((d) => {
    qParams.append("parent", d);
  });
  qParams.append("autoplay", String(true));
  qParams.append("muted", String(false));

  url.search = qParams.toString();
  const videoSrc = url.toString();

  return (
    <div className={`${styles.TwitchClip} ${className ? className : ""}`}>
      <iframe
        src={videoSrc}
        title={title}
        frameBorder="0"
        scrolling="no"
        allowFullScreen={true}
        allow="autoplay" // https://discuss.dev.twitch.tv/t/muted-flag-is-broken-until-you-manually-mute-or-unmute/23672/3
      />
    </div>
  );
};

export default React.memo(TwitchClip);
