import React, { useState } from "react";

import ClipModal from "components/modals/ClipModal";
import Checkmark from "components/form/input/Checkmark";
import { logInfo } from "api";
import { GACategory, GALabel, Nominee } from "types";

import styles from "components/input/ReadOnlyRadioInputGroup.module.scss";
import defaultPic from "assets/default-placeholder.png";
import playIcon from "components/form/input/assets/play.png";

type ReadOnlyRadioInputProps = {
  thumbnailUrl?: string;
  url?: string;
  clipUrl?: string;
  displayedName: string;
  selected: boolean;
  checkmarkColour?: string;
  selectedCheckmarkColour?: string;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const ReadOnlyRadioInput = React.forwardRef<
  HTMLInputElement,
  ReadOnlyRadioInputProps
>(
  (
    {
      thumbnailUrl,
      url,
      clipUrl,
      displayedName,
      children,
      checked,
      checkmarkColour,
      selected,
      selectedCheckmarkColour,
      ...props
    },
    ref
  ) => {
    const [playVideoUrl, setPlayVideoUrl] = useState<string | null>("");

    const handlePlayClick: React.MouseEventHandler = (e) => {
      if (!clipUrl) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      logInfo(
        `winners_nominee_clip-${displayedName}`,
        "",
        GACategory.Visit,
        GALabel.Radio
      );

      setPlayVideoUrl(clipUrl);
    };

    const handleRadioClick: React.MouseEventHandler<HTMLInputElement> = (e) => {
      // if (e.ctrlKey && url) {
      if (url) {
        logInfo(
          `winners_nominee_link-${url}`,
          "",
          GACategory.Visit,
          GALabel.Radio
        );
        window.open(url, "_blank");
        return;
      }
    };

    const renderCheckmark = () => {
      if (selected) {
        return (
          <span className={styles.ValidInput}>
            <Checkmark
              colour={selectedCheckmarkColour}
              borderColour={checked ? checkmarkColour : undefined}
            />
          </span>
        );
      }

      if (checked) {
        return (
          <span className={styles.ValidInput}>
            <Checkmark
              colour={checkmarkColour}
              borderColour={selected ? selectedCheckmarkColour : undefined}
            />
          </span>
        );
      }

      return null;
    };

    return (
      <div>
        <a href={url} target="_blank" rel="noreferrer" title={displayedName}>
          <label>
            <input
              type="radio"
              className={styles.RadioInput}
              onClick={handleRadioClick}
              checked={checked}
              readOnly
              ref={ref}
            />

            <div className={styles.RadioContainer}>
              <div className={styles.ImgContainer} onClick={handlePlayClick}>
                <img
                  className={styles.RadioImg}
                  src={process.env.PUBLIC_URL + thumbnailUrl}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = defaultPic;
                  }}
                  alt="nominee-pic"
                />
                {clipUrl && (
                  <img
                    className={styles.PlayBtn}
                    src={playIcon}
                    title="Watch nominee clip"
                    alt="play-clip"
                  />
                )}
              </div>

              <div
                className={`${styles.RadioSpan} ${
                  clipUrl ? styles.Offset : ""
                }`}
              >
                <div className={styles.RadioSpanText}>{displayedName}</div>
                {renderCheckmark()}
              </div>
            </div>
          </label>
        </a>

        {playVideoUrl && (
          // TODO: use single common modal?
          <ClipModal
            url={playVideoUrl}
            show={!!playVideoUrl}
            closeModal={() => setPlayVideoUrl(null)}
          />
        )}
      </div>
    );
  }
);

type ReadOnlyRadioInputGroupProps = {
  name: string;
  choices?: Array<Nominee>;
  checkedChoiceId?: number;
  selectedChoiceId?: number;
  checkmarkColour?: string;
  selectedCheckmarkColour?: string;
};

const ReadOnlyRadioInputGroup = React.forwardRef<
  HTMLDivElement,
  ReadOnlyRadioInputGroupProps
>(
  (
    {
      name,
      choices,
      checkedChoiceId,
      checkmarkColour,
      selectedChoiceId,
      selectedCheckmarkColour,
    },
    ref
  ) => {
    if (!choices || choices.length === 0) {
      return <p>Sorry, an error occurred. No nominees found.</p>;
    }

    return (
      <div
        role="group"
        aria-labelledby="radio-group"
        className={styles.RadioGroup}
      >
        {choices.map(({ nominee_id: nomId, name: nomName, details }, idx) => {
          return (
            <ReadOnlyRadioInput
              key={nomId}
              name={name}
              value={`${nomId}`}
              thumbnailUrl={details?.thumbnail_url}
              url={details?.url}
              clipUrl={details?.clip_url}
              ref={idx === 0 ? (ref as any) : null}
              displayedName={nomName}
              checked={nomId === checkedChoiceId}
              checkmarkColour={checkmarkColour}
              selected={nomId === selectedChoiceId}
              selectedCheckmarkColour={selectedCheckmarkColour}
            />
          );
        })}
      </div>
    );
  }
);

export default ReadOnlyRadioInputGroup;
