import { useState, useEffect } from "react";

// Adapted from https://stackoverflow.com/a/66755252/8802161
// https://github.com/mantinedev/mantine/blob/master/src/mantine-hooks/src/use-document-visibility/use-document-visibility.ts
// https://stackoverflow.com/a/52745276/8802161
const useDocumentVisibility = (): DocumentVisibilityState => {
  const [documentVisibility, setDocumentVisibility] =
    useState<DocumentVisibilityState>("visible");

  const handleVisibilityChange = () =>
    setDocumentVisibility(document.visibilityState);
  const hide = () => setDocumentVisibility("hidden");
  const show = () => setDocumentVisibility("visible");

  useEffect(() => {
    document.addEventListener(
      "visibilitychange",
      handleVisibilityChange,
      false
    );
    window.addEventListener("focus", show, false);
    window.addEventListener("blur", hide, false);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", show);
      window.removeEventListener("blur", hide);
    };
  }, []);

  return documentVisibility;
};

export default useDocumentVisibility;
