import React, { useState } from "react";

import styles from "pages/pickem/Rules.module.scss";
import globalStyles from "styles/components.module.scss";
import { logInfo } from "api";
import { GACategory, GALabel } from "types";

type RulesProps = {};

const Rules: React.FC<RulesProps> = () => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();

    if (!isOpen) {
      logInfo("pickem_rules", "", GACategory.General);
    }

    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <div className={styles.Details}>
        <div onClick={onClick} className={styles.Summary}>
          <span className={styles.ToggleIcon}>➤</span>{" "}
          {isOpen ? "Collapse" : "Expand"} details about the prizes and how
          Pick'em works
        </div>

        {isOpen && (
          <div className={styles.Rules}>
            <p>
              Pick'em is a predictions contest that you can join while we wait
              for the winners to be announced at The Streamer Awards.
            </p>
            <p>
              Predict what you think will happen on the day to stand a chance at
              winning prizes.
            </p>

            <p>
              The <b>Pick'em prizes</b> that you can win (apart from bragging
              rights, of course) are:
            </p>
            <ol className={styles.RulesList}>
              <li className={styles.RulesListItem}>
                Most number of correct predictions - $1000
              </li>
              <li className={styles.RulesListItem}>
                Highest Pick'em points total - $250
              </li>
              <li className={styles.RulesListItem}>
                Lowest Pick'em points total - $250
              </li>
            </ol>
            <p>
              Note: In the event of a tie, we will use the final category,{" "}
              <b>Pick'em Tiebreaker</b>, to determine the winner. If it's still
              tied, the winner is picked at random from the tied contenders.
            </p>

            <p>
              The <b>Pick'em points system</b> gives more points and deducts
              less points for risky predictions. This is how it works:
            </p>

            <ul className={styles.RulesList}>
              <li className={styles.RulesListItem}>
                You get points if you correctly predict the winner of a
                category.
                <ul className={styles.RulesList}>
                  <li className={styles.RulesListItem}>
                    You gain less points if most people correctly predicted this
                    winner.
                  </li>
                  <li className={styles.RulesListItem}>
                    You gain more points if few people correctly predicted this
                    winner.
                  </li>
                </ul>
              </li>

              <li className={styles.RulesListItem}>
                You lose some points if you wrongly predict the winner of a
                category.
                <ul className={styles.RulesList}>
                  <li className={styles.RulesListItem}>
                    You lose slighty more points if most people incorrectly
                    predicted the same choice as you.
                  </li>
                  <li className={styles.RulesListItem}>
                    You lose slightly less points if few people incorrectly
                    predicted the same choice as you.
                  </li>
                </ul>
              </li>

              <li className={styles.RulesListItem}>
                Your points remain unchanged if you do not submit a prediction
                for a category.
              </li>
            </ul>
          </div>
        )}
      </div>

      <p>
        See the{" "}
        <a
          href={process.env.PUBLIC_URL + "pickem_terms_and_conditions.html"}
          target="_blank"
          rel="noreferrer"
          className={globalStyles.Link}
        >
          Terms and Conditions
        </a>{" "}
        for the prizes (especially important if you win).
      </p>
    </React.Fragment>
  );
};

export default Rules;
