import React from "react";

import styles from "components/video/YoutubeEmbed.module.scss";
import borderImg from "components/video/assets/video-border.png";

type YoutubeEmbedProps = {
  embedId: string;
  title?: string;
  autoplay?: boolean;
  startTime?: number;
  endTime?: number;
  muted?: boolean;
  className?: string;
  border?: boolean;
};

const YoutubeEmbed: React.FunctionComponent<YoutubeEmbedProps> = ({
  embedId,
  title,
  autoplay = false,
  muted = false,
  className,
  border = false,
  startTime,
  endTime,
}) => {
  const url = new URL(`https://www.youtube.com/embed/${embedId}`);
  const qParams = url.searchParams;
  if (autoplay) {
    qParams.append("autoplay", String(1));

    const mute = muted ? 1 : 0;
    qParams.append("mute", String(mute));
  }
  if (startTime != null) {
    qParams.append("start", String(startTime));
  }
  if (endTime != null) {
    qParams.append("end", String(endTime));
  }

  url.search = qParams.toString();
  const videoSrc = url.toString();

  return (
    <div className={`${styles.Video} ${className ? className : ""}`}>
      <iframe
        src={videoSrc}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        title={title}
      />
      {border && (
        <img className={styles.OverlayImg} src={borderImg} alt="video-border" />
      )}
    </div>
  );
};

export default YoutubeEmbed;
