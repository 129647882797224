import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages#page_view_event
export const usePageTracking = (): void => {
  const location = useLocation();

  useEffect(() => {
    const { gtag } = window;
    if (!gtag) {
      return;
    }

    gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);
};
