import { logError } from "api";

type authIdToken = string;

type googleAuthUser = {
  name: string;
  email: string;
  imageUrl: string;
  googleId: string;
  givenName: string;
};

const IDTokenKey = "authIdToken";
const UserKey = "authUser";

export const saveUser = (user: googleAuthUser) => {
  localStorage.setItem(UserKey, JSON.stringify(user));
};

export const getUser = (): googleAuthUser | null => {
  try {
    const user = localStorage.getItem(UserKey);
    if (!user) {
      return null;
    }

    return JSON.parse(user);
  } catch (e) {
    logError("auth_parse_user_info", `${JSON.stringify(e)}`);
    return null;
  }
};

export const clearUser = () => {
  localStorage.removeItem(UserKey);
};

export const saveUserToken = (idToken: authIdToken) => {
  localStorage.setItem(IDTokenKey, idToken);
};

export const getUserToken = (): authIdToken | null => {
  return localStorage.getItem(IDTokenKey);
};

export const clearUserToken = () => {
  localStorage.removeItem(IDTokenKey);
};

// res = {
//   "tokenObj": {
//       "token_type": "Bearer", "expires_in": 3599,
//       "access_token": "<ACCESS_TOKEN>", "id_token": "<JWT_ID_TOKEN>", ...
//   },
//   "profileObj": {
//       "email": "thestreamerawards@gmail.com", "name": "The Streamer Awards Show", ...
//   }, ...
// }
// type res = {
//   tokenObj: {
//     token_type: string;
//     access_token: string;
//     id_token: string;
//     expires_in: number;
//   };
//   profileObj: {
//     name: string;
//     email: string;
//   };
// };

export const refreshAuthTokens = (res: any) => {
  const refreshToken = async () => {
    const newAuthResp = await res.reloadAuthResponse();

    saveUserToken(newAuthResp);

    let refreshIntervalMs =
      ((newAuthResp.expires_in || 3600 - 5 * 60) - 60) * 1000;
    setTimeout(refreshToken, refreshIntervalMs);
  };

  let refreshIntervalMs =
    ((res.tokenObj.expires_in || 3600 - 5 * 60) - 60) * 1000;
  setTimeout(refreshToken, refreshIntervalMs);
};
