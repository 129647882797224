export const ticketsPresaleStartTimestamp = new Date(
  "2023-01-19T16:00:00-08:00"
);
export const ticketsGeneralStartTimestamp = new Date(
  "2023-01-23T12:00:00-08:00"
);

export const categoryNominationStartDate = "January 19, 2023";
const categoryNominationStartTimestamp = new Date("2023-01-19T13:00:00-08:00");
export const categoryNominationEndDate = "January 27, 2023";
const categoryNominationEndTimestamp = new Date("2023-01-27T23:59:59-08:00");

// will be parsed to local timestamp, since no timezone is specified
export const nominationStartDate = "January 30, 2023";
const nominationStartTimestamp = new Date("2023-01-30T10:00:00-08:00");
export const nominationEndDate = "February 11, 2023";
const nominationEndTimestamp = new Date("2023-02-11T23:59:59-08:00");

export const voteStartDate = "February 20, 2023";
const voteStartTimestamp = new Date("2023-02-20T10:00:00-08:00");
export const voteEndDate = "March 4, 2023";
const voteEndTimestamp = new Date("2023-03-04T23:59:59-08:00");

export const pickEmStartDate = "March 9, 2023";
const pickEmStartTimestamp = new Date("2023-03-09T10:00:00-08:00");
export const pickEmEndDate = "March 10, 2023";
const pickEmEndTimestamp = new Date("2023-03-11T09:00:00-08:00");

export const awardShowDate = "March 11, 2023";
export const awardShowTimestamp = new Date("2023-03-11T15:00:00-08:00"); // red carpet 3pm, doors open 4pm, show starts 5:30pm
const AWARDS_SHOW_DURATION_HOURS = 8;

// Returns exact timestamps.
function getPhaseTimestamps(): {
  categoryNominationStartTimestamp: Date;
  categoryNominationEndTimestamp: Date;
  nominationStartTimestamp: Date;
  nominationEndTimestamp: Date;
  voteStartTimestamp: Date;
  voteEndTimestamp: Date;
  pickEmStartTimestamp: Date;
  pickEmEndTimestamp: Date;
  awardsEndTimestamp: Date;
} {
  // const nominationStartTimestamp = new Date(nominationStartDate);

  // const pstDateSuffix = " Z-08:00"; // to parse time in PST
  // const nominationEndTimestamp = new Date(nominationEndDate + pstDateSuffix);
  // nominationEndTimestamp.setDate(nominationEndTimestamp.getDate() + 1); // end of day

  // const pickEmStartTimestamp = new Date(pickEmStartDate);

  const awardsEndTimestamp = new Date(awardShowTimestamp);
  awardsEndTimestamp.setHours(
    awardsEndTimestamp.getHours() + AWARDS_SHOW_DURATION_HOURS
  );

  return {
    categoryNominationStartTimestamp,
    categoryNominationEndTimestamp,
    nominationStartTimestamp,
    nominationEndTimestamp,
    voteStartTimestamp,
    voteEndTimestamp,
    pickEmStartTimestamp,
    pickEmEndTimestamp,
    awardsEndTimestamp,
  };
}

export enum Phase {
  NONE = "NONE_PHASE",
  CATEGORY_NOMINATIONS = "CATEGORY_NOMINATIONS_PHASE",
  TABULATING_CATEGORIES = "TABULATING_CATEGORIES_PHASE",
  NOMINATIONS = "NOMINATIONS_PHASE",
  TABULATING_NOMINEES = "TABULATING_NOMINEES_PHASE",
  VOTING = "VOTING_PHASE",
  TABULATING_VOTES = "TABULATING_VOTES_PHASE",
  PICKEMS = "PICKEMS",
  AWARDS_SHOW = "AWARDS_SHOW_PHASE",
  WINNERS_ANNOUNCED = "WINNERS_ANNOUNCED_PHASE",
}

function getCurrentPhase(): Phase {
  const now = new Date();

  const {
    categoryNominationStartTimestamp,
    categoryNominationEndTimestamp,
    nominationStartTimestamp,
    nominationEndTimestamp,
    voteStartTimestamp,
    voteEndTimestamp,
    pickEmStartTimestamp,
    pickEmEndTimestamp,
    awardsEndTimestamp,
  } = getPhaseTimestamps();

  let currPhase = Phase.NONE;

  if (now < categoryNominationStartTimestamp) {
    currPhase = Phase.NONE;
  } else if (now < categoryNominationEndTimestamp) {
    currPhase = Phase.CATEGORY_NOMINATIONS;
  } else if (now < nominationStartTimestamp) {
    currPhase = Phase.TABULATING_CATEGORIES;
  } else if (now < nominationEndTimestamp) {
    currPhase = Phase.NOMINATIONS;
  } else if (now < voteStartTimestamp) {
    currPhase = Phase.TABULATING_NOMINEES;
  } else if (now < voteEndTimestamp) {
    currPhase = Phase.VOTING;
  } else if (now < pickEmStartTimestamp) {
    currPhase = Phase.TABULATING_VOTES;
  } else if (now < pickEmEndTimestamp) {
    currPhase = Phase.PICKEMS;
  } else if (now < awardsEndTimestamp) {
    currPhase = Phase.AWARDS_SHOW;
  } else if (now > awardsEndTimestamp) {
    currPhase = Phase.WINNERS_ANNOUNCED;
  }

  return currPhase;
}

export const currentPhase: Phase = getCurrentPhase();
