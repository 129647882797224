import { useEffect, useState } from "react";

import FancyBtn from "components/button/FancyBtn";
import Modal from "components/modals/Modal";
import SimpleBtn from "components/button/SimpleBtn";

import { logInfo } from "api";
import { useMockData } from "api/helper";
import { GACategory, GALabel, milliseconds } from "types";

import styles from "components/modals/TicketsModal.module.scss";
import globalStyles from "styles/components.module.scss";
import useLocalStorage from "use-local-storage";
import { currentPhase, Phase } from "common/dates";

const ticketModalShownKey = "tms";
const SHOW_MODAL_MAX_TIMES = 6;

type TicketsModalProps = {
  openDelay?: milliseconds;
  closeDelay?: milliseconds;
  className?: string;
};

const TicketsModal: React.FC<TicketsModalProps> = ({
  openDelay,
  closeDelay,
  className,
}) => {
  const [shownCount, setShownCount] = useLocalStorage<number>(
    ticketModalShownKey,
    0
  );
  const [show, setShow] = useState<boolean>(false);
  const [canClose, setCanClose] = useState<boolean>(false);

  const delayShowMs =
    openDelay != null
      ? openDelay
      : window.innerWidth > Number(styles.desktopWindowWidthPx)
      ? 800
      : 0;

  const delayCanCloseMs =
    closeDelay != null
      ? closeDelay
      : window.innerWidth > Number(styles.desktopWindowWidthPx)
      ? 5500
      : 1000;

  useEffect(() => {
    // if (useMockData) {
    // setShow(true);
    // setCanClose(true);
    // return;
    // }

    if (
      // process.env.NODE_ENV === "production" &&
      shownCount >= SHOW_MODAL_MAX_TIMES ||
      currentPhase === Phase.AWARDS_SHOW ||
      currentPhase === Phase.WINNERS_ANNOUNCED
    ) {
      return;
    }

    setTimeout(() => {
      setShow(true);
    }, delayShowMs);

    setTimeout(() => {
      setCanClose(true);
    }, delayCanCloseMs);
  }, []);

  const close = () => {
    if (!canClose) {
      return;
    }

    setShow(false);
    setShownCount(shownCount + 1);
  };

  return (
    <Modal onClose={close} show={show} className={styles.TicketsModal}>
      <div className={`${styles.Tickets} ${className ? className : ""}`}>
        <p>
          You can attend the Streamer Awards in person this year.{" "}
          <b>TICKETS ON SALE NOW!</b> No discount code necessary.
        </p>

        <div className={`${styles.Buttons}`}>
          <div className={`${globalStyles.Center}`}>
            <a
              href="https://www.ticketmaster.com/event/09005E32A2E0456F"
              target="_blank"
              rel="noopener"
              onClick={() => {
                logInfo("get_tickets_3", "", GACategory.Visit, GALabel.Modal);
              }}
            >
              <FancyBtn>Get tickets</FancyBtn>
            </a>
          </div>
        </div>
      </div>
    </Modal>

    // Want to attend the Streamer Awards in person? Tickets are still available!

    // Tickets to attend the Streamer Awards in person are available this year. Get yours while they last!
    // Use code peepopog30 for 30% off all tickets.
  );
};

export default TicketsModal;
