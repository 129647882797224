import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Switch, Route } from "react-router-dom";
import Countdown from "react-countdown";
import { CSSTransition } from "react-transition-group";

import ErrorBoundary from "components/error/ErrorBoundary";
import Navbar from "components/nav/Navbar";
import Alert from "components/alert";
import SplashPage from "pages/splash";
import AboutPage from "pages/about";
import CategoryNominationPage from "pages/category";
import NominationPage from "pages/nomination";
import WinnersPage from "pages/winners";
import VotePage from "pages/vote";
import HomePage from "pages/home";
import FAQPage from "pages/faq";
import PickEmPage from "pages/pickem";

import { getClientConfig, logInfo } from "api";
import { Phase, currentPhase, awardShowTimestamp } from "common/dates";
import { isDevMode } from "common";
import { usePageTracking } from "hooks/usePageTracking";

import styles from "App.module.scss";
import globalStyles from "styles/components.module.scss";

import FullLogo from "assets/logo-full.png";
import LeftPillar from "assets/LeftPillar.png";
import RightPillar from "assets/RightPillar.png";
import LeftElevator from "assets/LeftElevator.png";
import RightElevator from "assets/RightElevator.png";
import { ClientConfig, GACategory, GALabel, milliseconds } from "types";
import LoginSuccessPage from "pages/login_success";
import TicketsModal from "components/modals/TicketsModal";

type route = {
  path: string;
  Component: any; // TODO: React.FC<PageProps>;
  showLogo?: boolean;
};

const getRouterRoutes = (
  config?: ClientConfig
): { routes: Array<route>; DefaultPage: any } => {
  const DefaultPage = SplashPage;
  const routes: Array<route> = [
    { path: "/about", Component: AboutPage, showLogo: true },
    {
      path: "/home",
      Component: HomePage,
      showLogo: currentPhase !== Phase.AWARDS_SHOW,
    },
    { path: "/faq", Component: FAQPage, showLogo: true },
    { path: "/winners", Component: WinnersPage, showLogo: true },
    { path: "/login_success", Component: LoginSuccessPage, showLogo: false },
    { path: "/", Component: SplashPage }, // should be the last routes
    { path: "", Component: SplashPage },
  ];

  if (isDevMode) {
    routes.unshift({ path: "/categories", Component: CategoryNominationPage });
    routes.unshift({ path: "/nominations", Component: NominationPage });
    routes.unshift({ path: "/vote", Component: VotePage });
    routes.unshift({ path: "/pickem", Component: PickEmPage });
  } else {
    switch (currentPhase) {
      case Phase.CATEGORY_NOMINATIONS:
        routes.unshift({
          path: "/categories",
          Component: CategoryNominationPage,
        });
        break;

      case Phase.NOMINATIONS:
        routes.unshift({ path: "/nominations", Component: NominationPage });
        break;

      case Phase.VOTING:
        if (config == null || config?.is_voting_open) {
          routes.unshift({ path: "/vote", Component: VotePage });
        }
        break;

      case Phase.PICKEMS:
        routes.unshift({ path: "/pickem", Component: PickEmPage });
        break;

      case Phase.AWARDS_SHOW:
        routes.unshift({ path: "/pickem", Component: PickEmPage });
        break;

      default:
        // routes.unshift({ path: "/pickem", Component: PickEmPage });
        break;
    }
  }

  return { routes, DefaultPage };
};

const renderCountdown = () => {
  switch (currentPhase) {
    case Phase.TABULATING_VOTES:
    case Phase.PICKEMS:
    case Phase.AWARDS_SHOW:
      return (
        <Countdown
          date={awardShowTimestamp}
          renderer={({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
              return null;
            }

            return (
              <Alert>
                <div>
                  The Streamer Awards starts in
                  <div className={styles.Countdown}>
                    <span className={styles.CountdownVal}>{days}</span> days{" "}
                    <span className={styles.CountdownVal}>{hours}</span> hours{" "}
                    <span className={styles.CountdownVal}>{minutes}</span>{" "}
                    minutes{" "}
                    <span className={styles.CountdownVal}>{seconds}</span>{" "}
                    seconds
                  </div>
                </div>
              </Alert>
            );
          }}
        />
      );

    default:
      return null;
  }
};

const renderTicketsAlert = (className?: string) => {
  return (
    <Alert className={className}>
      <p>
        Want to attend the Streamer Awards in person?{" "}
        <b>
          Get your{" "}
          <a
            href="https://www.ticketmaster.com/event/09005E32A2E0456F"
            target="_blank"
            rel="noopener"
            onClick={() => {
              logInfo("get_tickets", "", GACategory.Visit, GALabel.StickyAlert);
            }}
            className={globalStyles.Link}
          >
            tickets
          </a>{" "}
          now!
        </b>
      </p>
    </Alert>
  );
};

const renderLiveShowAlert = (className?: string) => {
  return (
    <Alert className={className}>
      <p>
        Watch the Streamer Awards 2023 LIVE at{" "}
        <b>
          <a
            href="https://www.twitch.tv/qtcinderella"
            target="_blank"
            rel="noopener"
            onClick={() => {
              logInfo(
                "tsa_live_stream",
                "",
                GACategory.Visit,
                GALabel.StickyAlert
              );
            }}
            className={globalStyles.Link}
          >
            twitch.tv/qtcinderella →
          </a>
        </b>
      </p>
    </Alert>
  );
};

const renderMerchAlert = (className: string) => {
  return (
    <Alert className={className}>
      <p>
        Merch is here! Support the show by getting yours at{" "}
        <a
          href="https://merch.thestreamerawards.com"
          target="_blank"
          rel="noopener"
          className={globalStyles.Link}
          onClick={() => {
            logInfo("merch", "", GACategory.Visit, GALabel.Alert);
          }}
        >
          merch.thestreamerawards.com
        </a>
        {/* <img
        src="assets/peepoShy.gif"
        className={globalStyles.PeepoShy}
        alt="peepoShy"
      /> */}
      </p>
    </Alert>
  );
};

type BackgroundProps = {
  children: React.ReactNode;
};

const Background: React.FunctionComponent<BackgroundProps> = ({ children }) => {
  const doorTransitionStartDelayMs: milliseconds = 1500;
  const doorTransitionMs = Number(styles.doorAnimationMillis);
  const elevatorTransitionMs = Number(styles.elevatorAnimationMillis);

  const [skipAnimation, setSkipAnimation] = useState<boolean>(false);

  const leftHalfRef = useRef<HTMLDivElement>(null);
  const rightHalfRef = useRef<HTMLDivElement>(null);
  const [areDoorsClosed, setAreDoorsClosed] = useState(true);

  const leftElevatorRef = useRef<HTMLImageElement>(null);
  const rightElevatorRef = useRef<HTMLImageElement>(null);
  const [areElevatorsVisible, setAreElevatorsVisible] = useState(true);

  const leftDoorRef = useRef<HTMLDivElement>(null);
  const leftPillarRef = useRef<HTMLImageElement>(null);

  const [doorWidth, setDoorWidth] = useState(0);
  const [pillarWidth, setPillarWidth] = useState(0);
  const [elevatorWidth, setElevatorWidth] = useState(0);

  useLayoutEffect(() => {
    const eWidth = leftElevatorRef.current?.clientWidth || 0;
    const pWidth = leftPillarRef.current?.clientWidth || 0;

    let dWidth = (leftDoorRef.current?.clientWidth || 0) - eWidth;

    const maxDoorWidthPercent =
      parseFloat(
        window.innerWidth > Number(styles.largeWindowWidthPx)
          ? styles.doorWidthPercentLarge
          : styles.doorWidthPercentSmall
      ) / 100.0;
    if (dWidth > window.innerWidth * maxDoorWidthPercent) {
      dWidth = window.innerWidth * maxDoorWidthPercent;
    }

    setElevatorWidth(eWidth);
    setPillarWidth(pWidth);
    setDoorWidth(dWidth);
  });

  useEffect(() => {
    setTimeout(() => {
      setAreDoorsClosed(false);
    }, doorTransitionStartDelayMs);
  }, []);

  const renderBackground = (skipAnimation: boolean) => {
    if (skipAnimation) {
      return (
        <div className={styles.Doors}>
          <div ref={leftHalfRef} className={styles.LeftHalfDone}>
            <div ref={leftDoorRef} className={styles.LeftHalfContainer}>
              <img
                ref={leftPillarRef}
                src={LeftPillar}
                className={styles.LeftPillar}
              />
            </div>
          </div>

          <div ref={rightHalfRef} className={styles.RightHalfDone}>
            <div className={styles.RightHalfContainer}>
              <img src={RightPillar} className={styles.RightPillar} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.Doors}>
        <CSSTransition
          in={areDoorsClosed}
          appear={true}
          nodeRef={leftHalfRef}
          timeout={doorTransitionMs}
          classNames={{
            appear: styles.LeftHalfStart,
            appearActive: styles.LeftHalfStart,
            appearDone: styles.LeftHalfStart,
            enter: styles.LeftHalfStart,
            enterActive: styles.LeftHalfStart,
            enterDone: styles.LeftHalfStart,
            exit: styles.LeftHalfStart,
            exitActive: styles.LeftHalfTransition,
            exitDone: styles.LeftHalfDone,
          }}
          onExited={() => {
            setAreElevatorsVisible(false);
          }}
        >
          <div ref={leftHalfRef}>
            <div ref={leftDoorRef} className={styles.LeftHalfContainer}>
              <img
                ref={leftPillarRef}
                src={LeftPillar}
                className={styles.LeftPillar}
              />

              <CSSTransition
                in={areElevatorsVisible}
                appear={true}
                nodeRef={leftElevatorRef}
                timeout={elevatorTransitionMs}
                classNames={{
                  exitActive: styles.LeftElevatorTransition,
                  exitDone: styles.LeftElevatorDone,
                }}
                onExited={() => {
                  setElevatorWidth(0);
                }}
                unmountOnExit
              >
                <img
                  ref={leftElevatorRef}
                  src={LeftElevator}
                  className={styles.LeftElevator}
                />
              </CSSTransition>
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          in={areDoorsClosed}
          appear={true}
          nodeRef={rightHalfRef}
          timeout={doorTransitionMs}
          classNames={{
            appear: styles.RightHalfStart,
            appearActive: styles.RightHalfStart,
            appearDone: styles.RightHalfStart,
            enter: styles.RightHalfStart,
            enterActive: styles.RightHalfStart,
            enterDone: styles.RightHalfStart,
            exit: styles.RightHalfStart,
            exitActive: styles.RightHalfTransition,
            exitDone: styles.RightHalfDone,
          }}
          onExited={() => {
            setAreElevatorsVisible(false);
          }}
        >
          <div ref={rightHalfRef}>
            <div className={styles.RightHalfContainer}>
              <CSSTransition
                in={areElevatorsVisible}
                appear={true}
                nodeRef={rightElevatorRef}
                timeout={elevatorTransitionMs}
                classNames={{
                  exitActive: styles.RightElevatorTransition,
                  exitDone: styles.RightElevatorDone,
                }}
                unmountOnExit
              >
                <img
                  ref={rightElevatorRef}
                  src={RightElevator}
                  className={styles.RightElevator}
                />
              </CSSTransition>

              <img src={RightPillar} className={styles.RightPillar} />
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  };

  let initialPillarWidth = styles.initialPillarWidthSmallPx;
  if (window.innerWidth > Number(styles.largeWindowWidthPx)) {
    initialPillarWidth = styles.initialPillarWidthLargePx;
  }

  const style: { [cssField: string]: React.CSSProperties } = {
    "--door-width": initialPillarWidth as React.CSSProperties,
    "--pillar-width": initialPillarWidth as React.CSSProperties,
  };
  if (doorWidth > 0) {
    style["--door-width"] = (doorWidth + "px") as React.CSSProperties;
  }
  if (pillarWidth > 0) {
    style["--pillar-width"] = (pillarWidth + "px") as React.CSSProperties;
  }
  style["--elevator-width"] = (elevatorWidth + "px") as React.CSSProperties;

  return (
    <div style={style}>
      <div className={styles.Bg} />
      {renderBackground(skipAnimation)}

      {children}
    </div>
  );
};

const App: React.FunctionComponent = () => {
  const [config, setConfig] = useState<ClientConfig | undefined>(undefined);

  usePageTracking();

  useEffect(() => {
    const fetchConfig = async () => {
      let ret = await getClientConfig();
      if (ret.error != null) {
        return;
      }
      setConfig(ret.resp);
    };

    fetchConfig();
  }, []);

  const { routes, DefaultPage } = getRouterRoutes(config);

  const renderBottomAlert = () => {
    switch (currentPhase) {
      case Phase.AWARDS_SHOW:
        return (
          <div className={styles.StickyBottom}>
            {renderLiveShowAlert(styles.BottomAlert)}
          </div>
        );

      case Phase.WINNERS_ANNOUNCED:
        return null;

      default:
        return (
          <div className={styles.StickyBottom}>
            {renderTicketsAlert(styles.BottomAlert)}
          </div>
        );
    }
  };

  return (
    <React.Fragment>
      {/* http://reactcommunity.org/react-transition-group/with-react-router  */}

      <Background>
        <div className={styles.AppContainer}>
          <Navbar />

          <ErrorBoundary>
            <TicketsModal />
            {config?.alert && <Alert innerHtml={config.alert} />}
            {/* {renderTicketsAlert()} */}
            {config?.show_merch && renderMerchAlert("")}

            {renderCountdown()}

            <Switch>
              {routes.map(({ path, Component, showLogo }: route) => {
                return (
                  <Route key={path} exact path={path}>
                    {showLogo && (
                      <img src={FullLogo} alt="logo" className={styles.Logo} />
                    )}
                    <div className={globalStyles.PageContainer}>
                      <Component config={config} />

                      {renderBottomAlert()}
                    </div>
                  </Route>
                );
              })}
            </Switch>

            {/* {renderMerchAlert(styles.BottomAlert)} */}
          </ErrorBoundary>
        </div>
      </Background>
    </React.Fragment>
  );
};

export default App;
