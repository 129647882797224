import { useState, useEffect } from "react";

import { logInfo } from "api";

// https://dev.to/eons/detect-page-refresh-tab-close-and-route-change-with-react-router-v5-3pd
const initBeforeUnLoad = (showExitPrompt) => {
  window.onbeforeunload = (event) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();

      logInfo("user exit confirmation");

      if (e) {
        e.returnValue = "";
      }
      return "";
    }
  };
};

export default function useExitPrompt(initialState) {
  const [showExitPrompt, setShowExitPrompt] = useState(initialState);

  window.onload = function () {
    initBeforeUnLoad(showExitPrompt);
  };

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);

    return () => {
      initBeforeUnLoad(false);
    };
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt];
}
