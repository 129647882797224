import styles from "components/form/input/Select.module.scss";

type SelectProps = {
  selectedValue?: string;
  onChange?: (val: string) => void;
  values?: Array<string> | Array<{ label: string; value: string }>;
};

const Select: React.FC<SelectProps> = ({ selectedValue, onChange, values }) => {
  const handleChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    onChange && onChange(e.target.value);
  };

  return (
    <select
      value={selectedValue}
      onChange={handleChange}
      className={styles.Select}
    >
      {values &&
        values.map((v) => {
          const label = typeof v === "string" ? v : v.label;
          const value = typeof v === "string" ? v : v.value;

          return (
            <option value={value} key={value} className={styles.Option}>
              {label}
            </option>
          );
        })}
    </select>
  );
};

export default Select;
